<template>
  <div
    id="gf-epl-dm"
    class="row m-0 p-0 w-100"
  >
    <template v-if="!settingsLoaded">
      <div class="w-100">
        <div class="d-flex mb-4">
          <skeleton-box
            height="25px"
            width="25px"
          />
          <div class="pl-3 w-100">
            <div>
              <skeleton-box
                height="20px"
                width="100%"
              />
            </div>
            <div class="mt-2">
              <skeleton-box
                height="20px"
                width="75%"
              />
            </div>
          </div>
        </div>
        <template v-if="isNotInline">
          <hr>
          <div class="d-flex justify-content-end">
            <skeleton-box
              height="25px"
              width="25%"
            />
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="fade-in w-100">
        <component
          :is="settings.type"
          :settings="settings.feeSettings"
          :displayed-on-page-index="displayedOnPageIndex"
          @action="(eventData) => this.$emit('action', eventData)"
        />
      </div>
    </template>
  </div>
</template>
<script>
import SkeletonBox from '../../../common/SkeletonBox.vue'
import casingUtilities from "../../../../common/casingUtilities";
import ModuleBase from "../ModuleBase.vue";
import DonationTip from "./DonationTip.vue";
import Standard from "./Standard.vue";
import SingleValueDisplayOption from '../../../../common/singleValueDisplayOptions'
import _ from 'lodash'

export default {
  components: {
    DonationTip,
    Standard,
    SkeletonBox,
  },

  mixins: [ModuleBase],

  data() {
    return {
      isParentModule: true,
      settingsLoaded: false,
      entityConfigSettings: [
        {
          type: "Payment",
          subType: "FeeSettings",
        },
      ],
      rangeSettingsRepo: [],
    };
  },

  computed: {
    isNotInline() {
      return (
        typeof this.settings.displayOptions == 'undefined' || 
        (typeof this.settings.displayOptions != 'undefined' && this.settings.displayOptions.singleValueDisplay != SingleValueDisplayOption.inline)
      )
    }
  },

  watch: {
    'sharedState.amount': {
      deep: true,
      handler: function (val) {
        if (
            typeof this.settings.feeSettings.feeModelConfiguration.rangeSettings == 'undefined' || 
            (typeof this.settings.feeSettings.feeModelConfiguration.rangeSettings != 'undefined' && this.settings.feeSettings.feeModelConfiguration.rangeSettings == null)
          ) {
          this.logger.logInfo('rangeSettings is undefined', this.settings.feeSettings.feeModelConfiguration.rangeSettings)
          return
        }

        this.logger.logInfo('rangeSettingsRepo.length', this.rangeSettingsRepo.length)
        if (this.rangeSettingsRepo.length == 0) {
          this.rangeSettingsRepo = this.settings.feeSettings.feeModelConfiguration.rangeSettings
          this.rangeSettingsRepo = this.rangeSettingsRepo.sort((a, b) => a.startingValue - b.startingValue)
          this.logger.logInfo('Set rangeSettingsRepo', this.rangeSettingsRepo)
        }

        let feeModelConfiguration = {}
        
        for (let i = 0; i < this.rangeSettingsRepo.length; i++) {
          if (
            (
              val >= this.rangeSettingsRepo[i].startingValue && 
              (
                (typeof this.rangeSettingsRepo[i+1] != 'undefined' && val < this.rangeSettingsRepo[i+1].startingValue) || 
                (typeof this.rangeSettingsRepo[i+1] == 'undefined' && val >= this.rangeSettingsRepo[i].startingValue)
              )
            )
          ) {
            feeModelConfiguration = this.rangeSettingsRepo[i]
          }
        }

        feeModelConfiguration.rangeSettings = this.rangeSettingsRepo
        this.logger.logInfo('new feeModelConfiguration', feeModelConfiguration)
        this.settings.feeSettings.feeModelConfiguration = feeModelConfiguration
      }
    }
  },

  mounted() {
    this.logger.logInfo("FeeModule mounted settings:", this.settings);
    this.store.updateFeeModel(this.settings.type);
    if (
      this.settings &&
      this.settings.feeSettings &&
      !this.settings.feeSettings.feeModelConfiguration
    ) {
      var feeModelConfiguration = {};
      Object.assign(feeModelConfiguration, this.settings.feeSettings);
      this.settings.feeSettings["feeModelConfiguration"] = casingUtilities.objectKeysToCamelCase(feeModelConfiguration);
       this.logger.logInfo('mounted.this.settings.feeSettings', this.settings.feeSettings)
    }

    if (this.settings && this.settings.feeSettings) {
      this.settings.feeSettings.displayOptions = typeof this.settings.displayOptions != 'undefined' ? this.settings.displayOptions : {}
    }

    if (!this.settings.useDefaults) this.settingsLoaded = true;
  },
  methods: {
    $ModuleBase_handleEntityConfigResults(results) {
      this.logger.logInfo("Fee settings configuration:", results[0]);
      
      if (!this.settings.useDefaults) return;

      var settings = casingUtilities.objectKeysToCamelCase(results[0]);
      this.logger.logInfo("$ModuleBase_handleEntityConfigResults.settings:", settings);

      this.settings.type = this.codeToModuleMap(settings.code);

      this.store.updateFeeModel(_.startCase(this.settings.type).replace(/ /g, ''));
      
      if (!this.settings.feeSettings)
        this.settings["feeSettings"] = {feeModelConfiguration: {}}
      
      _.merge(this.settings.feeSettings.feeModelConfiguration, settings.settings)

      this.logger.logInfo("$ModuleBase_handleEntityConfigResults.feeModelConfiguration.merged:", this.settings.feeSettings.feeModelConfiguration);
      
      this.settingsLoaded = true;

      this.logger.logInfo("$ModuleBase_handleEntityConfigResults.store.state.amount:", this.store.state.amount);

      if (this.store.state.amount) {
        this.logger.logInfo("$ModuleBase_handleEntityConfigResults.triggeringTipAmountCalculation");
        this.$nextTick(() => {
          this.store.triggerTipAmountCalculation()
        })
      }
        
    },

    codeToModuleMap(code) {
      switch (code) {
        case "TIP":
          return "donationTip";
        default:
          return "standard";
      }
    },
  },
};
</script>

<style scoped>
.fade-in {
  display: block;
  animation-delay: 1s;
  -webkit-animation: arrowInOut .2s linear forwards;
  animation: arrowInOut .2s linear forwards;
}

@-webkit-keyframes arrowInOut {
  100% {opacity: 1;}
  0% {opacity: 0;}
}
@keyframes arrowInOut {
  100% {opacity: 1;}
  0% {opacity: 0;}
}
</style>