<template lang="html">
  <div>
    <label
      for="gf-cardholder-name-selector"
      class="gf-cardholder-switch"
    >
      <input
        id="gf-cardholder-name-selector"
        v-model="ignoreNameField"
        type="checkbox"
        name="gf-cardholder-name-selector"
      >
      <span class="gf-cardholder-slider round" />
    </label>
    <span>{{ label }}</span>
  </div>
</template>

<script>
import { GfPaymentsEventBus } from '../../../common/GfPaymentsEventBus.js';

export default {
  name: 'GfCardholderToggle',
  
  props: {      
    label: {
        type: String,
        default: 'I am the Card Holder'
    }
  },

  data: function() {
    return {
        ignoreNameField: true
    }
  },

  watch: {
    ignoreNameField: function(newVal) {
        GfPaymentsEventBus.$emit('cardholderToggleValueChanged', newVal);
    },
  },
  mounted: function() {
      GfPaymentsEventBus.$emit('cardholderToggleValueChanged', this.ignoreNameField);
  }
}
</script>

<style lang="css" scoped>
.gf-cardholder-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 0px;
}

.gf-cardholder-switch input {
  display:none;
}

.gf-cardholder-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: .4s;
  transition: .4s;
}

.gf-cardholder-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: #fafafa;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .gf-cardholder-slider {
  background-color: #2196F3;
}

input:focus + .gf-cardholder-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .gf-cardholder-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.gf-cardholder-slider.round {
  border-radius: 34px;
}

.gf-cardholder-slider.round:before {
  border-radius: 50%;
}
</style>
