import logger from '../logger'

export default {
    async execute(axios, paymentReferenceId, requestType, additionalData) {
        logger.logInfo(`updatePaymentRequestClient.execute(), paymentReferenceId: ${paymentReferenceId}, requestType: ${requestType}`);
        logger.logInfo(`updatePaymentRequestClient.execute(), additionalData`, additionalData);
        var axiosAjaxOptions = {
            headers: {
                        "x-requested-with": "XMLHttpRequest"
                     }
        };
        try {
            var results = await axios.post(`/payments/donate/update-payment-request`,
            {
                "clientReferenceId": paymentReferenceId,
                "requestType": requestType,
                "additionalData": additionalData
            }, axiosAjaxOptions);
            return results.data;
        } catch (error) {
            logger.logInfo(error);
            return {
                status: {
                    success: false,
                    msg: 'Unable to update the payment request. Please retry in a few minutes by refreshing the page. If the problem persists, please contact support on 1300 889 272 (or +61 2 8188 8920).'
                }
            }
        }
    }
}