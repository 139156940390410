var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-0 p-0 w-100",attrs:{"id":"gf-epl-dollar-handles"}},[(_vm.handleItems == null)?[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},_vm._l((4),function(i){return _c('div',{key:i,staticClass:"col-6 col-md-3 mb-3"},[_c('SkeletonBox',{attrs:{"width":"100%","height":"50px"}})],1)}),0)])]:_vm._l((_vm.handleItems),function(item,index){return _c('div',{key:index,class:`${_vm.settings.class} ${_vm.handlesTypeClass}`},[_c('div',{on:{"click":function($event){return _vm.setPredefined(item.value)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.predefinedValue),expression:"predefinedValue"}],attrs:{"id":`donation-amount-${index}`,"name":"DonationAmount","type":"radio","tabindex":"0"},domProps:{"value":item.value,"checked":_vm._q(_vm.predefinedValue,item.value)},on:{"change":function($event){_vm.predefinedValue=item.value}}}),_c('label',{staticClass:"donation-line",class:{
          'gf-secondary-border':
            _vm.predefinedValue === item.value && _vm.usingPredefined
        },attrs:{"for":`donation-amount-${index}`,"data-amountdesc":item.text,"title":item.text}},[(item.imageUrl)?_c('div',{staticClass:"donation-image m-auto",style:(`background-image: url('${item.imageUrl}')`)}):_vm._e(),_c('span',{class:{
            'gf-secondary-bg selected gf-secondary-text':
              _vm.predefinedValue === item.value &&
              _vm.usingPredefined
          }},[_vm._v(_vm._s(_vm.currencySymbol)+_vm._s(item.value))])])])])}),_c('div',{staticClass:"col-md-12 text-center m-auto",attrs:{"hide":""}},[_c('p',[_vm._v(_vm._s(_vm.getItemTitle))])]),(_vm.settings.displayOther)?_c('div',{staticClass:"donation-item hide-radio other-amount col-12 field"},[_c('div',{staticClass:"currency-symbol"},[_vm._v("\n      "+_vm._s(_vm.currencySymbol)+"\n    ")]),_c('input',{attrs:{"id":"donation-other","name":"DonationOther","type":"radio","value":""}}),_c('div',{staticClass:"ui input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customAmount),expression:"customAmount"}],attrs:{"name":"OtherAmount","type":"text","value":"","placeholder":`${_vm.settings.otherAmountPlaceHolderText}`},domProps:{"value":(_vm.customAmount)},on:{"input":function($event){if($event.target.composing)return;_vm.customAmount=$event.target.value}}})])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }