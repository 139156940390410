<template>
  <div
    id="gf-epl-download-receipt"
    class="col-12 m-0 p-0"
    :class="settings.class"
  >
    <span v-html="settings.content" />
    <form
      id="frm-download-receipt"
      action="/payments/donate/print-receipt"
      method="post"
    >
      <input
        id="PaymentId"
        data-val="true"
        data-val-number="The field PaymentId must be a number."
        name="PaymentId"
        type="hidden"
        :value="paymentId"
      >
      <input
        id="PaymentReference"
        name="PaymentReference"
        type="hidden"
        :value="paymentReference"
      >
    </form>    
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";

export default {
  mixins: [ModuleBase],
  
  data() {
    return {
      element: null,
      paymentId: 0,
      paymentReference: 0
    }
  },

  methods: {
    $ModuleBase_afterBaseMounted() {
      if (!this.settings.downloadLinkSelector)
        return;

      this.element = document.getElementById('gf-epl-download-receipt').querySelector(this.settings.downloadLinkSelector);
      var self = this;
      this.element.addEventListener("click", () => self.downloadReceipt());
    },

    async downloadReceipt() {
      this.paymentId = this.sharedState.paymentResponse.Payment.PaymentId;
      this.paymentReference = this.sharedState.paymentResponse.Payment.Reference;
      var form = document.getElementById("frm-download-receipt");
      this.$nextTick(()=>form.submit());
    }
  }
};
</script>
