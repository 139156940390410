<template>
  <div>
    <div class="row">
      <div
        v-if="displayNameSelector && !alwaysUseCardholderName"
        id="card-holder-name-selector"
        class="col-12 mb-3"
      >
        <label
          for="is-cardholder-name-selector"
          class="switch"
        >
          <input
            id="is-cardholder-name-selector"
            v-model="ignoreCardholderName"
            type="checkbox"
            name="is-cardholder-name-selector"
            :disabled="isDisabled"
          >
          <span class="slider round" />
        </label>
        <span>{{ displayNameSelectorLabel }}</span>
      </div>
    </div>
    <div class="row">
      <div
        v-if="ignoreCardholderName === false"
        class="form-group col-12"
      >
        <input
          id="cardholder-name"
          v-model="cardholderName"
          class="card_field form-control cardholder-name"
          placeholder="Cardholder's name"
          :class="{ invalid: cardholderNameInvalid }"
          :disabled="isDisabled"
        >
      </div>
    </div>
    <form id="card-form">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="card-number">Card Number</label>
            <div
              id="card-number"
              class="card_field form-control"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="expiration-date">Expiration Date</label>
            <div
              id="expiration-date"
              class="card_field form-control"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="cvv">CVV</label>
            <div
              id="cvv"
              class="card_field form-control"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import GfPaymentComponent from "../../common/GfPaymentComponent.vue";
import logger from "../../common/logger";
import VS2 from "@gf/vue-script2";
import GfCardTypes from "../../common/GfCardTypes";
import updatePaymentRequestType from "../../common/updatePaymentRequestTypes.js"

export default {
    name: "GfPayPalCreditCard",
    mixins: [GfPaymentComponent],
    data() {
        return {
            cardFields: null,
            orderId: null,
            paymentMethod: "CC",
            processingActions: false,
            clientId:"",
            clientToken: "",
            displayNameSelector: true,
            displayNameSelectorLabel: '',
            alwaysUseCardholderName: false,
            ignoreCardholderName: true,
            cardholderName: '',
            lastUpdatedNonce: '',
            lastUpdatedCardType: '',
            lastUpdatedDigits: '',
            lastUpdatedExpiry: '',
            additionalData: { cardholderName: '' },
            cardholderNameInvalid: true,
            paymentTypeName:'paypal-credit-card',
        };
    },
    computed: {
        isDisabled() {
            return this.disabled;
        },
    },
    watch: {
        cardholderName: function (val) {
            if (!this.ignoreCardholderName) {
                this.additionalData.cardholderName = this.cardholderName;
                this.notifyPaymentDetailsUpdatedIfValid();
            }
        },
        ignoreCardholderName: function (val) {
            this.additionalData.cardholderName = '';
            if (!val) {
                this.additionalData.cardholderName = this.cardholderName;
            }

            this.notifyPaymentDetailsUpdatedIfValid();
        },
    },
    created: function() {
        if (typeof(this.additionalProperties) === 'undefined')
            return;

        if (typeof(this.additionalProperties.displayNameSelectorLabel) !== 'undefined')
            this.displayNameSelectorLabel = this.additionalProperties.displayNameSelectorLabel;

        if (typeof(this.additionalProperties.alwaysUseCardholderName) !== 'undefined')
            this.alwaysUseCardholderName = this.additionalProperties.alwaysUseCardholderName;

        this.displayNameSelector = (this.additionalProperties.displayNameSelector !== null || this.additionalProperties.displayNameSelector !== undefined) && this.additionalProperties.displayNameSelector;
    },
    async mounted() {
        this.clientToken = this.additionalProperties.clientToken ? this.additionalProperties.clientToken : this.clientToken;
        this.clientId = this.tokenizationKey? this.tokenizationKey : this.clientId;
        var attr = {
            parent: document.head,
            attributes: [{ key: "data-client-token", value: this.clientToken }, { key: "data-partner-attribution-id", value: this.additionalProperties.bnCode }],
        };
        VS2.load(
            `https://www.paypal.com/sdk/js?components=buttons,hosted-fields&commit=false&client-id=${this.clientId}&currency=${this.additionalProperties.currencyCode}&merchant-id=${this.additionalProperties.merchantId}`,
            attr
        ).then(() => {
            this.paypalSetLoaded();
        });

        if (this.alwaysUseCardholderName) {
            this.ignoreCardholderName = false;
            this.displayNameSelector = false;
        }

        logger.logInfo(`Mounted the GfPayPalCreditCard component`);
    },
    methods: {
        paypalSetLoaded() {
            let vm = this;

            if (paypal.HostedFields.isEligible()) {
                paypal.HostedFields.render({
                    createOrder: () => vm.orderId,

                    styles: {
                        ".valid": {
                            color: "green",
                        },
                        ".invalid": {
                            color: "red",
                        },
                        input: {
                            "font-size": "18px",
                            color: "#333333",
                        },
                    },

                    fields: {
                        number: {
                            selector: "#card-number",
                            placeholder: "Credit Card Number",
                        },
                        cvv: {
                            selector: "#cvv",
                            placeholder: "CVV",
                        },
                        expirationDate: {
                            selector: "#expiration-date",
                            placeholder: "MM/YY",
                        },
                    },
                }).then(function (cardFields) {
                    vm.cardFields = cardFields;
                    cardFields.on("validityChange", function (event) {
                        vm.onPayPalCreditCardValidityChange(event);
                    });
                });
            } else {
                document.querySelector("#card-form").style = "display: none";
            }
        },

        onPayPalCreditCardValidityChange: function (event) {
            let vm = this;
            logger.logInfo(`Fields validity changed, event:`, event);
            logger.logInfo(`Fields validity changed, vm.cardFields`, vm.cardFields);
            const isValid =
                (vm.ignoreCardholderName || vm.cardholderName.length > 0) &&
                event.fields.cvv.isValid &&
                event.fields.expirationDate.isValid &&
                event.fields.number.isValid &&
                event.cards.length > 0;
            if (isValid) {
                logger.logInfo(`Credit card fields are valid`);
                vm.onPaymentMethodReceived(
                    Date.now().toString(),
                    vm.normaliseCard(event.cards[0].type),
                    "",
                    "",
                    vm.additionalData
                );
            } else {
                vm.clearInputData()
            }
        },

        normaliseCard(cardType) {
            switch (cardType.toLowerCase()) {
                case "amex":
                case "american express":
                case "american-express":
                    return GfCardTypes.types.AMEX;
                case "master-card":
                    return GfCardTypes.types.MASTERCARD;
                case "visa":
                    return GfCardTypes.types.VISA;
                default:
                    return GfCardTypes.OTHER;
            }
        },

        $_GfPaymentComponent_onPendingClientActions(data) {
            logger.logInfo(`onPendingClientActions:`, data);
            let self = this;
            if (!data || self.processingActions) return;

            self.processingActions = true;
            this.orderId = data.PaymentClientSecret;
            this.cardFields.submit({
                contingencies: this.additionalProperties.Contingencies
            })
                .then((data) => {
                    logger.logInfo("cardFields Submitted response", data);
                    self.onClientActionsResponseReceived(true, "success", data);
                    self.onPaymentUpdateRequest(updatePaymentRequestType.AUTHORIZED, {})
                    self.processingActions = false;
                })
                .catch(function (err) {
                    logger.logInfo("cardFields Submitted error", err);
                });
        },

        notifyPaymentDetailsUpdatedIfValid: function() {
            if (this.displayNameSelector && !this.ignoreCardholderName && !this.cardholderName) {
                this.cardholderNameInvalid = true;
                this.clearInputData();
                return;
            }
            this.cardholderNameInvalid = false;
            this.onPaymentMethodReceived(Date.now().toString(), this.lastUpdatedCardType, this.lastUpdatedDigits, this.lastUpdatedExpiry, this.additionalData);
        },
    },
};
</script>
<style lang="css" scoped>
input.invalid {
  border-color: red;
}

.invalid {
  border-color: red;
  background-color: #fee;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 0px;
}

.switch input {
  display:none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: #fafafa;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[disabled], div[disabled] {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}

</style>
