<template lang="html">
  <div class="steps-wrap">
    <div :class="`steps-ui row mx-auto ${sliderClass}`">
      <StepItem
        v-for="(item, index) in steps"
        :key="index"
        class="steps-tab col"
        :is-active="index == activeIndex"
        :class="{'active': index == activeIndex, 'disabled': disableAllSteps || (!sharedState.canProceed && (index > activeIndex)) || (index > activeIndex + 1)}"
        :disabled="sharedState.isProcessingPayment"
        @click.native="navigateToSlide(index)"
      >
        <div v-html="unescape(item.content)" />
        <div
          v-if="item.subText && item.subText.length > 0"
          class="step-num"
        >
          <span>{{ item.subText }}</span>
        </div>
      </StepItem>
    </div>
  </div>
</template>
<script>
import StepItem from "./StepItem.vue";
import logger from "../../common/logger";
import Store from "./EmbeddedPaymentStore";
import _ from 'lodash'
export default {
  components: {
    StepItem
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    sliderClass: {
        type: String,
        default: 'slider-nav'
    },
    sliderContentRef: {
        type: String,
        default: '.slider-for'
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    disableAllSteps: {
      type: Boolean,
      default: false
    }
  },
  data() {
      return {
        sharedState: Store.state
      }
  },

  methods: {
    navigateToSlide(slideNumber) {
      if (this.disableAllSteps || (!this.sharedState.canProceed && (slideNumber > this.activeIndex)) || (slideNumber > this.activeIndex + 1))
        return;
      this.$emit("navigateToSlide", slideNumber);
    },

    unescape(str) {
      return _.unescape(str);
    }
  },
};
</script>

<style scoped>
.steps-wrap {
  margin-bottom: 30px;
  padding: 0 2px;
}

.steps-ui div.steps-tab {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  min-height: 60px;
}

.disabled {
  cursor: not-allowed !important;
}

div[disabled] {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}
</style>
