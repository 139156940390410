<template>
  <div
    id="gf-epl-fm-donation-tip"
    class="row m-0 p-0 w-100"
  >
    <template v-if="settings.feeModelConfiguration.suggestedTipValues.length == 1">
      <div class="platform-fee col-12">
        <div
          class="field rounded-checkbox row"
        >
          <label
            for="IsAccepted"
            class="transition"
          >
            <input
              id="IsAccepted"
              v-model="isTipping"
              autocomplete="off"
              data-val="true"
              name="IsAccepted"
              type="checkbox"
            >
            <div
              class="box"
              :class="{ 'gf-secondary-button': isTipping }"
            /></label>
          <div class="col">
            <span
              for="isTipping"
              class="checkbox-detail"
              data-toggle="tooltip"
              data-placement="top"
              :title="replaceTemplatedStrings(settings.feeModelConfiguration.tipDescriptionToolTip)"
              @click="isTipping = !isTipping"
            >
              {{ replaceTemplatedStrings(settings.feeModelConfiguration.tipDescription) }} <span
                v-show="displayOptionIsInline && isTipping && tipAmount > 0"
                class="inline-thank-you-message"
              >{{ replaceTemplatedStrings(feeModelConfiguration.tipThankYouMessage) }}</span>
            </span>
            <span class="d-inline-block d-md-none ml-1">
              <a
                class="tooltip-toggle"
                href="javascript:;"
                data-placement="auto"
                data-toggle="tooltip"
                data-trigger="focus"
                :title="replaceTemplatedStrings(settings.feeModelConfiguration.tipDescriptionToolTip)"
              >
                <i class="fa fa-info-circle fa-fw fa-lg text-primary" />
              </a>
            </span>
            <span class="d-none d-md-inline-block ml-1">
              <a
                class="tooltip-toggle"
                href="javascript:;"
                data-toggle="tooltip"
                data-trigger="hover"
                :title="replaceTemplatedStrings(settings.feeModelConfiguration.tipDescriptionToolTip)"
              >
                <i
                  class="fa fa-info-circle fa-fw fa-lg text-primary"
                />
              </a>
            </span>
          </div><!-- .col -->
        </div>
        <template v-if="displayOptionIsDefault">
          <hr>
          <div class="row">
            <div class="col-auto">
              <span v-if="displayCurrencyName">{{ currencyName }} </span>{{ currency(totalAmount, { symbol : currencySymbol }).format() }}
            </div>
            <div
              v-show="isTipping && tipAmount > 0"
              class="col text-right default-thank-you-message"
            >
              {{ replaceTemplatedStrings(feeModelConfiguration.tipThankYouMessage) }}
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="w-100">
        <div class="card">
          <div class="card-body tip-container">
            <div class="d-flex justify-content-between">
              <div class="col-md-12 text-center">
                {{ replaceTemplatedStrings(settings.feeModelConfiguration.tipDescription) }}
              </div>
              <div>
                <div class="d-block d-md-none">
                  <a
                    href="javascript:;"
                    data-toggle="tooltip"
                    data-trigger="focus"
                    data-placement="auto"
                    :title="replaceTemplatedStrings(settings.feeModelConfiguration.tipDescriptionToolTip)"
                  >
                    <i class="fa fa-question-circle fa-fw" />
                  </a>
                </div>
                <div class="d-none d-md-block">
                  <a
                    href="javascript:;"
                    data-toggle="tooltip"
                    data-trigger="hover"
                    :title="replaceTemplatedStrings(settings.feeModelConfiguration.tipDescriptionToolTip)"
                  >
                    <i
                      class="fa fa-question-circle fa-fw"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="text-center">
              <div
                v-if="settings.feeModelConfiguration.suggestedTipValues.length"
                class="row mt-3"
              >
                <div class="offset-sm-4 col-sm-4">
                  <select
                    id="suggested-tip-values"
                    v-model="selectedTipValue"
                    class="form-control"
                    :disabled="!amount"
                  >
                    <template v-if="amount">
                      <option
                        v-for="tipValue in settings.feeModelConfiguration.suggestedTipValues"
                        :key="`tipvaluekey${tipValue}`"
                        :value="tipValue"
                      >
                        + {{ currencySymbol }}
                        <template v-if="settings.feeModelConfiguration.isSuggestedValuesPercentages">
                          {{ (amount * (tipValue/100)).toFixed(2) }}
                        </template>
                        <template v-else>
                          {{ tipValue.toFixed(2) }}
                        </template>
                      </option>
                      <option value="other">
                        Other
                      </option>
                    </template>
                  </select>
                  <template v-if="showCustomTipInput">
                    <div class="input-group mt-1">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-white pr-0">+ {{ currencySymbol }}</span>
                      </div>
                      <input
                        id="custom-tip-input"
                        ref="customTipAmount"
                        v-model="customTipAmount"
                        type="text"
                        class="form-control border-left-0 pl-0 pb-2 unbordered-on-focus"
                        @input="onCustomTipAmountChanged"
                      >
                    </div>
                  </template>
                  <div class="mt-3">
                    <div
                      v-show="isTipping && tipAmount > 0"
                      class="text-center"
                    >
                      {{ replaceTemplatedStrings(feeModelConfiguration.tipThankYouMessage) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- .card -->
      </div>
    </template>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import Currency from "currency.js";
import SingleValueDisplayOption from '../../../../common/singleValueDisplayOptions'
import _ from 'lodash'

export default {
	mixins: [ModuleBase],
	data() {
		return {
      isTipping: true,
      showCustomTipInput: false,
      calculatedTipAmount: 0,
			selectedTipValue: 0,
      customTipAmount: 0,
			tipAmount: 0,
			currency: Currency,
			entityConfigSettings: [
				{
					type: "Beneficiary",
					subType: "BeneficiaryTipCollector"
				}
			],
      isTypingCustomTip: false,
      isTypingCustomTipInterval: null,
      feeModelConfiguration: {
        tipThankYouMessage: '',
        suggestedTipValues: [],
        displayCurrencyName: false,
        isSuggestedValuesPercentages: true,
        defaultTipValue: 0,
        rangeSettings: [],
      },
		};
	},

	computed: {
		isUsingBinaryTipping() {
			return (
				this.feeModelConfiguration.suggestedTipValues &&
				this.feeModelConfiguration.suggestedTipValues.length === 1
			);
		},

		currencyName(){
			return this.sharedState.currencyInfo.code || "";
		},

		currencySymbol(){
			return this.sharedState.currencyInfo.symbol || "$";
		},

    amount() {
      return this.sharedState.amount
    },

		totalAmount() {
			return this.sharedState.totalAmount;
		},

    displayOptionIsDefault() {
      return (typeof this.settings.displayOptions != 'undefined' &&
        typeof this.settings.displayOptions.singleValueDisplay != 'undefined' &&
        this.settings.displayOptions.singleValueDisplay == SingleValueDisplayOption.subtotal
      ) ||
      (typeof this.settings.displayOptions == 'undefined') ||
      (typeof this.settings.displayOptions != 'undefined' && typeof this.settings.displayOptions.singleValueDisplay == 'undefined')
    },

    displayOptionIsInline() {
      return typeof this.settings.displayOptions != 'undefined' &&
        typeof this.settings.displayOptions.singleValueDisplay != 'undefined' &&
        this.settings.displayOptions.singleValueDisplay == SingleValueDisplayOption.inline
    },

		displayCurrencyName() {
			return (
				this.feeModelConfiguration.displayCurrencyName == undefined ||
				this.feeModelConfiguration.displayCurrencyName
			);
		},
	},

	watch: {
    isTipping() {
			this.calculateTipAmount();
		},

    'settings.feeModelConfiguration': function (val) {
      this.feeModelConfiguration = val
    },

		'sharedState.amount': function (val) {
      this.logger.logInfo('DonationTip.sharedState.amount', val)
      this.updateCalculation()
		},

    'sharedState.calculateTipAmountTrigger': function (val) {
      this.logger.logInfo('DonationTip.sharedState.calculateTipAmountTrigger', val)
      this.updateCalculation()
    },

    selectedTipValue(amount) {
      this.showCustomTipInput = (amount === 'other') ? true : false

      if ( ! this.showCustomTipInput) {
        this.calculateTipAmount();
      }
    },

    showCustomTipInput() {
      this.customTipAmount = this.customTipAmount + ' '
      this.setCustomTipAmount(1)
    }
	},

	mounted() {
    if (this.settings.feeModelConfiguration)
      this.feeModelConfiguration = this.settings.feeModelConfiguration
		this.selectedTipValue = this.settings.feeModelConfiguration.defaultTipValue;

      this.initializeJQueryPlugins();
	},

	methods: {
    initializeJQueryPlugins() {
      if (typeof $ != 'undefined') {
        $(function () {
          $('[data-toggle="tooltip"]').tooltip()
        })
      }
    },

		$ModuleBase_handleEntityConfigResults(results) {
			this.store.setDonorTipBeneficiary(JSON.parse(results[0].Settings).BeneficiaryId, JSON.parse(results[0].Settings).TaxTypeId);
		},

    findDefaultConfigurationByRangeSettings(rangeSettings) {
      let _rangeSettings = _.cloneDeep(rangeSettings)
      let rangeSettingsRepo = _rangeSettings.sort((a, b) => a.startingValue - b.startingValue)
      this.logger.logInfo('Set rangeSettingsRepo', rangeSettingsRepo)

      let feeModelConfiguration = {}
      
      for (let i = 0; i < rangeSettingsRepo.length; i++) {
        if (
          (
            this.sharedState.amount >= rangeSettingsRepo[i].startingValue && 
            (
              (typeof rangeSettingsRepo[i+1] != 'undefined' && this.sharedState.amount < rangeSettingsRepo[i+1].startingValue) || 
              (typeof rangeSettingsRepo[i+1] == 'undefined' && this.sharedState.amount >= rangeSettingsRepo[i].startingValue)
            )
          )
        ) {
          feeModelConfiguration = rangeSettingsRepo[i]
        }
      }
      return feeModelConfiguration
    },

    replaceTemplatedStrings(text) {
      var updatedText = text.replace(
        "$Tip.Amount$",
        this.currency(this.tipAmount, { symbol : this.currencySymbol }).format()
      );
      updatedText = updatedText.replace(
        "$Tip.CalculatedValue$",
        this.currency(this.calculatedTipAmount, { symbol : this.currencySymbol }).format()
      );
      return updatedText;
    },

    setTipAmount(tipAmount) {
      this.tipAmount = tipAmount;
      this.customTipAmount = tipAmount;
      this.calculatedTipAmount = tipAmount;
      if (this.isTipping)
        this.store.addToAmount(tipAmount);
      else{
        this.store.addToAmount(0);
        this.tipAmount = 0;
        this.customTipAmount = 0;
      }

			if (tipAmount > 0) this.$ModuleBase_resized();
    },

    updateCalculation() {
      this.calculateTipAmount();
      if (_.has(this.settings.feeModelConfiguration, 'rangeSettings') && this.settings.feeModelConfiguration.rangeSettings.length > 0) {
        this.logger.logInfo('DonationTip.feeModelConfiguration.rangeSettings', this.settings.feeModelConfiguration.rangeSettings) 
        let feeModelConfiguration = this.findDefaultConfigurationByRangeSettings(this.settings.feeModelConfiguration.rangeSettings)
        this.logger.logInfo('DonationTip.feeModelConfiguration', feeModelConfiguration) 
        this.feeModelConfiguration = feeModelConfiguration
        this.selectedTipValue = feeModelConfiguration.defaultTipValue
      } else if (typeof this.settings.feeModelConfiguration.defaultTipValue != 'undefined') {
        this.logger.logInfo('DonationTip.feeModelConfiguration.defaultTipValue', this.settings.feeModelConfiguration.defaultTipValue) 
        this.selectedTipValue = this.settings.feeModelConfiguration.defaultTipValue;
      } 
    },

		calculateTipAmount() {
			var tipAmount = this.selectedTipValue;

      if (tipAmount === 'other')
        this.selectedTipValue = this.feeModelConfiguration.defaultTipValue;

			if (this.feeModelConfiguration.isSuggestedValuesPercentages)
				tipAmount = this.sharedState.amount * (this.selectedTipValue / 100);
			this.setTipAmount(tipAmount);
		},

    onCustomTipAmountChanged() {
      this.setCustomTipAmount()
    },

    setCustomTipAmount(delay) {
      var $delay = delay || 1000
      this.customTipAmount = this.customTipAmount.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')

      if (this.isTypingCustomTipInterval)
        clearTimeout(this.isTypingCustomTipInterval)

      this.isTypingCustomTipInterval = setTimeout(() => {
        this.customTipAmount = parseFloat(this.customTipAmount).toFixed(2)
        this.setTipAmount(this.customTipAmount)
      }, $delay)
    }
	},
};
</script>

<style>
.unbordered-on-focus:focus {
  border-color: #ced4da !important;
  -webkit-box-shadow: none !important;
}

.tip-container {
  background: #EEE;
}
</style>
