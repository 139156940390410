class ClickToPayPaymentRequestor {
    constructor() {}

    tokenizeRequest(hostedFieldsInstance, successCallback, errorCallback) {
        if (!hostedFieldsInstance || !hostedFieldsInstance.tokenize)
            return;

        hostedFieldsInstance.tokenize({}, (tokenizeErr, payload) => {
            if (tokenizeErr) {
                if (errorCallback)
                    errorCallback(tokenizeErr);
                return;
            }

            successCallback(payload);
        })
    }
}

export default ClickToPayPaymentRequestor;
