import logger from '../logger'

export default {
    async execute(axios, paymentReferenceId, csrfToken) {
        logger.logInfo(`retrievePaymentStatusClient.execute(), retrieving payment status, paymentReferenceId: ${paymentReferenceId}`);

        var axiosAjaxOptions = {
            headers: {
                        "x-requested-with": "XMLHttpRequest",
                        "RequestVerificationToken": csrfToken
                     }
        };
        try {
            var results = await axios.post(`/payments/donate/retrieve-payment-status`,
            {
                "clientReferenceId": paymentReferenceId
            }, axiosAjaxOptions);
            return results.data;
        } catch (error) {
            logger.logInfo(error);
            return {
                status: {
                    success: false,
                    msg: 'Unable to load the payment status. Please retry in a few minutes by refreshing the page. If the problem persists, please contact support on 1300 889 272 (or +61 2 8188 8920).'
                }
            }
        }
    }
}