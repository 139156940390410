/**
 * The aliasing serves as abstraction layer between our internal variables names and external usage, the alias itself should not change but internal variables
 * can change and must be updated here respectively.
 */
const aliasing = {
    /** Alias: ModuleName|Field */
    aliases: {
        /** modules */
        modules: {
            dollarhandles: 'DollarHandles',
            customquestion: 'CustomQuestion',
        },
        /** properties|data as field */
        fields: {
            amount: 'selectedValue',
            value: 'selectedValue',
            textvalue: 'textValue',
        }
    },

    resolveModuleAlias(alias){
        if(alias.toLowerCase().includes(this.aliases.modules.customquestion.toLowerCase()))
            return alias;
        return this.aliases.modules[alias.toLowerCase()];
    },

    resolveFieldAlias(alias){
        return this.aliases.fields[alias.toLowerCase()];
    }
}

const actions = {show: 'show', hide: 'hide'};
const operators = {
    lessThan: '<',
    lessThanOrEqualTo: '<=',
    greaterThan: '>',
    greaterThanOrEqualTo: '>=',
    equalTo: '=',
    notEqualTo: '!=',
}
const unionOperators = {and: '&&', or: '||'}
const customQuestionComponents = ['Options', 'Freetext']

export { actions, operators, unionOperators, customQuestionComponents };
export const resolveModuleAlias = aliasing.resolveModuleAlias.bind(aliasing);
export const resolveFieldAlias = aliasing.resolveFieldAlias.bind(aliasing);
