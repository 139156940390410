<template lang="html">
  <div 
    class="loader-container active"
  >
    <span class="processing" />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
  .loader-container {
      display: none;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 2em;
      width: 2em;
  }

  .loader-container.active {
      display: inline;
  }

  .processing {
    display: inline-block;
    height: 200px;
    width: 200px;
    background-repeat: no-repeat; 
    background-image: url('../../../../assets/loader-orange.gif');
  }
</style>
