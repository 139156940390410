<script>
import _ from 'lodash'
import GfPaymentInputHandler from './GfPaymentInputHandler.vue';
import GfPaymentHandler from './GfPaymentHandler.vue';
import GfPaymentStatusManager from './GfPaymentStatusManager.vue';
import logger from './logger'
import Vue from 'vue'
/**
 * @mixin
 */
export default {
    name: 'GfPaymentComponent',
    mixins: [GfPaymentInputHandler, GfPaymentHandler, GfPaymentStatusManager],
    props: {
        /**
         * This client side key is used for tokenizing the card details.
         */
        tokenizationKey: {
            type: String,
            default: ''
        },

        /**
         * The id of the button that will be used to trigger the payment.
         */
        processButtonId: {
            type: String,
            default: '',
            required: true
        },

        /**
         * The selector of the payment/donate button the will be used as the container
         */
        processButtonSelector: {
            type: String,
            default: '',
        },

        /**
         *
         */
        pendingActionsData: {
            type: Object,
            default: () => null
        },

        /**
         * This object is passed through from the parent which can use to handle messages
         */
        globalEventBus: {
            type: Object,
            default: () => new Vue()
        },

        /**
         * The function that will be called once the widget is ready.
         */
        onReady: {
            type: String,
            default: ''
        },

        /**
         * The function that will be called once the widget failed to load.
         */
        onLoadError: {
            type: String,
            default: ''
        },

        /**
         * The function that will be called once the user input is tokenized.
         */
        onTokenizeSuccess: {
            type: String,
            default: ''
        },

        /**
         * The function that will be called once the token is reset
         */
        onTokenReset: {
            type: String,
            default: ''
        },

        /**
         * The function that will be called if there was an error with the tokenization..
         */
        onTokenizeError: {
            type: String,
            default: ''
        },
        /**
         * You can add any additional properties that needs to be passed to the widgets.
         * The additional properties will be passed to the widgets through the `additional-properties` attributes
         */
        additionalProperties: {
            type: Object,
            default: () => {},
        },
        /**
         * Set the client Ip address (if known) for improved security
         */
        clientIpAddress: {
            type: String,
            default: ''
        },
        tearDownComponent: {
            type: Boolean,
            default: false
        },
        disabled: {
          type: Boolean,
          default: false,
        },
        tokenGenerationRequestId: {
            type: String,
            default: null
        },
        clientReferenceId: {
            type: String,
            default: null
        },
        gatewayId: {
            type: String,
            default: null
        },
        paymentData: {
            type: Object,
            default: () => { },
        },
        embeddedPaymentMode: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            processButton: '',
            paymentMethod: 'CC',
            handlingPendingClientActions: false,
            paymentError: false,
            pollingPeriodInMs: 1500,
            pollingTimeoutInMs: 180000,
            isPaymentGatewaySupported: false,
            paymentTypeName: null,
            validateBeforeSubmitFuncName: 'validateBeforeSubmit',
            customerDetails: null,
            currencyInfo: null
        }
    },
    watch: {
        tearDownComponent(val) {
            if (val && val === true)
                this.tearDown();
        },

        pendingActionsData(val) {
            if (this.store.state.currentGateway.typeId === this.gatewayId){
                this._onReceivedPendingActionsData(val);
            }
        },

        globalEventBus(val) {
           _registerEventBusCallbacks();
        },

        clientReferenceId(val){
            this.paymentClientReference = val
        },

        'store.state.currentGateway': {
          deep: true,
          handler: function(val) {
            logger.logInfo('GfPaymentComponent.watch.currentGateway.' + this.paymentTypeName, val)
            if (val.type === this.paymentTypeName) {
              logger.logInfo('GfPaymentComponent.watch.updating button for ' + this.paymentTypeName)              
              
              this.store.setDisplayAlternateButton(this.additionalProperties && this.additionalProperties.setAlternateDonateButton)
              if (typeof this.readyToInject != 'undefined' && this.readyToInject && typeof this.injectToDOM != 'undefined') {
                this.injectToDOM()
              }
            }
          }
        },

        isPaymentGatewaySupported(val) {
            if (val === false)
                this._onGatewayIsNotSupported(this.paymentTypeName)
        }
    },
    mounted: function() {
        var vm = this;
        vm.processButton = document.getElementById(vm.processButtonId);
        this._registerEventBusCallbacks();

        if (vm.processButton !== null && vm.processButton !== '') {
            vm.processButton.addEventListener('click', function(e) {
                vm.generateToken();
                vm.$nextTick(()=> {
                    if (vm.onTokenizeError !== '' && !vm.detailsTokenized) {
                        window[vm.onTokenizeError]();
                        return;
                    }

                    if (vm.onTokenizeSuccess !== '' && vm.detailsTokenized) {
                        window[vm.onTokenizeSuccess]();
                    }
                });
            });
        }

        window["GfPaymentsModuleApi"] = {
            generateToken: this._handleTokenGenerationRequest
        }

        window[`${this.validateBeforeSubmitFuncName}-${this.paymentMethod}`] = this._validateBeforeSubmit;
    },
    methods: {
        _onReceivedPendingActionsData(val) {
            if (val !== null && !this.handlingPendingClientActions) {
                this.$_GfPaymentComponent_onPendingClientActions(val);
                this.handlingPendingClientActions = true;
            }
        },

        _handleTokenGenerationRequest(requestId) {
            this.tokenGenerationRequestId = requestId;
            this.generateToken();
        },

        _registerEventBusCallbacks() {
            if (this.globalEventBus && this.globalEventBus.$on) {
                logger.logInfo(`global event bus supplied`, this.globalEventBus);
                this.globalEventBus.$on('receivedClientReference', this._onReceivedClientReference);
                this.globalEventBus.$on('updateCustomerDetails', this._onUpdateCustomerDetails);
                this.globalEventBus.$on('updateCurrencyInfo', this._onUpdateCurrencyInfo);
            }
        },

        _onUpdateCustomerDetails(customerDetails) {
            logger.logInfo(`Received Client Details: ${customerDetails}`);
            this.customerDetails = customerDetails;
        },

        _onUpdateCurrencyInfo(currencyInfo) {
            logger.logInfo(`Received Currency Info: ${currencyInfo}`);
            this.currencyInfo = currencyInfo;
        },

        _onReceivedClientReference(clientReference) {
            logger.logInfo(`Received Client reference: ${clientReference}`);
            this.paymentClientReference = clientReference;
            this.$_GfPaymentStatusManager_RetrieveStatusUpdates(
                this.paymentClientReference,
                this.pollingTimeoutInMs,
                this.pollingPeriodInMs,
                this._handlePaymentStatusReceived,
                this._handleSucessMessage,
                this._handlePendingActions,
                this._handleFailure
            );
        },

        _handlePaymentStatusReceived(_intervalId, message) {
            logger.logInfo(`_handlePaymentStatusReceived(), clientReferenceId: ${this.paymentClientReference} message`, message);
        },

        _handleSucessMessage(intervalId, data) {
            logger.logInfo(`_handleSucessMessage(), data:`, data);
            this.globalEventBus.$emit('paymentCompletedSuccessfully', data)
            clearInterval(intervalId);
        },

        _handlePendingActions(intervalId, data) {
            logger.logInfo(`_handlePendingActions(), data`, data);
            this._onReceivedPendingActionsData(data)
        },

        _handleFailure(intervalId, data) {
            logger.logInfo(`_handleFailure(), data`, data);
            this.globalEventBus.$emit('paymentFailure', data)
            clearInterval(intervalId);
        },

        _onGatewayIsNotSupported(data) {
            logger.logInfo(`_onGatewayIsNotSupported(), data`, data);
            this.globalEventBus.$emit('gatewayIsNotSupported', data)
            this.$emit('paymentTypeNotSupported', data);
        },

        /**
         * Call this method once you get successful details from the user input.
         *
         * **nonce** - The nonce or token recieved from the payment service
         *
         * **paymentType** - The type of payment. E.g. visa, mastercard, amex, paypal etc
         *
         * **lastDigits** - Meta data about the tokenised details. This could be the last x digits of the card or email address of account holder
         *
         * **expiry** - The card expiry
         *
         * **additionalData** - optional parameter that allows you to pass in any additional data from your component. The additional data will be
         * emitted through the `data-propertyname` attribute. The object should be in the form of a key-value-pair object. e.g. `{prop1: 'test', prop2: 'test2'}`
         *
         * @param {string} nonce
         * @param {string} paymentType
         * @param {string} lastDigits
         * @param {string} expiry
         * @param {object} [additionalData]
         * @return {undefined}
         * @public
         */
        onPaymentMethodReceived: function (nonce, paymentType, lastDigits, expiry, additionalData) {
            this._updatePaymentMethodData(nonce, paymentType, lastDigits, expiry, additionalData);
        },

        /**
         * This method is called when we get any pending client actions. We should override this method by handling the data
         *
         * **data** - the data of the pending client actions
         *
         * @param {object} data
         */
        $_GfPaymentComponent_onPendingClientActions: function(data) {

        },

        /**
         * Call this method if the client actions succeeded or failed so that it can be handled appropriately
         *
         * @param {bool} isSuccess
         * @param {string} statusMsg
         * @param {object} data
         */
        onClientActionsResponseReceived: function(isSuccess, statusMsg, data) {
            this.handlingPendingClientActions = false;
            this._clientActionsResponseReceived(isSuccess, statusMsg, data);
        },

        /**
         * Call this method to update the payment type once it becomes available.
         *
         * **paymentType** - The type of payment. E.g. visa, mastercard, amex, paypal etc
         *
         * @param {string} paymentType
         * @return {undefined}
         * @public
         */
        onPaymentTypeChanged: function(paymentType) {
            this._updatePaymentType(paymentType);
        },

        /**
         * Call this method to update the payment request after the initial submission has been sent.
         * This is typically called in scenarios where the client side payment component handles the payment authorization
         * and its result needs to be sent to the server to handle.
         *
         * @param {number} requestType
         * @param {object} additionalData
         * @return {object}
         * @public
         */

         onPaymentUpdateRequest: function(requestType, additionalData) {
            logger.logInfo(`Received Request to update payment: clientReference: ${this.paymentClientReference}, requestType: ${requestType}, additionalData:`, additionalData);
            return this.$_GfPaymentStatusManager_UpdatePaymentRequest(
                this.paymentClientReference,
                requestType,
                additionalData
            );
        },

        onPaymentCancelled: function(data) {
            logger.logInfo(`onPaymentCancelled(), data`, data);
            this.globalEventBus.$emit('paymentCancelled', data)
        },

        /**
         * Call this method when the widget has failed to load
         *
         * **err** - error that will be logged
         *
         * @param {string} err
         * @return {undefined}
         * @public
         */
        loadFail: function (err, typeName) {
            console.log(err);
            this.$emit('loadPaymentSdkFailed', typeName);
            if (this.onLoadError !== '' && this.onLoadError) {
                window[this.onLoadError]();
            }
        },

        /**
         * Call this method when the widget is ready
         *
         * @public
         */
        ready: function() {
            if (this.onReady !== '' && this.onReady) {
                window[this.onReady]();
            }
        },

        /**
         * Call this method to to tear down the widget and clear up resources
         *
         * @public
         */
        tearDown: function() {
            this.$emit('teardownSuccess');
        },

        getEnv: function () {
            var env = _.get(this, 'additionalProperties.environment', 'sandbox')

            return env
        },

        injectPaymentGatewayButton: function(btnSelector, paymentType, options) {
          var btnWrapper = document.querySelector(btnSelector)

          logger.logInfo(`injectPaymentGatewayButton, paymentType`, paymentType)
          logger.logInfo(`injectPaymentGatewayButton, btnWrapper`, btnWrapper)

          if (this.processButtonSelector) {
            var processButton = document.querySelector(this.processButtonSelector)

            if (processButton && btnWrapper) {
              processButton.innerHTML = ''
              processButton.prepend(btnWrapper)

              var donateButtonWrapper = processButton.querySelector(btnSelector)
              if (donateButtonWrapper) {
                donateButtonWrapper.style.display = 'block'
              }

              if (_.has(options, 'callback')) {
                options.callback()
              }
            }
            return
          }

          if (btnWrapper) {
            var parentContainer = btnWrapper.closest('div[id*="step-"]')

            logger.logInfo(`injectPaymentGatewayButton, parentContainer`, parentContainer)
            if (parentContainer) {
              var donateButton = parentContainer.querySelector('#gf-epl-action-button .alternate-button-container')
              btnWrapper = parentContainer.querySelector(btnSelector)

              logger.logInfo(`injectPaymentGatewayButton, donateButton`, donateButton)
              logger.logInfo(`injectPaymentGatewayButton, btnWrapper`, btnWrapper)

              if (donateButton && this.store.state.currentGateway.type == paymentType) {
                donateButton.prepend(btnWrapper)

                var donateButtonWrapper = donateButton.querySelector(btnSelector)
                if (donateButtonWrapper) {
                  donateButtonWrapper.style.display = 'block'
                }
              }

              if(this.store.state.currentGateway.type == paymentType){
                this.showInjectedPaymentGatewayButton(btnSelector)
              }
              if (_.has(options, 'callback')) {
                options.callback()
              }
            }
          }
        },

        showInjectedPaymentGatewayButton: function (selector) {
          var button = document.querySelector(selector)
          if (button) {
            var parentContainer = button.closest('div[id*="step-"]')
            if (parentContainer) {
              var altDonateButtonPaymentButtonWrapper = parentContainer.querySelectorAll('#gf-epl-action-button .alternate-button-container .payment-button-wrapper')
              for (var i = 0; i < altDonateButtonPaymentButtonWrapper.length; i++) {
                altDonateButtonPaymentButtonWrapper[i].style.display = 'none'
              }

              var currentGateway = parentContainer.querySelector('#gf-epl-action-button .alternate-button-container ' + selector)
              currentGateway.style.display = 'block'
            }
          }
        },

        isValid() {
            if(typeof window[this.validateBeforeSubmitFuncName] === "function"){
                return window[this.validateBeforeSubmitFuncName]();
            }
            return true;
        },

    }
}
</script>
