<script>
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
import VueRecaptcha from "vue-recaptcha";
Vue.use(SimpleVueValidation);
export default {
  components: {
    VueRecaptcha,
  },
  props: {},
  data() {
    return {
      recaptchaVerified: false,
    };
  },
  methods: {
    executeCaptcha() {
      var vm = this;
      if (vm.captchaEnabled && !vm.recaptchaVerified)
        vm.$refs.invisibleRecaptcha.execute();
      else 
        this.RecaptchaAsVerified("");
    },
    
    RecaptchaAsVerified(token) {
      this.store.updateStatus(true, token);
      this.recaptchaVerified = true;
    },
  },
};
</script>
