<template>
  <div
    v-if="showComponent"
    :id="`gf-epl-regulargiving-${uniqueId}`"
    class="col-12 m-0 p-0"
    :class="settings.class"
  >
    <div class="row">
      <div class="col-12">
        {{ settings.title }}
      </div>
    </div>
    <div class="col-12 m-0 p-0">
      <div class="col-12 row">
        <div
          class="col-6 ui-tab text-center gf-secondary-font gf-epl-regulargiving-oneoff"
          :class="{
            'gf-secondary-bg gf-secondary-text gf-secondary-font': !isRecurringDonation
          }"
          @click="setRecurring(false)"
        >
          {{ settings.oneTimeDonationText }}
        </div>
        <div
          class="col-6 ui-tab gf-secondary-font gf-epl-regulargiving-recurring"
          :class="{
            'gf-secondary-bg gf-secondary-text gf-secondary-font': isRecurringDonation
          }"
          @click="setRecurring(true)"
        >
          {{ settings.recurringDonationText }}
        </div>
      </div>
    </div>
    <donation-frequency
      v-if="isRecurringDonation"
      :default-frequency="settings.selectedFrequency"
      @frequencyChanged="val => (regularGivingFrequency = val)"
    />
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import DonationFrequency from "./DonationFrequency.vue";
import nextPaymentDateCalculator from "./nextPaymentDateCalculator";

export default {
    components: { DonationFrequency },
    mixins: [ModuleBase],
    data() {
        return {
            isRecurringDonation: false,
            regularGivingFrequency: null,
            useNowAsBaseDate: true,
            nextPaymentDate: null,
            entityConfigSettings: [
              {
                type: "Payment",
                subType: "ScheduledPayment"
              }
            ],
            listSettings: [],
            showComponent: true
        };
    },

    watch: {
        isRecurringDonation(val) {
            this.store.setRecurringDonation(val, this.regularGivingFrequency, this.nextPaymentDate);
        },

        regularGivingFrequency(val) {
            this.nextPaymentDate = null;
            if (this.useNowAsBaseDate)
                this.nextPaymentDate = nextPaymentDateCalculator.calculate(val);
            this.store.setRecurringDonation(
                this.isRecurringDonation,
                val,
                this.nextPaymentDate
            );
        }
    },

    methods: {
        $ModuleBase_handleEntityConfigResults(results) {
          this.listSettings = results;
          this.showComponent = this.listSettings[0].AllowScheduledPayments;

          if(!this.showComponent)
            this.isRecurringDonation = false;
        },

        $ModuleBase_afterBaseMounted() {
            this.logger.logInfo(this.settings, "Regular Giving module loaded");

            if (!this.settings.selectedFrequency)
                this.settings.selectedFrequency = this.constants.RegularGivingFrequency.Monthly;

            this.regularGivingFrequency = this.settings.selectedFrequency;

            if (this.settings.enabledByDefault)
                this.isRecurringDonation = this.settings.enabledByDefault;

            if (!this.settings.title)
                this.settings.title = this.gfLocalisation.getLocale(
                    "regularGiving.title"
                );
            if (!this.settings.oneTimeDonationText)
                this.settings.oneTimeDonationText = this.gfLocalisation.getLocale(
                    "regularGiving.oneTime"
                );

            if (!this.settings.recurringDonationText)
                this.settings.recurringDonationText = this.gfLocalisation.getLocale(
                    "regularGiving.recurring"
                );
        },

        setRecurring(val) {
            this.isRecurringDonation = val;
        }
    }
};
</script>
