<script>
import { Client } from 'braintree-web/client';
import { PayPal } from 'braintree-web/paypal';
import { PayPalCheckout } from 'braintree-web/paypal-checkout';
import { DataCollector } from 'braintree-web/data-collector';
import VS2 from '@gf/vue-script2';
import GfCardTypes from "../../../common/GfCardTypes"
import logger from '../../../common/logger'

export default {
  name: 'GfBrainTreePayPalInitializer',
  components: { Client, PayPal, PayPalCheckout, DataCollector },
  data() {
    return {
      paypalBtnElement: null,
      braintree: {
        client: null,
        paypal: null,
        paypalCheckout: null,
        dataCollector: null
      },
      paypalCheckout: null,
      user_paypal: {
        email: ''
      },
      paypalActions: '',
      additionalResponseData: {},
      tokenizeError: this.onTokenizeError,
      isTokenized: false,
      loadFailure: false,
      paymentTypeName: 'braintree-paypal'
    }
  },

  computed:{
    deviceDataRes:{
      get: function(){
        return this.additionalResponseData;
      },
      set: function(id){
        var data = this.additionalResponseData;
        data.device_session_id = id.device_session_id;
        data.fraud_merchant_id = id.fraud_merchant_id;
        data.correlation_id = id.correlation_id;
        this.additionalResponseData = data;
      }
    }
  },
  mounted: function() {
    var vm = this;
    vm.loadPaypalScripts().then(function() {
      vm.createClient();
    });
  },
  methods: {
    paypal_cancel_user: function() {
      this.user_paypal.email = '';
      this.isTokenized = false;
      this.clearInputData();
      logger.logInfo(`PayPal credentials cancelled by user`);
      this.paypalBtnElement.style.display = "block";
      this.paypalActions.enable();
    },

    loadPaypalScripts: function() {
      this.braintree.client = require("braintree-web/client");
      this.braintree.paypal = require("braintree-web/paypal");
      this.braintree.paypalCheckout = require("braintree-web/paypal-checkout");
      this.braintree.dataCollector = require("braintree-web/data-collector");
      return VS2.load('https://www.paypalobjects.com/api/checkout.js');
    },

    createClient: function () {
      var vm = this;
      logger.logInfo(`GfBrainTreePayPalInitializer, additionalProperties:`, vm.additionalProperties);
      var deCodeProperties = vm.additionalProperties;
      vm.paypalBtnElement = document.getElementById(deCodeProperties.buttonPayPal);
      logger.logInfo(`GfBrainTreePayPalInitializer, PayPal Button`, vm.paypalBtnElement);

      if (vm.braintree.paypalCheckout.isSupported()) {
        vm.braintree.client.create({
          authorization: vm.tokenizationKey
        }, function(clientErr, clientInstance) {
          if (clientErr) {
            vm.$data.loadFailure = true;            
            vm.loadFail(clientErr, vm.paymentTypeName);
            return;
          }
          vm.braintree.dataCollector.create({
            client: clientInstance,
            kount: true,
            paypal: true
          }, function(err, dataCollectorInstance) {
            if (err) {
              vm.loadFail(err);
              return;
            }
            var myDeviceData = JSON.parse(dataCollectorInstance.deviceData);
            vm.deviceDataRes = myDeviceData;
          });
          vm.braintree.paypalCheckout.create({
            client: clientInstance
          }, function(paypalCheckoutErr, paypalCheckoutInstance) {
            if (paypalCheckoutErr) {
              vm.$data.loadFailure = true;
              vm.loadFail(paypalCheckoutErr, vm.paymentTypeName);
              return;
            }

            paypal.Button.render({
              env: deCodeProperties.paymentTypeBraintreePaypal,
              style: {
                label: deCodeProperties.styleButtonPaypal.label,
                size: deCodeProperties.styleButtonPaypal.size,
                shape: deCodeProperties.styleButtonPaypal.shape,
                color: deCodeProperties.styleButtonPaypal.color,
                tagline: deCodeProperties.styleButtonPaypal.tagline
              },
              validate: function(actions) {
                vm.paypalActions = actions;
              },
              payment: function() {
                return paypalCheckoutInstance.createPayment({
                  flow: 'vault',
                  displayName: deCodeProperties.displayNamePaypal,
                  billingAgreementDescription: false,
                  enableShippingAddress: false,
                  shippingAddressEditable: false
                });
              },
              onAuthorize: function(data, actions) {
                vm.paypalActions.disable();
                return paypalCheckoutInstance.tokenizePayment(data, function(err, payload) {
                  logger.logInfo(`PayPal credentials tokenised`, payload);
                  vm.isTokenized = true;
                  vm.user_paypal.email = payload.details.email;
                  vm.paypalBtnElement.style.display = "none";
                  vm.onPaymentMethodReceived(payload.nonce, GfCardTypes.types.PAYPAL, payload.details.email, '', vm.deviceDataRes);
                });
              },
              onCancel: function(data) {
                logger.logInfo(`PayPal credentials cancelled`, data);
                vm.paypalBtnElement.style.display = "block";
                vm.tokenizeError = "Payment is cancelled";
                vm.isTokenized = false;
              },
              onError: function(err) {
                logger.logInfo(`PayPal credentials error`, err);
                vm.tokenizeError = "Payment is error";
                vm.isTokenized = false;
              }
            }, deCodeProperties.buttonPayPal).then(function() {});
          });
        });
      } else {
        vm.onPaymentError('Browser does not support');
      }
    }
  }
}
</script>
