<script>

import { GfPaymentsEventBus } from '../../../../src/common/GfPaymentsEventBus';

export default {
    name: 'GfCardholderNameExtension',
    data: function() {
        return {
            cardholderName: '',
            isValid: false,
            additionalData: {}
        }
    },

    mounted: function() {
        var vm = this;
        GfPaymentsEventBus.$on('cardholderNameValueChanged', function(isValid, cardholderName) {
            vm._cardholderDataChange(isValid, cardholderName);
        });
    },

    methods: {
       _cardholderDataChange: function(isValid, cardholderName) {
            this.isValid = isValid;
            this.cardholderName = cardholderName;            
            this.additionalData.cardholderName = this.cardholderName;
            this.onCardholderValueChanged(this.isValid, this.cardholderName);
       },

       onCardholderValueChanged(isValid, cardholderName) { }    
    }
}
</script>