<template>
  <div
    id="gf-epl-cover-cost"
    class="row m-0 p-0 w-100"
  >
    <div class="platform-fee field col-12">
      <div class="field rounded-checkbox">
        <label
          for="IsAccepted"
          class="transition"
        >
          <input
            id="IsAccepted"
            v-model="coveringCost"
            autocomplete="off"
            data-val="true"
            name="IsAccepted"
            type="checkbox"
          >
          <div
            class="box"
            :class="{'gf-secondary-button': coveringCost}"
          /></label>
        <label
          for="coveringCost"
          class="checkbox-detail"
        >
          {{ settings.text }}
        </label>
      </div>
      <hr>
      <div class="row">
        <div class="col-6">
          AUD $0.00
        </div>
        <div class="col-6 text-right">
          {{ settings.coveringCostText }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
export default {
  mixins: [ModuleBase],

  data() {
    return {
      coveringCost: false
    }
  },

  watch: {
    coveringCost(val) {
      this.store.setCoveringCost(val);
    }
  }
};
</script>
<style scoped>
.platform-fee *{
	color: #666666;
	font-size: 12px;
}
</style>
