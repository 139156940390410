<script>
import _ from 'lodash'
import { Client } from 'braintree-web/client';
import { DataCollector } from 'braintree-web/data-collector';
import VS2 from '@gf/vue-script2';
import logger from '../../../common/logger'
import updatePaymentRequestType from "../../../common/updatePaymentRequestTypes.js";
import EmbeddedPaymentStore from "../../embedded-payment-loader/EmbeddedPaymentStore.js";
import googlePayConstants from './googlePayConstants'
import GfPaymentTypes from "../../../common/GfPaymentTypes";

export default {
  name: 'GfBrainTreeGooglePayInitializer',
  components: { Client, DataCollector },
  data() {
    return {
      braintree: {
        instance: {
          client: {
            create() {
              //
            }
          }
        },
        client: null,
        dataCollector: null,
        googlePaymentInstance: null,
      },
      googlePay: {
        client: null,
      },
      additionalResponseData: {},
      tokenizeError: this.onTokenizeError,
      isTokenized: false,
      loadFailure: false,
      isPaymentGatewaySupported: true,
      isPaymentMethodPresent: false,
      store: EmbeddedPaymentStore,
      paymentMethodButtonId: 'gfPresetGooglePayButton',
      isDisabled: false,
    }
  },

  computed:{
    deviceDataRes:{
      get: function(){
        return this.additionalResponseData;
      },
      set: function(id){
        var data = this.additionalResponseData;
        data.device_session_id = id.device_session_id;
        data.fraud_merchant_id = id.fraud_merchant_id;
        data.correlation_id = id.correlation_id;
        this.additionalResponseData = data;
      }
    }
  },

  watch: {
    'store.state.currentGatewayTrigger': {
      deep: true,
      handler: function (val) {
        if (this.isPaymentMethodPresent) {
          this.setAsDefaultPaymentMethod()
        }
      }
    }
  },

  methods: {
    async loadGooglePayScripts() {
      await Promise.all([
        VS2.load('https://pay.google.com/gp/p/js/pay.js'),
        VS2.load('https://js.braintreegateway.com/web/3.97.1/js/client.min.js'),
        VS2.load('https://js.braintreegateway.com/web/3.97.1/js/google-payment.min.js')
      ]);
    },

    setInstance() {
      if (typeof braintree != 'undefined') {
        this.braintree.instance = braintree
      }
    },

    _getEnv() {
      if (this.getEnv().toLowerCase() == 'sandbox') {
        return googlePayConstants.environment.test
      }
      else {
        return googlePayConstants.environment.production
      }
    },

    initializeClient() {
      var vm = this;
      var env = this._getEnv()
      logger.logInfo(`GfBraintreeGooglePayInitializer, environment:`, env)
      return new google.payments.api.PaymentsClient({
        environment: env
      })
    },

    async getJwtFromServer() {
      logger.logInfo("GfBrainTreeGooglePayInitializer, executing getJwtFromServer");
      var jwtReceived = false;
      while (!jwtReceived) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (this.paymentJwt != null) {
          logger.logInfo("GfBrainTreeGooglePayInitializer, getJwtFromServer, paymentJwt: ", this.paymentJwt);
          jwtReceived = true
        }
      }
      return this.paymentJwt;
    },

    setAsDefaultPaymentMethod() {
      if (this.store.state.supportedGateways && this.store.state.supportedGateways.length > 0)  {
        this.store.setDefaultPaymentGateway(this.paymentTypeName)
      }
    },

    createClient() {
      var vm = this;

      this.googlePay.client = this.initializeClient()
      this.setInstance()

      logger.logInfo(`GfBraintreeGooglePayInitializer, createClient`)
      logger.logInfo(`GfBraintreeGooglePayInitializer, googlePay.client:`, this.googlePay.client)
      logger.logInfo(`GfBraintreeGooglePayInitializer, vm.tokenizationKey:`, vm.tokenizationKey)
      logger.logInfo(`GfBraintreeGooglePayInitializer, additionalProperties:`, vm.additionalProperties)

      this.braintree.instance.client.create({
        authorization: vm.tokenizationKey
      }, (clientErr, clientInstance) => {

        this.braintree.instance.googlePayment.create({
          client: clientInstance,
          googlePayVersion: googlePayConstants.googlePayVersion,
          googleMerchantId: _.get(vm, 'additionalProperties.googlepayMerchantId', '')
        }, (googlePaymentErr, googlePaymentInstance) => {

          this.braintree.googlePaymentInstance = googlePaymentInstance

          var readyToPayRequestOptions = {
            apiVersion: googlePayConstants.apiVersion,
            apiVersionMinor: googlePayConstants.apiVersionMinor,
            allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
          }
          this.googlePay.client.isReadyToPay(readyToPayRequestOptions)
            .then(response => {
              logger.logInfo(`GfBraintreeGooglePayInitializer, this.googlePay.client.isReadyToPay response:`, response)
              if (!vm.isAppleDevices() && response.result) {
                if (_.has(response, 'paymentMethodPresent') && response.paymentMethodPresent === true) {
                  logger.logInfo(`GfBraintreeGooglePayInitializer, this.googlePay.client. isPaymentMethodPresent to true`)
                  vm.isPaymentMethodPresent = true
                }

                const container = document.getElementById(this.paymentMethodButtonId);
                vm.createButton(container)
                vm.readyToInject = true
                if (this.isInjectedToDom === false) {
                  this.injectToDOM();
                }
              } else {
                logger.logInfo(`GfBraintreeGooglePayInitializer, googlepay is not supported`)
                vm.isPaymentGatewaySupported = false
              }
            }).catch(err => {
              logger.logInfo(`GfBraintreeGooglePayInitializer, caught err:`, err)
              vm.isPaymentGatewaySupported = false
            }).finally(() => {
              vm.isLoading = false
            })
        })
      })

    },

    isAppleDevices() {
        var isIOSDevices = (/iphone|ipod|ipad/).test
             (navigator.userAgent.toLowerCase());
        return isIOSDevices;
    },

    async createPaymentDataRequest() {
      try {
        var vm = this;
        var currentCode = this.store.state.currencyInfo.code

        vm.onPaymentMethodReceived(
            Date.now().toString(),
            GfPaymentTypes.GOOGLEPAY,
            "",
            "",
            { forceSubmit: true }
          );

        var result = await vm.getJwtFromServer();
        logger.logInfo("Jwt is: " + result);

        var amount = this.store.state.amount
        var env = this._getEnv();
        var paymentDataRequestConfig = {
          environment: env,
          transactionInfo: {
            currencyCode: _.get(this, 'additionalProperties.currencyCode', currentCode),
            totalPriceStatus: googlePayConstants.totalPriceStatus.final,
            totalPrice: amount.toString()
          }
        }

        if (env == googlePayConstants.environment.production) {
          paymentDataRequestConfig.merchantInfo = {
            merchantId: _.get(vm, 'additionalProperties.googlepayMerchantId', ''),
            merchantOrigin: vm._getMerchantOrigin(),
            merchantName: _.get(vm, 'additionalProperties.googlepayMerchantName', ''),
            authJwt: this.paymentJwt
          }
        }

        logger.logInfo(`GfBraintreeGooglePayInitializer, paymentDataRequestConfig:`, paymentDataRequestConfig)

        var paymentDataRequest = this.braintree.googlePaymentInstance.createPaymentDataRequest(paymentDataRequestConfig)
        return paymentDataRequest
      }
      catch(err) {
        logger.logInfo(`createPaymentDataRequest, err`, err)
      }
      return null;
    },

    createButton(container) {
      const button = this.googlePay.client.createButton({
        buttonColor:  _.get(this, 'additionalProperties.buttonStyle.color', 'default'),
        buttonType: _.get(this, 'additionalProperties.buttonStyle.type', 'pay'),
        buttonSizeMode: _.get(this, 'additionalProperties.buttonStyle.sizeMode', 'fill'),
        onClick: () => {},
        allowedPaymentMethods: this.braintree.googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods
      })


      logger.logInfo(`GfBraintreeGooglePayInitializer, button:`, button)

      if (container) {
        container.appendChild(button)
      }

      this.setButtonOnClickEventListener(button)
    },

    _getMerchantOrigin() {
      return this.merchantOrigin;
    },

    setButtonOnClickEventListener(button) {
      var vm = this
      button.addEventListener('click', async event => {
        event.preventDefault()
        if (vm.isValid() === false) {
          vm.isDisabled = false
          logger.logInfo("setButtonOnClickEventListener, isValid is FALSE")
          return;
        }

        vm.isDisabled = true
        try {
          var paymentDataRequest = await vm.createPaymentDataRequest()
          if(!paymentDataRequest) return

          logger.logInfo(`GfBraintreeGooglePayInitializer, google pay button click, paymentDataRequest`, paymentDataRequest)
          var paymentData = await this.googlePay.client.loadPaymentData(paymentDataRequest)
          logger.logInfo(`GfBraintreeGooglePayInitializer, loadPaymentData, paymentData`, paymentData)
          this.braintree.googlePaymentInstance.parseResponse(paymentData, (err, result) => {
            if (err) {
              logger.logInfo(`GfBraintreeGooglePayInitializer, parseResponse, error`, err)
              return
            }

            logger.logInfo(`GfBraintreeGooglePayInitializer, parseResponse, result`, result)
            vm.onPaymentUpdateRequest(updatePaymentRequestType.TOKENIZED, { "nonce": result.nonce })
          })

        }
        catch(err) {
          this.isDisabled = false
          logger.logInfo(`GfBraintreeGooglePayInitializer, loadPaymentData, err`, err)
          vm.onClientActionsResponseReceived(false, "failed to make payment", 'error occured');
          vm.onPaymentUpdateRequest(updatePaymentRequestType.CANCELLED)
          vm.onPaymentCancelled(true)
        }
      });
    }
  }
}
</script>
