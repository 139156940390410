<template>
  <div
    :id="wrapperId"
    class="col-12 m-0 p-0"
  >
    <span v-html="settings.description" />
    <div class="col-12">
      <div
        v-for="(option, optionIndex) in settings.options"
        :key="optionIndex"
        class="field rounded-checkbox row"
      >
        <label
          :for="getOptionValue(option)"
          class="transition"
        >
          <input
            :id="getOptionValue(option)"
            v-model="checkedValues"
            type="checkbox"
            :value="getOptionValue(option)"
            :name="settings.uniqueIdentifier"
            autocomplete="off"
            data-val="true"
          >
          <div
            class="box"
            :class="{'gf-secondary-bg gf-secondary-text': checkedValues.includes(getOptionValue(option))}"
          />
        </label>
        <label 
          :for="getOptionValue(option)"
        >
          {{ option.text }}
        </label>
      </div>
      <div class="field rounded-checkbox row">
        <label
          :for="otherValue"
          class="transition"
        >
          <input
            :id="otherValue"
            v-model="checkedValues"
            :value="otherValue"
            autocomplete="off"
            data-val="true"
            :name="settings.uniqueIdentifier"
            type="checkbox"
          >
          <div
            class="box"
            :class="{'gf-secondary-bg gf-secondary-text': checkedValues.includes(this.otherValue)}"            
          /></label>
        <input
          id="txtOther"
          v-model="otherValueManual"
          type="text"
          :placeholder="otherText"
          :disabled="!isOtherChecked"
          class="other-textbox"
          data-val="true"
        >
      </div>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";

export default {
  name: "Checkboxmultiple",
  mixins: [ModuleBase],
  props:{
    values: {
      type: Object,
      default: function () {
        return { }
      }
    }
  },
  data() {
    return {
      checkedValues: [],
      otherValueManual: '',
      otherValue: this.settings.defaultOtherValue,
      otherText: this.settings.otherText,
    }
  },
  computed: {
    wrapperId() {
      return `custom-question-checkboxmultiple-wrapper-${this.settings.uniqueIdentifier}`
    },
    isOtherChecked() {
      return this.checkedValues.includes(this.otherValue)
    }
  },
  watch: {
    checkedValues() {
      setTimeout(() => {
        this.handleCheckedValues()
      }, 100)
    },
    otherValueManual() {
      this.logger.logInfo(`otherValueManual changed:`, this.otherValueManual)
      var previousOtherValue = this.otherValue
      if (this.otherValueManual === null || this.otherValueManual === '') {
        this.otherValue = this.settings.defaultOtherValue
      }
      else {
        this.otherValue = this.otherValueManual
      }

      this.checkedValues[this.checkedValues.indexOf(previousOtherValue)] = this.otherValue
      this.handleCheckedValues()
    },
  },
  mounted() {
    this.logger.logInfo('CheckboxMultiple component loaded. Settings:', this.settings)
  },

  methods: {
    storeUpdatedValue() {
      var metaDataValue = {
        metaType: this.constants.PaymentMetaType.Custom,
        metaValueType: this.constants.PaymentMetaValueType.String,
        name: this.settings.uniqueIdentifier,
        description: this.settings.description, 
        value: this.checkedValues.toString()
      }
      this.store.updateCustomQuestionValue(metaDataValue)
    },
    handleCheckedValues() {
      this.logger.logInfo(`checkedValues changed:`, this.checkedValues)
      this.storeUpdatedValue()
    },
    getOptionValue(option) {
      return option.value === undefined || option.value === null || option.value === '' ? option.text : option.value
    }
  },
};
</script>