<template>
  <div
    id="gf-epl-payment"
    class="col-12 m-0 p-0 "
  >
    <div
      v-if="sharedState && !sharedState.gatewayLoadStatus.success"
      class="h4 text-danger text-center"
    >
      {{ sharedState.gatewayLoadStatus.msg }}
    </div>
    <div
      v-if="sharedState && sharedState.gatewayLoadStatus.success && sharedState.supportedGateways && sharedState.supportedGateways.length > 0"
      class="overflow-hidden"
    >
      <div
        v-if="sharedState.supportedGateways.length > 1"
        class="payment-options row"
        :disabled="disabled"
      >
        <div
          v-for="(item, key) in sharedState.supportedGateways"
          :key="key"
          class="ui-tile-item col"
        >
          <div class="field">
            <input
              :id="item.paymentMethod"
              v-model="selectedGateway"
              type="radio"
              :value="key"
            >
            <label :for="item.paymentMethod"><span
              :class="{
                'gf-secondary-bg gf-secondary-text': key == selectedGateway,
              }"
            >{{
              getPaymentMethodFriendlyName(
                item.paymentMethod
              )
            }}</span></label>
          </div>
        </div>
      </div>
      <div
        v-for="(item, key) in sharedState.supportedGateways"
        :key="key"
        :class="item.type"
        class="payment-type"
        :title="getPaymentMethodFriendlyName(item.paymentMethod)"
      >
        <SlideUpDown
          :key="`slide-${key}`"
          :active="key == selectedGateway"
          :use-hidden="false"
          :duration="750"
        >
          <div
            class="panel panel-default py-3"
            :class="{ errorBox: errorMessage }"
          >
            <component
              :is="getComponentName(item.type)"
              :gateway-id="item.typeId"
              :additional-properties="getAdditionalProperties(item.additionalSettings)"
              :tokenization-key="item.publicToken"
              process-button-id="process-payment-bt"
              process-button-selector=""
              :global-event-bus="sharedState.globalEventBus"
              on-tokenize-success="embeddedTokenSuccess"
              :is-supported-payment-types-hidden="settings.isSupportedPaymentTypesHidden"
              :on-token-reset="onTokenReset"
              :on-fees-received="onFeesReceived"
              :on-ready="onReady"
              :on-load-error="onLoadError"
              :client-ip-address="sharedState.clientIpAddress"
              tear-down-component="tearDownComponent"
              :disabled="disabled"
              :pending-actions-data="sharedState.pendingPaymentActionsData"
              :client-reference-id="sharedState.paymentClientReference"
              :embedded-payment-mode="true"
              @clientActionsResponse="onClientActionsResponse"
              @tokenReset="onTokenResetEvent"
              @tokeniseSuccess="onTokenSuccess"
              @teardownSuccess="$emit('teardownSuccess')"
              @loadPaymentSdkFailed="onLoadPaymentSdkFailed"
              @paymentTypeNotSupported="onPaymentTypeNotSupported"
            />
            <div
              v-if="item.paymentMethod === 'PP'"
              id="gfPresetPayPalBtn"
              class="col-12 col-md-8 m-auto pb-3"
            />
            <div
              v-for="(errorItem, errorkey) in sdkErrorMessageByType(item.type)"
              :key="errorkey"
              class="errorMessage"
            >
              {{ errorItem.errorMessage }}
            </div>
          </div>
        </SlideUpDown>
        <div id="process-payment-bt" />
      </div>
    </div>
    <div class="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import ModuleBase from "../ModuleBase.vue";
import SupportedGateways from "../../../SupportedGateways.vue";
import { VueTabs, VTab } from "vue-nav-tabs";
import SlideUpDown from "vue-slide-up-down";
import "vue-nav-tabs/themes/vue-tabs.css";

export default {
  name: 'Payment',
	components: {
		VueTabs,
		VTab,
    SlideUpDown,
	},
	mixins: [ModuleBase, SupportedGateways],

	data() {
		return {
			selectedGateway: 0,
			gatewayTokenDetails: {},
			tearDownComponent: false,
			tokenDetails: null,
			errorMessage: "",
			sdkErrorMessage: [],
			tokenGenerationRequestId: null,
		};
	},
	watch: {
		"sharedState.paymentResponse": {
			deep: true,
			handler() {
				this.logger.logInfo(
					`paymentResponse Changed`,
					this.sharedState.paymentResponse
				);

				if (this.hasErrorMessage()) {
					this.errorMessage = this.sharedState.paymentResponse.Status.Message;
					this.tokenGenerationRequestId = new Date().toISOString();
					window["GfPaymentsModuleApi"].generateToken(this.tokenGenerationRequestId);
					this.store.setProcessingPayment(false)
				}
				return "";
			},
		},
    'sharedState.currentGateway': {
      deep: true,
      handler: function (val) {
        this.onCurrentGatewayChanged(val)
      }
    },
    'sharedState.supportedGateways': {
      deep: true,
      handler: function (val) {
        this.logger.logInfo(`watch.supportedGateways`, val);
        if (val.length >= 1) {
            this.store.setCurrentGateway(val[0])
        }
      }
    },
    'sharedState.defaultPaymentGateway': {
      deep: true,
      handler: async function (type) {
        const supportedGateways = !_.isEmpty(this.sharedState.supportedGateways)
            ? this.sharedState.supportedGateways
            : []

        this.logger.logInfo('watch.defaultPaymentGateway', type)

        if (supportedGateways.length > 0 && !_.isEmpty(type)) {
          const gatewayIndex = supportedGateways.findIndex(i => i.type == type)
          if (gatewayIndex > -1) {
            this.selectedGateway = gatewayIndex
          }
        }
      }
    },
		selectedGateway(val) {
			var selectedGatewayType = this.sharedState.supportedGateways[val].Type || this.sharedState.supportedGateways[val].type;
      		this.store.setCurrentGateway( this.sharedState.supportedGateways[val])
			this.logger.logInfo(`Selected gateway changed`, selectedGatewayType);
			this.logger.logInfo("token details", this.gatewayTokenDetails);

			if (
				this.gatewayTokenDetails[selectedGatewayType] &&
				this.gatewayTokenDetails[selectedGatewayType].paymentToken &&
				this.gatewayTokenDetails[selectedGatewayType].paymentToken
					.length > 0
			) {
				window[this.onTokenizeSuccess](
					this.gatewayTokenDetails[selectedGatewayType]
				);
				this.tokenDetails = this.gatewayTokenDetails[
					selectedGatewayType
				];
			} else {
				if (this.onTokenReset !== "" && this.onTokenReset) {
					window[this.onTokenReset]();
					this.tokenDetails = null;
				}
			}
		},

		tokenDetails(val) {
			this.$nextTick(async () => {
				await this.$ModuleBase_validate();
			});
		},
	},

	validators: {
		tokenDetails(val) {
			if (typeof this.sharedState.currentGateway.type != 'undefined' && this.sharedState.currentGateway.type == 'paypal-paypal') {
				return this.validator.value(true).match(true)
			}


			return this.validator.custom(() => {
				if (val && val.paymentToken && val.paymentToken.length > 0)
					return;
				else return "Card details are invalid";
			});
		},
	},

	mounted() {
		window.embeddedTokenSuccess = () => { };
		window.tearDownComponent = this.tearDown;
		this.isValid = false;
	},

	methods: {

        getAdditionalProperties(gatewayItemSettings) {
            var settings = gatewayItemSettings

            if (_.get(this.additionalProperties, 'environment')) {
                settings.environment = this.additionalProperties.environment
            }

            return settings
        },

		getPaymentMethodFriendlyName(paymentMethod) {
			if (!paymentMethod) return "Credit Card";

			switch (paymentMethod.toLowerCase()) {
				case "gp":
					return "Google Pay";
				case "ap":
					return "ApplePay";
				case "pp":
					return "PayPal";
				default:
					return "Credit Card";
			}
		},
		onClientActionsResponse(data) {
			this.logger.logInfo(`onClientActionsResponse`, data);
			this.store.updateClientActionsResponse(data);
		},

		onTokenResetEvent(source) {
			this.logger.logInfo(`onTokenResetEvent from ${source}`);
			this.gatewayTokenDetails[this.getFriendlyName(source)] = {};
			this.tokenDetails = null;
			this.store.resetTokenDetailsForTransaction();
		},

		sdkErrorMessageByType(type){
			return this.sdkErrorMessage.filter(x =>x.type == type)
		},

		onLoadPaymentSdkFailed(type){
			this.sdkErrorMessage.push({
				type: type,
				errorMessage : this.gfLocalisation.getLocale(
                    "validation.loadPaymentSdkFailed"
                )
			})
		},

		onPaymentTypeNotSupported(type){
			this.store.removeFromSupportedGateway(type)
		},

		onTokenSuccess(val) {
			this.logger.logInfo("onTokenSuccess", val);
			if(val.additionalData.forceSubmit)
				this.$ModuleBase_notifyParent('DONATE');

			if (val.source && val.source.length > 0) {
					this.gatewayTokenDetails[
						this.getFriendlyName(val.source)
					] = val;
					this.tokenDetails = val;
				}

			this.store.setTokenDetailsForTransaction(
				this.tokenDetails.paymentToken,
				this.tokenDetails.additionalData.paymentType,
				this.tokenDetails.additionalData.cardholderName
			);

			if (this.hasErrorMessage() && val.tokenGenerationRequestId != this.tokenGenerationRequestId) {
				this.errorMessage = "";
				this.store.overrideProceedToNext(true);
			}
		},

		hasErrorMessage() {
			if (
				this.sharedState &&
				this.sharedState.paymentResponse &&
				this.sharedState.paymentResponse.Status &&
				this.sharedState.paymentResponse.Status.Message &&
				!this.sharedState.paymentResponse.Status.Success
			)
				return true;

			return false;
		},

		handleTabChange(tabIndex, newTab, oldTab) {
			this.selectedGateway = tabIndex;
		},

		tearDown() {
			this.tearDownComponent = true;
		},

        onCurrentGatewayChanged(val) {
            this.logger.logInfo(`Payment.watch.sharedState.currentGateway.setDisplayAlternateButton to false`);
            this.store.setDisplayAlternateButton(false)
            this.errorMessage = ''
            this.$nextTick(async () => {
                this.logger.logInfo(`Payment.watch.sharedState.currentGateway.moduleBaseValidate()`, val);
                await this.$ModuleBase_validate();
                this.store.triggerGenerateToken()
            });
        },
	},
};
</script>
<style scoped>
.ui-tile-item input[type="radio"],
.hide-radio input[type="radio"] {
	visibility: hidden;
	position: absolute;
}

.ui-tile-item label>span {
	background: #f7f7f7;
	border-radius: 5px;
	text-align: center;
	display: block;
	padding: 12px;
	cursor: pointer;
}

.ui-tile-item input+label,
.ui-tile-item input+label>span {
	width: 100%;
}

.ui-tile-item input:checked+label>span {
	background: #1388cd;
	color: #fff;
}

.errorBox {
	border: solid red;
	padding: 10px;
	margin-right: 1px;
}

.errorMessage {
	padding-bottom: 10px;
	color: red;
}

div[disabled] {
	opacity: 0.6;
	pointer-events: none;
	touch-action: none;
}

div.paypal-paypal .errorBox{
	display:none
}
</style>
