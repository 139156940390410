<template lang="html">
  <div>
    <div v-if="loadedStripe">
      <StripeElements
        ref="elms"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
        #default="{ elements }"
        :stripe-key="tokenizationKey"
      >
        <div id="stripeWidget">
          <div class="panel panel-default">
            <div
              v-if="loadedStripe"
              class="credit-card-inputs"
              :class="{ complete }"
            >
              <div class="row">
                <div class="form-group col-12">
                  <gf-cardholder-toggle label="I am the Card Holder" />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12">
                  <gf-custom-cardholder-name />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12">
                  <StripeElement
                    id="card-number-element"
                    ref="cardNumber"
                    class="form-control stripe-element card-number"
                    type="cardNumber"
                    :elements="elements"
                    :options="cardNumberOptions"
                    @blur="unfocusedCard"
                    @change="number = $event.complete; onChangeTypeCreditCard($event)"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-6">
                <label
                  class="control-label"
                  for="card-expiry"
                >Expiry</label>
                <StripeElement
                  id="card-expiry"
                  ref="cardExpiry"
                  class="form-control stripe-element card-expiry"
                  type="cardExpiry"
                  :elements="elements"
                  :options="options"
                  @change="expiry = $event.complete"
                />
              </div>
              <div class="form-group col-6">
                <label
                  class="control-label"
                  for="card-security"
                >Security Number</label>
                <StripeElement
                  id="card-security"
                  ref="cardCvc"
                  class="form-control stripe-element card-cvc"
                  type="cardCvc"
                  :elements="elements"
                  :options="options"
                  @change="cvc = $event.complete"
                />
              </div>
            </div>
          </div>
        </div>
      </StripeElements>
    </div>
    <div
      id="card-name-selector-wrap"
      class="col-12 pl-0 pr-0"
    >
      <ul class="pl-0 pr-0 row">
        <li class="col-12">
          <GfSupportedPaymentTypes :highlight-item="cardTypeToHighlight" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {
  StripeElements,
  StripeElement,
} from 'vue-stripe-elements-plus'
import GfPaymentComponent from '../../../common/GfPaymentComponent.vue';
import GfSupportedPaymentTypes from '../../common/GfSupportedPaymentTypes.vue';
import GfStripeInitializer from './GfStripeInitializer.vue';
import { GfCardholderToggle, GfCustomCardholderName, GfCardholderNameMixin } from '../../common/cardholder-name-widget/GfCardholderNameExtension.js';
import logger from "../../../common/logger"
export default {
  name: 'GfStripeCreditCard',
  components: {
    GfCardholderToggle,
    GfCustomCardholderName,
    GfSupportedPaymentTypes,
    StripeElements,
    StripeElement
  },
  mixins: [GfStripeInitializer, GfPaymentComponent, GfCardholderNameMixin],
  data: function() {
    return {
      cardTypeToHighlight: 'all',
      processingActions: false,
      completedPayment: false,
      cardNumberOptions: {
        placeholder: 'Credit Card Number',
        style: {
          base: {
            fontSize: '18px',
            fontFamily: 'Arial'
          }
        }
      },
      securityNumberOptions: {
        placeholder: 'CVV'
      },
      paymentTypeName: 'stripe-credit-card',

    }
  },
  methods: {
    generateToken() {
      this.createStripeToken();
    },

    onChangeTypeCreditCard(payload) {
      this.onPaymentTypeChanged(this.normaliseCard(payload.brand));
      this.highlightEnteredCardType(this.normaliseCard(payload.brand));
      this.update();
    },

    unfocusedCard(){
      this.update();
    },

    onCardholderValueChanged(isValid, cardholderName) {
      if (!isValid)
        return this.clearInputData();
      this.onPaymentMethodReceived(this.lastToken.token, this.lastToken.type, this.lastToken.last4, this.lastToken.expiry, this.additionalData);
    },

    $_GfPaymentComponent_onPendingClientActions(data) {
        var self = this;
        if (!data || self.processingActions || self.completedPayment)
            return;

        self.processingActions = true;

        if (data.HasError) {
            this.clientActionsResponseReceived(false, "failed to make payment", {});
            return;
        }
        const pmData = {
                payment_method: {
                    card: this.$refs.cardNumber.stripeElement,
                }
            }
        const groupComponent = this.$refs.elms
        groupComponent.instance.confirmCardPayment(data.PaymentClientSecret, pmData).then(function(result) {
            logger.logInfo("$_GfPaymentComponent_onPendingClientActions, confirmCardPaymentResponse", result)
            if (result && result.error) {
                self.onClientActionsResponseReceived(false, "failed to make payment", result.error);
            } else {
                self.onClientActionsResponseReceived(true, "success", result);
                self.completedPayment = true;
            }
            self.processingActions = false;
        })
    },
  }
}
</script>
<style>
.stripe-element {
  padding: 5px;
  border: 1px solid #ccc;
  font-family: 'Arial';
  font-weight: 400;
  line-height: 20px;
  font-size: 13.33px;
}

.form-control.stripe-element {
  padding: 10px .25rem 0.375rem .25rem !important;
  font-size: 18px;
}
</style>
