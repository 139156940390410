<template>
  <div
    id="gf-epl-contact-details"
    class="col-12 m-0 p-0"
  >
    <div
      v-if="settings.enableCompanySelector"
      class="details-type row"
    >
      <div class="ui-tile-item col-6">
        <div>
          <input
            id="individual"
            v-model="selectedDonationType"
            type="radio"
            :value="constants.DonationContactType.individual"
            @click="$ModuleBase_resized"
          >
          <label
            for="individual"
            data-amountdesc=""
            title=""
          ><span
            :class="{ 'gf-secondary-bg gf-secondary-text': !showCompanyFields }"
          >Individual</span></label>
        </div>
      </div>

      <div class="ui-tile-item col-6">
        <div>
          <input
            id="company"
            v-model="selectedDonationType"
            type="radio"
            :value="constants.DonationContactType.company"
            @click="$ModuleBase_resized"
          >
          <label
            for="company"
            data-amountdesc=""
            title=""
          ><span
            :class="{
              'gf-secondary-bg gf-secondary-text': showCompanyFields,
            }"
          >Company</span></label>
        </div>
      </div>
    </div>
    <div
      id="tab-individual"
      class="tab-content current"
    >
      <slide-up-down
        :active="showCompanyFields"
        :duration="750"
      >
        <div class="field">
          <label v-if="!settings.isDenseMode">Company name</label>
          <div class="ui input">
            <input
              id="companyName"
              v-model="companyName"
              :placeholder="settings.isDenseMode && 'Company name'"
              type="text"
            >
          </div>
        </div>
      </slide-up-down>
      <div>
        <div class="row">
          <div class="required field ui input col-6">
            <label
              v-if="!settings.isDenseMode"
              for="firstName"
            >First Name</label>
            <input
              id="firstName"
              v-model="firstName"
              :placeholder="settings.isDenseMode && 'First Name'"
              type="text"
              :class="{
                'is-invalid':
                  validation.hasError('firstName') &&
                  validation.isTouched('firstName'),
              }"
            >
          </div>
          <div class="required field ui input col-6">
            <label
              v-if="!settings.isDenseMode"
              for="firstName"
            >Last Name</label>
            <input
              id="lastName"
              v-model="lastName"
              :placeholder="settings.isDenseMode && 'Last Name'"
              type="text"
              :class="{
                'is-invalid':
                  validation.hasError('lastName') &&
                  (validation.isTouched('lastName') || validation.isTouched('firstName')),
              }"
            >
          </div>
        </div>
      </div>

      <div
        v-if="showDisplayNameFormat"
        class="field"
      >
        <select
          id="name-display"
          v-model="displayNameFormat"
          name="name-display"
        >
          <option :value="constants.DisplayNameFormat.fullName">
            Show full name
          </option>
          <option :value="constants.DisplayNameFormat.anonymous">
            Anonymous
          </option>
        </select>
      </div>
      <div class="required field">
        <label v-if="!settings.isDenseMode">Email</label>
        <div class="ui input">
          <input
            id="email"
            v-model="email"
            :placeholder="settings.isDenseMode && 'Email'"
            type="text"
            :class="{
              'is-invalid':
                validation.hasError('email') &&
                validation.isTouched('email'),
            }"
            @blur="trimEmail"
          >
        </div>
      </div>

      <div
        v-if="settings.displayMobileNumber"
        :class="settings.isMobileNumberRequired ? 'required field' : 'field'"
      >
        <label v-if="!settings.isDenseMode">Mobile Number</label>
        <div>
          <IntlTelInput
            v-model="mobile"
            :settings="settings"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import AdditionalItemBase from "../AdditionalItem/AdditionalItemBase.vue";
import SlideUpDown from "vue-slide-up-down";
import IntlTelInput from "../../../common/IntlTelInput.vue";

export default {
  name: 'ContactDetails',
	components: {
		SlideUpDown,
        IntlTelInput,
	},
	mixins: [ModuleBase, AdditionalItemBase],

	data() {
		return {
			selectedDonationType: "",
			email: "",
			companyName: "",
			firstName: "",
			lastName: "",
			mobile: {
                local: '',
                complete: '',
                isValid: true,
            },
			displayNameFormat: "",
		};
	},
	computed: {
		showCompanyFields() {
			return (
				this.settings.enableCompanySelector &&
				this.selectedDonationType ===
					this.constants.DonationContactType.company
			);
		},

    showDisplayNameFormat() {
      return this.store.state.donationType == this.constants.DonationType.donation
    }
	},

	watch: {
		selectedDonationType(val) {
			if (val == this.constants.DonationContactType.company)
				this.store.setCompanyName(this.companyName);
			else this.store.setCompanyName("");

			this.$ModuleBase_resized();
		},

		companyName(val) {
			this.store.setCompanyName(val);
		},

		email(val) {
			this.$nextTick(async () => {
				await this.$ModuleBase_validate();
			});

			this.store.updateEmail(val.trim());
		},

		firstName(val) {
			this.$nextTick(async () => {
				await this.$ModuleBase_validate();
			});
			this.store.updateName(val, this.lastName);
		},

		lastName(val) {
			this.$nextTick(async () => {
				await this.$ModuleBase_validate();
			});
			this.store.updateName(this.firstName, val);
		},

		mobile(val) {
            this.$nextTick(async () => {
				await this.$ModuleBase_validate();
			});
			this.store.updateMobile(val.complete);
		},

		displayNameFormat(val) {
			this.store.setNameDisplayMode(val);

      this.isAnonymous = (val === this.constants.DisplayNameFormat.anonymous);
		},

    'store.state.forceContactDetailsAsAnonymous': {
      deep: true,
      handler: function (val) {
        this.displayNameFormat = val
          ? this.constants.DisplayNameFormat.anonymous
          : this.constants.DisplayNameFormat.fullName
      }
    },
	},

	validators: {
		email(value) {
			return this.validator.value(value).required().email();
		},

		firstName(value) {
			return this.validator
				.value(value)
				.required()
				.regex(
					"^[A-Za-z \&'-]*$",
					"Must only contain alphabetic characters."
				);
		},

		lastName(value) {
			return this.validator
				.value(value)
				.required()
				.regex(
					"^[A-Za-z \&'-]*$",
					"Must only contain alphabetic characters."
				);
		},

        mobile(value){
            return this.validator.custom(function(){
                if(!value.isValid) return 'Mobile number not valid';
            })
        }
	},

	mounted() {
		this.validate();
		this.displayNameFormat = this.constants.DisplayNameFormat.fullName;
		this.selectedDonationType = this.constants.DonationContactType.individual;
	},

	methods: {
    $ModuleBase_handlePrefillData(settings) {
      this.logger.logInfo('ContactDetails.$ModuleBase_handlePrefillData.settings', settings)
      for (let prop in settings) {
        this.logger.logInfo('ContactDetails.$ModuleBase_handlePrefillData.props', prop)
        if (typeof this[prop] != 'undefined')
          this[prop] = settings[prop]
      }
    },
		validate() {
			var emailValid = this.email.length > 0;
			var nameValid =
				this.firstName.length > 0 && this.lastName.length > 0;
			this.isValid = emailValid && nameValid && this.mobile.isValid;
		},
    trimEmail() {
      this.email = this.email.trim();
    },
	},
};
</script>
<style scoped>
#gf-epl-contact-details input[type="text"],
#gf-epl-contact-details input[type="email"],
#gf-epl-contact-details input[type="password"],
#gf-epl-contact-details input[type="textarea"],
#gf-epl-contact-details select {
	margin: 0;
	outline: 0;
	tap-highlight-color: hsla(0, 0%, 100%, 0);
	background: #fff;
	border: 1px solid rgba(34, 36, 38, 0.2);
	color: #000;
	border-radius: 5px;
	-webkit-box-shadow: 0 0 0 0 transparent inset;
	box-shadow: inset 0 0 0 0 transparent;
	-webkit-transition: color 0.1s ease, border-color 0.1s ease;
	transition: color 0.1s ease, border-color 0.1s ease;
	line-height: 1.6em;
	padding: 13px;
	width: 99.8%;
}

.field {
	padding-bottom: 25px;
}

.field label {
	margin-bottom: 5px;
}

.ui-tile-item input[type="radio"],
.hide-radio input[type="radio"] {
	visibility: hidden;
	position: absolute;
}

.ui-tile-item label > span {
	background: #f7f7f7;
	border-radius: 5px;
	text-align: center;
	margin-bottom: 20px;
	display: block;
	padding: 12px;
	cursor: pointer;
}

.ui-tile-item input + label,
.ui-tile-item input + label > span {
	width: 100%;
}

.ui-tile-item input:checked + label > span {
	background: #1388cd;
	color: #fff;
}
</style>
