<template>
  <div
    :class="settings.required ? 'required field' : 'field'"
  >
    <span v-html="settings.description" />
    <div v-if="settings.displayType === constants.CustomQuestionOptionsDisplayType.Radio">
      <div
        v-for="(option, optionIndex) in settings.options"
        :key="optionIndex"
      >
        <input
          :id="`option-${optionIndex}`"
          v-model="selectedValue"
          type="radio"
          :value="option.value"
          :name="settings.uniqueIdentifier"
          tabindex="0"
        >
        <label
          :for="`option-${optionIndex}`"
          :title="option.text"
        >
          {{ option.text }}</label>
      </div>
    </div>

    <div v-if="settings.displayType === constants.CustomQuestionOptionsDisplayType.Select">
      <select
        :id="settings.uniqueIdentifier"
        v-model="selectedValue"
      >
        <option
          v-for="(option, optionIndex) in settings.options"
          :key="optionIndex"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";

export default {
  name: "Options",
  mixins: [ModuleBase],
  props:{
    values: {
      type: Object,
      default: function () {
        return { }
      }
    }
  },
  data() {
    return {
      selectedValue: ''
    }
  },
  watch: {
    selectedValue() {
      this.storeUpdatedValue()
      this.$nextTick(async () => {
        await this.$ModuleBase_validate()
      })
    }
  },
	mounted() {
		this.logger.logInfo(this.settings,'Dropdown component loaded')
		if (this.settings.defaultValue !== undefined && this.settings.defaultValue !== null)
			this.selectedValue = this.settings.defaultValue
	},

  methods: {
    storeUpdatedValue() {
      var metaDataValue = {
        metaType: this.constants.PaymentMetaType.Custom,
        metaValueType: this.constants.PaymentMetaValueType.String,
        name: this.settings.uniqueIdentifier,
        description: this.settings.description,
        value: this.selectedValue
      }
      this.store.updateCustomQuestionValue(metaDataValue)
    },
  },
  validators: {
    selectedValue(val) {
      if (this.settings.required) {
        return this.validator
          .value(val)
          .required()
      }
    }
  }
};
</script>
