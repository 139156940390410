<template>
  <div
    id="gf-epl-terms-conditions"
    class="col-12 m-0 p-0"
  >
    <div
      v-if="isCheckbox"
      class="col-12"
    >
      <div class="field rounded-checkbox row">
        <label
          for="Terms1"
          class="transition"
        >
          <input
            id="Terms1"
            v-model="agreeToTerms"
            autocomplete="off"
            data-val="true"
            name="Terms1"
            type="checkbox"
            :disabled="sharedState.isProcessingPayment"
          >
          <div
            class="box"
            :class="{'gf-secondary-bg gf-secondary-text': agreeToTerms}"
          /></label>
        <label
          id="gf-epl-terms-conditions-checkbox-text"
          for="Terms1"
          class="checkbox-detail col"
          v-html="tAndCCheckBoxText"
        />
      </div>
    </div>
   
    <div
      v-if="isNone"
      class="col-12 d-flex pt-2 text-center"
    >
      <label
        id="gf-epl-terms-conditions-checkbox-text"        
        class="checkbox-detail mx-auto"
        v-html="tAndCCheckBoxText"
      />
    </div>

    <div class="col-12">
      <div
        v-if="hasContactOptIn"
        class="field rounded-checkbox row"
      >
        <label
          for="ContactOptIn"
          class="transition"
        >
          <input
            id="ContactOptIn"
            v-model="optInToComms"
            autocomplete="off"
            data-val="true"
            name="ContactOptIn"
            type="checkbox"
            :disabled="sharedState.isProcessingPayment"
          >
          <div
            class="box"
            :class="{'gf-secondary-bg gf-secondary-text': optInToComms}"
          /></label>
        <label
          id="ContactOptInLabel"
          for="ContactOptIn"
          class="terms checkbox-detail col"
        >
          {{ getDescription('contactOptIn') }}
        </label>
      </div>
    </div>
    <div class="col-12">
      <div
        v-if="hasSignupToNewsLetter"
        class="field rounded-checkbox row"
      >
        <label
          for="NewsletterSignUp"
          class="transition"
        >
          <input
            id="NewsletterSignUp"
            v-model="signUpToNewsletter"
            autocomplete="off"
            data-val="true"
            name="NewsletterSignUp"
            type="checkbox"
            :disabled="sharedState.isProcessingPayment"
          >
          <div
            class="box"
            :class="{'gf-secondary-bg gf-secondary-text': signUpToNewsletter}"
          /></label>
        <label
          id="NewsletterSignUpLabel"
          for="NewsletterSignUp"
          class="terms checkbox-detail col"
        >
          {{ getDescription('newsletterSignUp') }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import constants from "../../EmbeddedPaymentConstants";

export default {
  mixins: [ModuleBase],

  data() {
    return {
      agreeToTerms: false,
      entityConfigSettings: [
        {
          type: "Payment",
          subType: "TermsAndConditions"
        }
      ],
      optInToComms: false,
      signUpToNewsletter: false,
      customisedTandCText: null,
      type: constants.TermAndConditionType.Checkbox,
    }
  },

  computed: {
    isCheckbox() {
      return this.type === constants.TermAndConditionType.Checkbox
    },

    isNone() {
      return this.type === constants.TermAndConditionType.None
    },

    hasContactOptIn() {
      return typeof this.settings.contactOptIn != 'undefined' && (this.settings.contactOptIn.length > 0 || Object.keys(this.settings.contactOptIn).length > 0)
    },

    hasSignupToNewsLetter() {
      return typeof this.settings.newsletterSignUp != 'undefined' && (this.settings.newsletterSignUp.length > 0 || Object.keys(this.settings.newsletterSignUp).length > 0)
    },

    tAndCCheckBoxText() {
      if (this.customisedTandCText !== null)
        return this.customisedTandCText

      return `I accept the
          <a
            href="/terms-of-use"
            target="_blank"
          >terms and conditions</a> &amp; I
          have read the
          <a
            href="/data-collection-policy"
            target="_blank"
          >collection policy</a>
          before providing my personal information.`
    }
  },

  watch: {
    optInToComms(val){      
      this.store.updateReceiveNewsletter(val);
    },

    signUpToNewsletter(val){      
      this.store.updateReceiveGFNewsletter(val);
    },

    agreeToTerms(val) {
      this.$nextTick(async () => {
        (await this.$ModuleBase_validate());
      });
    }
  },

  validators: {
    agreeToTerms(value) {
      return this.validator.custom(() => {
        if (!value)
          return 'Must agree to the terms and conditions';
      });
    }
  },

  mounted() {
    this.isValid = this.agreeToTerms;
    if (this.settings.type){
      this.type = this.settings.type
    }

    if(this.isNone){
      this.agreeToTerms = true;
    }

    this.optInToComms = this.setDefaultChecked('contactOptIn')
    this.signUpToNewsletter = this.setDefaultChecked('newsletterSignUp')
  },

  methods: {
    $ModuleBase_handleEntityConfigResults(results) {
      this.logger.logInfo("Terms and conditions entity configuration result", results)
      if (results.length > 0 && results[0].Settings && results[0].Settings.length > 0) {
        var parsed = JSON.parse(results[0].Settings)
        if (parsed.TermsAndConditionsText)
          this.customisedTandCText = parsed.TermsAndConditionsText;
      }
        
    },
    setDefaultChecked(optKey) {
      if (typeof this.settings[optKey] != 'undefined' && Object.keys(this.settings[optKey]).length > 0 && typeof this.settings[optKey].value != 'undefined') {
        return this.settings[optKey].value == 'true' || this.settings[optKey].value == true
          ? true
          : false
      }
      return false
    },

    getDescription(optKey) {
      switch (typeof this.settings[optKey]) {
        case 'string':
          return this.settings[optKey]
        case 'object':
          if (typeof this.settings[optKey].description != 'undefined') {
            return this.settings[optKey].description
          }
          return ''
        default:
          return this.settings[optKey]
      }
    }
  }
};
</script>
<style scoped>
label#gf-epl-terms-conditions-checkbox-text {
    font-size: 12px !important;
}
</style>