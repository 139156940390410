<template>
  <div>
    <div
      v-show="showWrapper"
      id="paypal-wrapper"
    >
      <div
        v-show="!isButtonEnabled"
        id="paypal-button-container-disabled"
      />
      <div
        v-show="isButtonEnabled"
        id="paypal-button-container"
      />
    </div>
  </div>
</template>
<script>
import GfPaymentComponent from "../../common/GfPaymentComponent.vue";
import logger from "../../common/logger";
import VS2 from "@gf/vue-script2";
import GfCardTypes from "../../common/GfCardTypes";
import updatePaymentRequestType from "../../common/updatePaymentRequestTypes.js"

export default {
  name: "GfPayPalPayPal",
  mixins: [GfPaymentComponent],
  data() {
    return {
      cardFields: null,
      orderId: null,
      paymentMethod: "PP",
      processingActions: false,
      clientId: "",
      clientToken: "",
      showWrapper: false,
      isButtonEnabled: false,
      tokenizeError: this.onTokenizeError,
      scriptLoader: VS2,
      paymentTypeName: 'paypal-paypal',
      readyToInject: false,
    };
  },
  watch: {
    'store.state.canProceed': {
      deep: true,
      handler: function (val) {
        this.isButtonEnabled = val
      }
    },
  },
  async mounted() {
    this.initializePaypal()
    logger.logInfo(`Mounted the GfPayPalPayPal component`);
  },
  methods: {
    initializePaypal() {
      this.clientToken = this.additionalProperties.clientToken ? this.additionalProperties.clientToken : this.clientToken;
      this.clientId = this.tokenizationKey? this.tokenizationKey : this.clientId;
      var attr = {
        parent: document.head,
        attributes: [{ key: "data-client-token", value: this.clientToken }, { key: "data-partner-attribution-id", value: this.additionalProperties.bnCode }],
      };
      this.scriptLoader.load(
        `https://www.paypal.com/sdk/js?components=buttons,hosted-fields&commit=false&client-id=${this.clientId}&currency=${this.additionalProperties.currencyCode}&merchant-id=${this.additionalProperties.merchantId}`,
        attr
      ).then(() => {
        this.paypalSetLoaded();
        this.readyToInject = true;
        this.injectToDOM();
      });
      logger.logInfo(`Mounted the GfPayPalPayPal component`);
    },
    paypalSetLoaded() {
      let vm = this

      window.paypal.Buttons({
        onInit: (data, actions) => {
          actions.disable();
        },
        style: {
          color: "blue",
          shape: "pill"
        },
      })
        .render("#paypal-button-container-disabled");

      window.paypal.Buttons({
        style: {
          color: "blue",
          shape: "pill"
        },
        createOrder: async () => {
          vm.onPaymentMethodReceived(
            Date.now().toString(),
            GfCardTypes.types.PAYPAL,
            "",
            "",
            { forceSubmit: true }
          );

          logger.logInfo("starting to create order");
          var result = await vm.getOrderFromServer();
          logger.logInfo("Result is: " + result);
          return result;
        },
        onApprove: () => {
          vm.onPaymentUpdateRequest(updatePaymentRequestType.AUTHORIZED)
        },
        onCancel: function (data) {
          logger.logInfo(`PayPal credentials cancelled`, data);
          vm.onClientActionsResponseReceived(false, "failed to make payment", 'error occured');
        },
        onError: function (e) {
          logger.logInfo("paypal modal error: " + e);
          vm.onClientActionsResponseReceived(false, "failed to make payment", 'error occured');
        }

      })
        .render("#paypal-button-container");
    },

    async getOrderFromServer() {
      var orderReceived = false;
      while (!orderReceived) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (this.orderId != null) {
          orderReceived = true
        }
      }
      return this.orderId;
    },

    $_GfPaymentComponent_onPendingClientActions(data) {
      logger.logInfo(`onPendingClientActions:`, data);
      var self = this;
      if (!data || self.processingActions) return;

      self.processingActions = true;
      this.orderId = data.PaymentClientSecret;
      logger.logInfo("_GfPaymentComponent_onPendingClientActions_Order Id", self.orderId);
    },

    injectToDOM() {
      this.injectPaymentGatewayButton('#paypal-wrapper', 'paypal-paypal', {
        callback: () => {
          this.isButtonEnabled = true
        }
      })
    }
  },
};
</script>
<style lang="css">
#paypal-button-container-disabled .paypal-buttons.paypal-buttons-context-iframe.paypal-buttons-label-unknown.paypal-buttons-layout-vertical {
  filter: opacity(0.5);
}
#paypal-button-container-disabled, #paypal-button-container{
  text-align: center;
}
</style>
