import { render, staticRenderFns } from "./GfPayments.vue?vue&type=template&id=29e7584d&scoped=true&lang=html&"
import script from "./GfPayments.vue?vue&type=script&lang=js&"
export * from "./GfPayments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e7584d",
  null
  
)

export default component.exports