export default {
  PayPal: {
    data: {
      "gatewayTypes": [
      //   {
      //   "type": "paypal-credit-card",
      //   "typeId": 19,
      //   "paymentMethod": "PP",
      //   "publicToken": "pk_paypal_test_Ol53NikYQcIaK9nl2aOj6zD3",
      //   "additionalSettings": "{\"clientToken\":\"eyJicmFpbnRyZWUiOnsiYXV0aG9yaXphdGlvbkZpbmdlcnByaW50IjoiNGI0Y2YwZjAwYWU4MmZmMWNkMjcyNzIyZWYwNTA1MDgwYThkMmJlZDg1YmQyNTMwYWJiYjY5ZDczNjZiZTI4NHxtZXJjaGFudF9pZD1yd3dua3FnMnhnNTZobTJuJnB1YmxpY19rZXk9NjNrdm4zN3Z0MjlxYjRkZiZjcmVhdGVkX2F0PTIwMjItMTAtMjVUMDY6NDk6MjguNzYwWiIsInZlcnNpb24iOiIzLXBheXBhbCJ9LCJwYXlwYWwiOnsiaWRUb2tlbiI6bnVsbCwiYWNjZXNzVG9rZW4iOiJBMjFBQUlDRF9wTnFKc0gwRVMzWFNhbkd3QS16eHh3Qk1UQXNFaXAyMG1tM3Vka0lCRnV6V2R1RTd1QkdMU2JXUE95S1RrcE9FWlZlNnIyWTBXaU0yMktaSE5tMHBuR1Z3In19\"}"
      // }, 
      {
        "additionalSettings": "{\"displayNameSelector\": true, \"displayNameSelectorLabel\": \"I am the Card Holder\" }",
        "paymentMethod": "CC",
        "publicToken": "sandbox_p7f4kscm_h472r2723rfch9w7",
        "type": "braintree-credit-card",
        "typeId": "70"
      },
      {
        "type": "paypal-paypal",
        "typeId": 19,
        "paymentMethod": "PP",
        "publicToken": "pk_paypal_test_Ol53NikYQcIaK9nl2aOj6zD3",
        "additionalSettings": "{\"setAlternateDonateButton\":true,\"clientToken\":\"eyJicmFpbnRyZWUiOnsiYXV0aG9yaXphdGlvbkZpbmdlcnByaW50IjoiNGI0Y2YwZjAwYWU4MmZmMWNkMjcyNzIyZWYwNTA1MDgwYThkMmJlZDg1YmQyNTMwYWJiYjY5ZDczNjZiZTI4NHxtZXJjaGFudF9pZD1yd3dua3FnMnhnNTZobTJuJnB1YmxpY19rZXk9NjNrdm4zN3Z0MjlxYjRkZiZjcmVhdGVkX2F0PTIwMjItMTAtMjVUMDY6NDk6MjguNzYwWiIsInZlcnNpb24iOiIzLXBheXBhbCJ9LCJwYXlwYWwiOnsiaWRUb2tlbiI6bnVsbCwiYWNjZXNzVG9rZW4iOiJBMjFBQUlDRF9wTnFKc0gwRVMzWFNhbkd3QS16eHh3Qk1UQXNFaXAyMG1tM3Vka0lCRnV6V2R1RTd1QkdMU2JXUE95S1RrcE9FWlZlNnIyWTBXaU0yMktaSE5tMHBuR1Z3In19\"}"
      }],
      "fees": [{
        "name": "MX",
        "transactionRate": 0.024000000,
        "transactionFee": 0.100000000
      }, {
        "name": "Visa",
        "transactionRate": 0.006000000,
        "transactionFee": 0.100000000
      }, {
        "name": "Master",
        "transactionRate": 0.006000000,
        "transactionFee": 0.100000000
      }, {
        "name": "PayPal",
        "transactionRate": 0.021000000,
        "transactionFee": 0.200000000
      }],
      "clientIpAddress": "::1",
      "currency": {
        "symbol": "$",
        "name": "Australian dollar",
        "code": "AUD",
        "isoLanguageCode": "en-AU"
      }
    }
  },
  BrainTree: {
    data: {
      "clientIpAddress": "122.53.255.51, 3.105.172.57",
      "fees": [{
        "name": "MX",
        "transactionFee": "0.2",
        "transactionRate": "0.0198"
      }, {
        "name": "Visa",
        "transactionFee": "0.2",
        "transactionRate": "0.011"
      }, {
        "name": "Master",
        "transactionFee": "0.2",
        "transactionRate": "0.011"
      }, {
        "name": "PayPal",
        "transactionFee": "0.2",
        "transactionRate": "0.021"
      }],
      "gatewayTypes": [{
        "additionalSettings": "{\"displayNameSelector\": true, \"displayNameSelectorLabel\": \"I am the Card Holder\" }",
        "paymentMethod": "CC",
        "publicToken": "sandbox_p7f4kscm_h472r2723rfch9w7",
        "type": "braintree-credit-card",
        "typeId": "70"
      }, {
        "additionalSettings": "{\"buttonPayPal\":\"gfPresetPayPalBtn\",\"styleButtonPaypal\":{\"label\":\"pay\",\"size\":\"responsive\",\"shape\":\"rect\",\"color\":\"blue\",\"tagline\":false},\"paymentTypeBraintreePaypal\":\"sandbox\",\"displayNamePaypal\":\"GfPayment\"}",
        "paymentMethod": "PP",
        "publicToken": "sandbox_p7f4kscm_h472r2723rfch9w7",
        "type": "braintree-paypal",
        "typeId": "71"
      }, {
        "additionalSettings": "{\"setAlternateDonateButton\":true,\"buttonPayPal\":\"gfPresetPayPalBtn\",\"styleButtonPaypal\":{\"label\":\"pay\",\"size\":\"responsive\",\"shape\":\"rect\",\"color\":\"blue\",\"tagline\":false},\"paymentTypeBraintreePaypal\":\"sandbox\",\"displayNamePaypal\":\"GfPayment\"}",
        "paymentMethod": "GP",
        "publicToken": "sandbox_p7f4kscm_h472r2723rfch9w7",
        "type": "braintree-googlepay",
        "typeId": "72"
      }, {
        "additionalSettings": "{\"setAlternateDonateButton\":true,\"buttonPayPal\":\"gfPresetPayPalBtn\",\"styleButtonPaypal\":{\"label\":\"pay\",\"size\":\"responsive\",\"shape\":\"rect\",\"color\":\"blue\",\"tagline\":false},\"paymentTypeBraintreePaypal\":\"sandbox\",\"displayNamePaypal\":\"GfPayment\"}",
        "paymentMethod": "GP",
        "publicToken": "sandbox_p7f4kscm_h472r2723rfch9w7",
        "type": "braintree-applepay",
        "typeId": "73"
      }],
      "currency": {
        "symbol": "$",
        "name": "Australian dollar",
        "code": "AUD",
        "isoLanguageCode": "en-AU"
      }
    }
  }
}