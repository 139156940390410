<script>
import GfPaydock from "./paydock/GfPaydock.vue";
import GfBrainTreeCreditCard from "./Braintree/CreditCard/GfBrainTreeCreditCard.vue";
import GfStripeCreditCard from "./Stripe/CreditCard/GfStripeCreditCard.vue"; 
import GfBraintreePayPal from "./Braintree/PayPal/GfBraintreePayPal.vue";
import GfBraintreeGooglePay from "./Braintree/GooglePay/GfBraintreeGooglePay.vue"; 
import GfBraintreeApplePay from "./Braintree/ApplePay/GfBraintreeApplePay.vue"; 
import GfPayPalCreditCard from "./PayPal/GfPayPalCreditCard.vue";
import GfPayPalPayPal from "./PayPal/GfPayPalPayPal.vue";
import GfClickToPayCreditCard from "./ClickToPay/CreditCard/GfClickToPayCreditCard.vue";

export default {
    name: "SupportedGateways",
    components: {
        GfPaydock,
        GfBrainTreeCreditCard,
        GfStripeCreditCard,
        GfBraintreePayPal,
        GfBraintreeGooglePay,
        GfBraintreeApplePay,
        GfPayPalCreditCard,
        GfPayPalPayPal,
        GfClickToPayCreditCard,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        getComponentName(friendlyName) {
            switch (friendlyName) {
                case "paydock":
                    return "GfPaydock";
                case "braintree-credit-card":
                    return "GfBrainTreeCreditCard";
                case "stripe-credit-card":
                    return "GfStripeCreditCard";
                case "braintree-googlepay":
                    return "GfBraintreeGooglePay";
                case "braintree-applepay":
                    return "GfBraintreeApplePay";
                case "braintree-paypal":
                    return "GfBraintreePayPal";
                case "paypal-credit-card":
                    return "GfPayPalCreditCard";
                case "paypal-paypal":
                    return "GfPayPalPayPal";
                case "clicktopay-credit-card":
                case "payfurl-credit-card":
                    return "GfClickToPayCreditCard"
            }
        },

        getFriendlyName(componentName) {
            switch (componentName) {
                case "GfPaydock":
                    return "paydock";
                case "GfBrainTreeCreditCard":
                    return "braintree-credit-card";
                case "GfStripeCreditCard":
                    return "stripe-credit-card";
                case "GfBrainTreePayPal":
                    return "braintree-paypal";
                case "GfPayPalCreditCard":
                    return "paypal-credit-card";
                case "GfPayPalPayPal":
                    return "paypal-paypal";
                case "GfClickToPayCreditCard":
                    return "clicktopay-credit-card";
            }
        },
    },
};
</script>
