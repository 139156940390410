<template>
  <div
    v-if="predefinedValue > 0 && isMatchingActive"
    class="col-12 p-1 w-100 mb-2"
  >
    <div class="row w-100 m-auto">
      <div class="cel-12 m-auto  ">
        <p class="font-weight-bold">
          {{ settings.title }}
        </p>
      </div>
    </div>
    <div class="row w-100 m-auto MatchingBox">
      <div
        id="matchingCalculateBox"
        class="cel-12 m-auto"
      >
        {{ currencySymbol }}{{ predefinedValue }} <span
          class="MultiplierCircule"
          style="border-radius:14px !important;font-family: Arial, sans-serif !important;"
        >x{{ multiplierValue }}</span> = {{ currencySymbol }}{{ totalMatching }}
      </div>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import GfConfigurationClient from "../../../../common/GfConfigurationClient.vue";
import dollarMatchingListSettings from "../../../../data/dollarMatchingListSettings";
import moment from 'moment'

export default {
    name: "DollarMatching",

    mixins: [ModuleBase, GfConfigurationClient],

    data() {
        return {
            selectedValue: 0,
            predefinedValue:0,
            listSettings: [],
            multiplierValue: 0,
            totalMatching: 0,
            isMatchingActive : false,
            entityConfigSettings: [
              {
                type: "Payment",
                subType: "Matching"
              }
            ]
        };
    },

    computed: {
      currencySymbol(){
			  return this.sharedState.currencyInfo.symbol || "$";
		  }
    },

    watch: {
        "sharedState.amount": function (amount) {
			this.predefinedValue = amount;
            this.getDollarMatchingDetails();
		},
    },

     mounted() {
        if (this.selectedValue > 0) this.isValid = true;
        else this.isValid = false;
    },

    methods: {
          populateListSetting(matchingSettings){
            if (matchingSettings == null || matchingSettings.length == 0)
              return

                matchingSettings.forEach(data => {
                  const matchingSetting = {
                    Id: data.RuleId,
                    IsActive : false,
                    Status: data.Status,
                    ProcessedAmount :0,
                    AmountMultiplier:0,
                    CapAmount:0,
                    StartDate:"",
                    EndDate:""
                  }

                  if (data.State && data.State.length > 0) {
                    const ruleSetting = JSON.parse(data.State[0].RuleStateSettings)
                    if (ruleSetting && ruleSetting.ProcessedAmount) {
                      matchingSetting.ProcessedAmount = ruleSetting.ProcessedAmount
                    }
                  }

                  data.ProcessorSettings.forEach(processorSettings => {
                    if (processorSettings.PluginType === 'Validator') {
                      const paymentSetting = JSON.parse(processorSettings.Settings)
                      if (paymentSetting.Conditions && paymentSetting.Conditions.length > 0) {
                        const condition = paymentSetting.Conditions[0]
                        const validationParameter = condition.ValidationParameter

                        const startDateField = validationParameter.Conditions.filter(x => x.ConditionField === 'DatePaid' && x.Operator === 'GreaterThanOrEqual')
                        if (startDateField.length > 0) matchingSetting.StartDate = startDateField[0].Value

                        const endDateField = validationParameter.Conditions.filter(x => x.ConditionField === 'DatePaid' && (x.Operator === 'LessThan' || x.Operator === 'LessThanOrEqual'))
                        if (endDateField.length > 0) matchingSetting.EndDate = endDateField[0].Value
                      }

                      if (paymentSetting.MatchingGift) {
                        matchingSetting.AmountMultiplier = paymentSetting.MatchingGift.Settings.AmountMultiplier
                      }

                      if (paymentSetting.StateConditions && paymentSetting.StateConditions.length > 0) {
                        const processedAmountField = paymentSetting.StateConditions.filter(x => x.ConditionField === 'ProcessedAmount')
                        if (processedAmountField.length > 0) matchingSetting.CapAmount = processedAmountField[0].Value
                      }
                      if (paymentSetting.StateConditions.length == 0)
                        matchingSetting.CapAmount = -1
                    }
                  })
                  matchingSetting.IsActive = this.getIsMatchingActive(matchingSetting)
                  this.listSettings.push(matchingSetting)
                })
          },

          getIsMatchingActive(setting){
              var now = moment();
              var isActive = false
              if(setting.Status !== 'A') return false
              if(setting.EndDate != ""){
                var endDate = moment(setting.EndDate, 'YYYY-MM-DD HH:mm:ss');
                var isActive = endDate.isAfter(now)
                if(!isActive) return false;
              }
              if(setting.StartDate != ""){
                var startDate = moment(setting.StartDate, 'YYYY-MM-DD HH:mm:ss');
                isActive = now.isAfter(startDate)
                if(!isActive) return false;
              }
              if (setting.CapAmount == -1) return true

              return setting.CapAmount > setting.ProcessedAmount;
          },

          $ModuleBase_handleEntityConfigResults(results) {
            this.listSettings = results[0]

            this.logger.logInfo('DollarMatching.$ModuleBase_handleEntityConfigResults -> results', results)
            this.populateListSetting(this.listSettings)
            if (this.predefinedValue && this.listSettings.length > 0)
                this.getDollarMatchingDetails()
          },

           getDollarMatchingDetails() {
            this.multiplierValue = 0;
            var hasActive = false

            if (this.sharedState.isDemo) {
                setTimeout(() => {
                    this.$ModuleBase_handleEntityConfigResults([dollarMatchingListSettings])
                }, 100)
            }

            this.logger.logInfo('DollarMatching.getDollarMatchingDetails -> listSettings', this.listSettings)

            this.listSettings.forEach(settings => {
                if(settings.IsActive && (settings.CapAmount == -1 || (this.predefinedValue + settings.ProcessedAmount) < settings.CapAmount)) {
                this.multiplierValue += settings.AmountMultiplier
                this.isMatchingActive = settings.IsActive
                hasActive = true
                }
                else if(!hasActive)
                this.isMatchingActive = false
            });
            this.multiplierValue ++;

            this.totalMatching = parseFloat(this.predefinedValue) * parseFloat(this.multiplierValue);
        }
    }
};
</script>
<style scoped>
.MatchingBox{
    background: #f8fcfe;
    border: solid #d7e9f6;
    padding-bottom: 20px;
    padding-top: 20px;
    border-radius: 9px;
}
.MultiplierCircule{
    background: #408cd3;
    padding: 6px;
    border-radius: 14px;
    color: white;
}
</style>
