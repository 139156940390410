<template lang="html">
  <div v-if="showInput">
    <input
      id="cardholder-name" 
      v-model="cardholderName" 
      class="hosted-field form-control cardholder-name" 
      placeholder="Cardholder's name" 
      :class="{ invalid: cardholderNameInvalid }"
    >
  </div>
</template>

<script>
import { GfPaymentsEventBus } from '../../../common/GfPaymentsEventBus.js';

export default {
  
  name: 'GfCustomCardholderName',

  props: {
    defaults: {
      type: Object,
      default: function() { 
        return {
          cardholderName: '',
          showInput: false
        }
      }
    }    
  },
  data: function() {
      return {
        cardholderName: '',
        showInput: false,
        cardholderNameInvalid: true,
        bus: GfPaymentsEventBus
      }
  },

  watch: {
    cardholderName: function (val) {
      this._validateCardholderName(val);
    }
  },

  mounted: function() {
    this.showInput = this.defaults.showInput;
    this.cardholderName = this.defaults.cardholderName;
    GfPaymentsEventBus.$on('cardholderToggleValueChanged',this._onCardholderToggleValueChange);
    this._validateCardholderName(this.cardholderName);    
  },

  methods: {
    _onCardholderToggleValueChange: function (val) {      
      this.showInput = !val;
      this._validateCardholderName(this.cardholderName);
    },

    _validateCardholderName: function(name) {
      var currentValidity = !(this.showInput && name === "");
      GfPaymentsEventBus.$emit('cardholderNameValueChanged', currentValidity, 
          (!currentValidity || !this.showInput) ? '' : this.cardholderName);
    }
  }
}
</script>

<style lang="css" scoped>

</style>
