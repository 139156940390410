import _ from 'lodash';
import BeneficiaryFilter from './BeneficiaryFilter'
import DefaultFilter from './DefaultFilter'
import logger from '../../../../common/logger';

export default { 
    
    _getFiltersByType(filterType){
        switch (filterType) {
            case 'beneficiary':
                return BeneficiaryFilter;
            default:
                return DefaultFilter;
        }
    },

    parse: function(filters, store) {
        var result;
        for(let idx in filters) {
            let filter = filters[idx]
            let filterType = _.has(filter, 'type') ? filter.type.toLowerCase() : ''
            
            if(filterType === '') continue;

            logger.logInfo(`parse() - filter.settings`, filter.settings)
            var type = this._getFiltersByType(filterType);
            type.beneficiaryData = store.state.beneficiaryData;
            result = type.apply(filter.settings);
        }
        return result;
    }

};