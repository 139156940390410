<script>
import standardSubmission from "./paymentSubmissions/standardSubmission";
import logger from "./logger";

export default {
    
    data() {
        return {
            paymentSubmissionProcessor: null,
            pollingId: null,
            paymentClientReference: null
        };
    },

    mounted() {
        this.paymentSubmissionProcessor = this.submissionProcessor;
        if (this.submissionProcessor == null)
            this.paymentSubmissionProcessor = standardSubmission;

        logger.logInfo(
            `paymentSubmissionProcessor`,
            this.paymentSubmissionProcessor
        );
    },

    methods: {
        async $_GfPaymentStatusManager_RetrieveStatusUpdates(
            clientReferenceId,
            pollingTimeoutInMs,
            pollingPeriodInMs,
            onPaymentStatusReceived,
            onSuccessPayment,
            onPendingActions,
            onFailure
        ) {
            var maxAttempt = pollingTimeoutInMs / pollingPeriodInMs;
            var readyToRetrieveStatus = true;
            logger.logInfo(
                `pollingPeriodInMs: ${pollingPeriodInMs}, pollingTimeoutInMs: ${pollingTimeoutInMs}, maxAttempt: ${maxAttempt}`
            );
            var i = 1;
            var self = this;
            var pollingId = setInterval(async () => {
                var paymentStatus;
                if (!readyToRetrieveStatus) return;

                readyToRetrieveStatus = false;
                paymentStatus =
                    await this.paymentSubmissionProcessor.retrievePaymentStatus(
                        clientReferenceId,
                        i,
                        maxAttempt
                    );

                logger.logInfo("paymentStatus: ", paymentStatus);
                onPaymentStatusReceived(pollingId, paymentStatus.data);

                i++;
                if (
                    paymentStatus.data.PaymentStatus == "Success" &&
                    paymentStatus.data.Payment !== null &&
                    !self.paymentError
                ) {
                    onSuccessPayment(pollingId, paymentStatus.data);
                } else if (
                    paymentStatus.data.PaymentStatus == "PendingActions" &&
                    !self.paymentError
                ) {
                    onPendingActions(pollingId, paymentStatus.data.StatusData);
                } else if (
                    self.paymentError ||
                    i > maxAttempt ||
                    paymentStatus.data.PaymentStatus == "Failure"
                ) {
                    onFailure(pollingId, paymentStatus.data);
                }

                readyToRetrieveStatus = true;
            }, pollingPeriodInMs);
        },

        async $_GfPaymentStatusManager_UpdatePaymentRequest(
            paymentClientReference,
            requestType,
            additionalData
        ) {
            await this.paymentSubmissionProcessor.updatePaymentRequest(
                paymentClientReference,
                requestType,
                additionalData
            );
        },
    },
};
</script>
