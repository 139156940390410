<template>
  <div v-if="!loadFailure">
    <p
      v-show="isTokenized === false"
      class="section-heading mt-2"
    >
      You must login to PayPal before you can complete this transaction. Click the button below to login.
    </p>
    <div
      v-show="isTokenized === false"
      id="btn-paypal-donate"
      class="col-12 col-md-8 m-auto pb-3"
    />

    <div id="paypalBraintreeWidget">
      <div id="paypal-contextsUser">
        <div
          v-if="user_paypal.email != '' "
          class="paypal-user-rs row mx-auto"
        >
          <p>
            <img
              src="https://checkout.paypal.com/pwpp/2.26.0/images/paypal-small.svg"
              class="paypal-img-logo"
              alt="paypal-logo-paypal"
            >{{ user_paypal.email }}
          </p>
          <p
            class="paypal-cancel"
            :disabled="disabled"
            @click="paypal_cancel_user"
          >
            Cancel
          </p>
        </div>
        <div v-else>
          <div class="animated-placeholder-user" />
        </div>
      </div>
    </div>
    <p
      v-show="isTokenized === true"
      class="small-text font-weight-bold"
    >
      Use the confirmation button below to complete the transaction.
    </p>
  </div>
</template>
<script>
import GfPaymentComponent from '../../../common/GfPaymentComponent.vue';
import GfBraintreePayPalInitializer from './GfBraintreePayPalInitializer.vue'
export default {
  name: 'GfBrainTreePayPal',
  mixins: [GfPaymentComponent, GfBraintreePayPalInitializer],
  data() {
    return {
      paymentMethod: 'PP'
    }
  }
}
</script>
<style lang="css">

.paypal-user-rs img.paypal-img-logo{
  padding-right: 15px;
}
.paypal-user-rs p{
  display: inline-block;
  font-size: 15px;
}
.paypal-user-rs p img{
  display: inline-block;
}
.paypal-user-rs p.paypal-cancel{
  float: right;
  cursor: pointer;
  color: #019cde;
  margin-left: 13em;
}
#gfButtonPayPal{
  border:none;
  padding: 0;
}
@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.braintreePPFlexBox{
  display: flex;
  flex-flow: row;
}
.braintreePPFlexBox p{
  white-space: pre;
  margin-bottom: 0px;
}
.animated-placeholder,.animated-placeholder-user {
  display: none;
  margin: 0 auto;
 background-color: #eee;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eee 10%, #ddd 18%, #eee 10%);
  background-size: 800px 104px;
  height: 16px;
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  width: 100%;
}

input[disabled], div[disabled], p[disabled] {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}
</style>
