/**
 * List of all possible TYPES
 * https://www.npmjs.com/package/libphonenumber-js
 * 
 * This npm package is used by maz-ui package for the MazPhoneNumberInput component
 **/ 
export default {
  ALL: 'ALL',
  MOBILE: 'MOBILE',
  OTHER: 'OTHER',
  FIXED_LINE: 'FIXED_LINE',
  FIXED_LINE_OR_MOBILE: 'FIXED_LINE_OR_MOBILE',
  PREMIUM_RATE: 'PREMIUM_RATE',
  TOLL_FREE: 'TOLL_FREE',
  SHARED_COST: 'SHARED_COST',
  VOIP: 'VOIP',
  PERSONAL_NUMBER: 'PERSONAL_NUMBER',
  PAGER: 'PAGER',
  UAN: 'UAN',
  VOICEMAIL: 'VOICEMAIL',
}
