<template lang="html">
  <div class="step-item">
    <div class="step-icon text-center">
      <slot />
      <div class="step-item-base col">
        <div
          v-if="isActive"
          class="active gf-secondary-bg gf-secondary-text"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            default: false
        },
        number: {
            type: Number,
            default: -1
        }
    }
}
</script>
<style scoped>
.step-item-base .active {
	content: '';
	width: 100%;
	display: block;
	height: 4px;
	background: #f18f22;
	border-radius: 10px;
	margin: 8px auto 0;
	max-width: 68px;
}
</style>
