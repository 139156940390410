<script>
import GfCardTypes from "../../../common/GfCardTypes"

export default {
    name: 'GfBrainTreeInitializer',
    data: function() {
        return {
            hostedFieldInstance: '',
            numberValid: -1,
            cvvValid: -1,
            expirationDateValid:  -1,
            loadFailure: false,
            paymentTypeName: 'braintree-credit-card'
        };
    },
    methods: {
        initialize: function(tokenizationKey, preFillData) {
            this.createBrainTreeClient(tokenizationKey, preFillData);
        },

        createBrainTreeClient: function(clientToken, preFillData, paymentMethodReceivedCallback, paymentErrorCallback) {
            var vm = this;
            const client = require('braintree-web/client');
            var authorization = clientToken;

            client.create({
                authorization: authorization,
                options: vm.getOptions(paymentMethodReceivedCallback, paymentErrorCallback)
            }, function (err, clientInstance) {
                if (err) {
                    vm.$data.loadFailure = true;                    
                    vm.loadFail(err, vm.paymentTypeName);
                    return;
                }
                vm.createBrainTreeHostedFields(clientInstance, preFillData);
            });
            vm.ready();
        },

        getOptions: function(paymentMethodReceivedCallback, paymentErrorCallback) {
            return {
                onPaymentMethodReceived: paymentMethodReceivedCallback,
                onError: paymentErrorCallback
            };
        },

        createBrainTreeHostedFields: function (clientInstance, preFillData) {
            var vm = this;
            var fields = this.initFields(preFillData);
            var styles = this.initStyles();

            const hostedFields = require('braintree-web/hosted-fields');
            hostedFields.create({
                client: clientInstance,
                styles: styles,
                fields: fields
            }, (err, hostedFieldInstance) => {
                if (err) {
                    vm.loadFail(err, vm.paymentTypeName);
                    vm.$data.loadFailure = true;
                    return;
                }

                vm.$data.hostedFieldInstance = hostedFieldInstance;
                vm.$emit('hostedFieldsReady', hostedFieldInstance);

                hostedFieldInstance.on('cardTypeChange', function (data) {
                    vm.onBrainTreeCardTypeChangeEvent(data);
                });
                hostedFieldInstance.on('validityChange', function (data) {
                    vm.onBrainTreeCardValidityChangeEvent(data);
                });

                hostedFieldInstance.on('blur', function (data) {
                    vm.onBrainTreeCardValidityChangeEvent(data);
                });

                vm.onBrainTreeCardTypeChangeEvent(hostedFieldInstance.getState());
                vm.onBrainTreeCardValidityChangeEvent(hostedFieldInstance.getState());
            });
        },

        onBrainTreeCardTypeChangeEvent: function(data) {
            this.clearInputData();
            this.onPaymentTypeChanged(this.getNormalisedCardType(data));
            this.onBrainTreeCardValidityChangeEvent(data);
            this.highlightEnteredCardType(this.getNormalisedCardType(data));
        },

        getNormalisedCardType: function(data) {
            var cardType = GfCardTypes.OTHER;
            if (data.cards && data.cards.length === 1)
                cardType = this.normaliseCard(data.cards[0].niceType)

            return cardType;
        },

        normaliseCard(cardType) {
            switch (cardType.toLowerCase()) {
                case "american express":
                    return GfCardTypes.types.AMEX;
                case "mastercard":
                    return GfCardTypes.types.MASTERCARD;
                case "visa":
                    return GfCardTypes.types.VISA;
                default:
                    return GfCardTypes.OTHER;
            }
        },

        onBrainTreeCardValidityChangeEvent: function(data) {
            var vm = this;
            var isAllDetailsValid = true;

            for (var field in data.fields) {
                if (!data['fields'][field].isValid) {
                    isAllDetailsValid = false;
                    vm.clearInputData();
                }
            }

            vm.highlightInvalidFields(data);

            if (isAllDetailsValid) {
                vm.generateToken();
            }
        },

        highlightInvalidFields: function(data) {
            var vm = this;
            if (vm[data.emittedBy + 'Valid'] === undefined)
                return;

            var isValid = data.fields[data.emittedBy].isValid;
            vm[data.emittedBy + 'Valid'] = isValid;
        },

        initFields: function(preFillData) {
            var fields = {
                    number: {
                        selector: '#card-number',
                        placeholder: 'Credit card number'
                    },
                    expirationDate: {
                        selector: '#card-expiry',
                        placeholder: 'MM/YY'
                    },
                    cvv: {
                        selector: '#card-security',
                        placeholder: 'CVV'
                    }
            };

            if (preFillData && preFillData.cardNumber)
                fields.number['prefill'] = preFillData.cardNumber;
            if (preFillData && preFillData.cardExpiry)
                fields.expirationDate['prefill'] = preFillData.cardExpiry;
            if (preFillData && preFillData.cvv)
                fields.cvv['prefill'] = preFillData.cvv;
            return fields;
        },

        initStyles: function() {
            return {
                    'input': {
                      'color': '#ccc',
                      'font-size': '18px',
                    },
                    'input.invalid': {
                        'border-color': 'red'
                    },
                    ':focus': {
                        'color': 'black'
                    },
                    'input.valid': {
                        'color': 'green'
                    }
                };
        },

        tearDown: function() {
            var vm = this;
            if (vm.$data.hostedFieldInstance === undefined) {
                return;
            }
            if (vm.$data.hostedFieldInstance !== '') {
                vm.$data.hostedFieldInstance.teardown((err) => {
                    if (err) {
                        console.log("teardown err: " + err);
                        return;
                    }
                    vm.$data.hostedFieldInstance = '';
                });
            }
            vm.$emit('teardownSuccess');
        }
    }
}
</script>
