<script>
import GfCardTypes from './GfCardTypes';
import logger from './logger'

export default {
    name: 'GfPaymentHandler',
    mixins: [],
    data: function() {
        return {
            nonce: '',
            lastGeneratedTokenDetails: {
                nonce: '',
                paymentType: '',
                lastDigits: '',
                expiry: '',
                additionalData: {}
            },
        }
    },
    watch: {
        'store.state.currentGateway': {
            deep: true,
            handler: function(val) {
                if(val.type === this.paymentTypeName)
                    this._triggerPaymentMethodData(this.lastGeneratedTokenDetails);
            }
        },
    },
    methods: {
        makePaymentRequest() {

        },

        onPaymentError(err) {
            console.log("got error: " + err);
        },

        generateToken() {
            // override
        },

        validateBeforeSubmit(callback) {
            // override
            logger.logInfo(`validateBeforeSubmit() - default`)
            return true;
        },

        _validateBeforeSubmit(callback) {
            logger.logInfo(`_validateBeforeSubmit() - paymentMethod: ${this.paymentMethod}`)         
            return this.validateBeforeSubmit(callback)    
        },

        _clientActionsResponseReceived(isSuccess, statusMsg, data) {
            if (!isSuccess)
                this.paymentError = true;
            this.$emit("clientActionsResponse", {
                success: isSuccess,
                msg: statusMsg,
                data: data
            })
        },


        _updatePaymentMethodData: function (nonce, paymentType, lastDigits, expiry, additionalData) {
            logger.logInfo(`_updatePaymentMethodData(), Data: `, {nonce, paymentType, lastDigits, expiry, additionalData})
            const paymentDetails = {nonce, paymentType, lastDigits, expiry, additionalData};
            this.lastGeneratedTokenDetails = paymentDetails;
            if(!this.embeddedPaymentMode){
                this._triggerPaymentMethodData(paymentDetails);
                return
            }
            if(this.store.state.currentGateway.type === this.paymentTypeName)
                this._triggerPaymentMethodData(paymentDetails);
        },

        _triggerPaymentMethodData: function ({nonce, paymentType, lastDigits, expiry, additionalData}){
            if(!nonce.length) this.clearInputData();

            if (paymentType == GfCardTypes.OTHER) {
                this.highlightEnteredCardType(GfCardTypes.OTHER);
                return;
            }

            if (!additionalData)
                additionalData = {};

            this.updateInputData(paymentType, lastDigits, nonce, expiry, additionalData);
            var tokenDetails = {
                source: this.$options.name,
                paymentToken: nonce,
                paymentType: paymentType,
                paymentDisplay: lastDigits,
                paymentExpiry: expiry,
                additionalData:additionalData,
                tokenGenerationRequestId: this.tokenGenerationRequestId
            };

            if (this.onTokenizeSuccess && this.onTokenizeSuccess !== '') {
                this.$emit('tokeniseSuccess', tokenDetails);
                window[this.onTokenizeSuccess](tokenDetails);
            }

            this.tokenGenerationRequestId = null;
        }
    }
}
</script>
