import logger from '../../common/logger';
import constants from './EmbeddedPaymentConstants';
import storeDefaults from './EmbeddedPaymentStoreDefaults';
import currency from 'currency.js';
import _ from 'lodash';

export default {
    state: {},

    setContext(beneficiaryId, eventCampaignId, fundraisingPageId) {
        logger.logInfo(`setContext: beneficiaryId: ${beneficiaryId}, eventId: ${eventCampaignId}, pageId: ${fundraisingPageId}`);

        if (beneficiaryId && beneficiaryId.length > 0)
            this.state.beneficiaryId = beneficiaryId;

        if (eventCampaignId && eventCampaignId.length > 0)
            this.state.eventCampaignId = eventCampaignId;

        if (fundraisingPageId && fundraisingPageId.length > 0)
            this.state.fundraisingPageId = fundraisingPageId;
    },

    setDemo(isDemo) {
        this.state.isDemo = isDemo;
    },

    setSupportedGateways(gatewayResponse) {
        logger.logInfo(`setSupportedGateways: `, gatewayResponse);
        this.state.supportedGateways = gatewayResponse.gatewaySettings.gatewayTypes;
        this.state.gatewayLoadStatus = gatewayResponse.status;
    },

    removeFromSupportedGateway(type) {
        if (this.state.supportedGateways) {
            this.state.supportedGateways = this.state.supportedGateways.filter(i => i.type != type)
        }
    },

    setGlobalEventBus(eventBus) {
        logger.logInfo(`setGlobalEventBus: `, eventBus);
        this.state.globalEventBus = eventBus;
    },

    setCurrencyInfo(currencyResponse) {
        logger.logInfo(`setCurrencyInfo: `, currencyResponse);
        this.state.currencyInfo = currencyResponse;
        if (this.state.globalEventBus && this.state.globalEventBus.$emit)
            this.state.globalEventBus.$emit('updateCurrencyInfo', this.state.currencyInfo);
    },

    setClientIpAddress(clientIpAddress) {
        this.state.clientIpAddress = clientIpAddress;
    },

    setNameDisplayMode(mode) {
        this.state.nameDisplayFormat = mode;
    },

    setCompanyName(val) {
        logger.logInfo(`setting company name to: ${val}`);
        this.state.customerDetails.CompanyName = val;
        if (val)
            this.state.customerDetails.DonorType = constants.DonationContactType.company;
    },

    updateAmount(val) {
        var amount = currency(val).value;
        logger.logInfo(`updateAmount: ${amount}`);
        this.state.amount = amount;
        this.calculateTotal();
        this.validate();
    },

    addToAmount(val) {
        logger.logInfo(`addToAmount: ${val}`);
        this.state.addOnamount = currency(val).value;
        this.calculateTotal();
    },

    updateFeeModel(feeModel) {
        switch (feeModel) {
            case "DonationTip":
                this.state.feeModel = constants.FeeModel.donationTip
                break;
            default:
                this.state.feeModel = constants.FeeModel.standard;
        }
        logger.logInfo(`fee model set to: ${this.state.feeModel}`);
    },

    calculateTotal() {
        this.state.totalAmount = (this.state.addOnamount + this.state.amount).toFixed(2);
        logger.logInfo(`totalAmount: ${this.state.totalAmount}`);
    },

    updateName(firstName, lastName) {
        this.state.customerDetails.FirstName = firstName;
        this.state.customerDetails.LastName = lastName;
        if (this.state.globalEventBus && this.state.globalEventBus.$emit)
            this.state.globalEventBus.$emit('updateCustomerDetails', this.state.customerDetails);
    },

    updateEmail(email) {
        this.state.customerDetails.EmailAddress = email;
        if (this.state.globalEventBus && this.state.globalEventBus.$emit)
            this.state.globalEventBus.$emit('updateCustomerDetails', this.state.customerDetails);
    },

    updateReceiveNewsletter(val){
        this.state.customerDetails.ReceiveNewsletter = val;
    },

    updateReceiveGFNewsletter(val){
        this.state.customerDetails.ReceiveGFNewsletter = val;
    },

    updateMobile(mobile) {
        this.state.customerDetails.MobileNumber = mobile;
    },

    updateContactNumber(contactNumber) {
        this.state.customerDetails.ContactNumber = contactNumber
    },

    updateAddress(address) {
        logger.logInfo(`setting address store state:`, address);

        this.state.customerDetails.Address = address.address;
        this.state.customerDetails.AddressLine1 = address.addressLine1;
        this.state.customerDetails.AddressLine2 = address.addressLine2;
        this.state.customerDetails.Suburb = address.suburb;
        this.state.customerDetails.Postcode = address.postcode;
        this.state.customerDetails.State = address.state;
        this.state.customerDetails.Country = address.country;
    },

    updateAddressLine1(address) {
        this.state.customerDetails.AddressLine1 = address;
    },

    updateSuburb(suburb) {
        this.state.customerDetails.Suburb = suburb;
    },

    updatePostcode(postcode) {
        this.state.customerDetails.Postcode = postcode;
    },

    updateAddressState(state) {
        this.state.customerDetails.State = state;
    },

    updateCountry(country) {
        this.state.customerDetails.Country = country;
    },

    updateMessage(message) {
        if (message && message.length > 0)
            this.state.message = message;
    },

    updateStatus(status, token) {
        this.state.captchaToken = token;
        this.state.readyForSubmission = status;
    },

    async executeBeforePayment() {
        var currentModules = this.state.pageModules;
        logger.logInfo(`executeBeforePayment, currentModules:`, currentModules);

        this.state.readyForSubmission = false;
        var readyForSubmission = true;
        for (var i = 0; i < currentModules.length; i++) {
            var isValid = await currentModules[i].module.$ModuleBase_executeBeforePayment()
            logger.logInfo(`validating module "${currentModules[i].module.$options._componentTag}"`, isValid)
            if (!isValid) {
                readyForSubmission = false
                break
            }
        }

        this.state.readyForSubmission = readyForSubmission;
        logger.logInfo(`validate, currentModules: ${this.state.readyForSubmission}`);
    },

    moveToNextPage() {
        this.state.currentPageIndex += 1;
        this.validate();
    },
    moveToPage(index) {
        this.state.currentPageIndex = index;
        this.validate();
    },
    updateCustomQuestionValue(val) {
        logger.logInfo(`updateCustomQuestionValue:`, val);
        var index = this.state.metaData.findIndex(x => x.name === val.name);
        if (index === -1)
            this.state.metaData.push(val);
        else {
            this.state.metaData[index] = val;
        }
    },

    mountedComponent(component, pageIndex) {
        logger.logInfo(`mounted component on page ${pageIndex}`, component);
        this.state.pageModules.push({
            pageIndex: pageIndex,
            module: component
        });
        this.validate();
    },

    setCoveringCost(val) {
        logger.logInfo(`set covering cost ${val}`);
        this.state.coveringCost = val;
    },

    setCsrfToken(token) {
        logger.logInfo(`csrfToken: ${token}`);
        this.state.csrfToken = token;
    },

    setTokenDetailsForTransaction(token, paymentType, credentialHolder) {
        this.state.tokenDetailsForTransaction.Token = token;
        this.state.tokenDetailsForTransaction.Type = paymentType;
        this.state.tokenDetailsForTransaction.CredentialHolder = credentialHolder;
    },

    resetTokenDetailsForTransaction() {
        this.state.tokenDetailsForTransaction.Token = '';
        this.state.tokenDetailsForTransaction.Type = null;
        this.state.tokenDetailsForTransaction.CredentialHolder = null;
    },

    async validate() {
        var currentPageModules = this.state.pageModules.filter(x => x.pageIndex === this.state.currentPageIndex);
        logger.logInfo(`validate, currentPageModules:`, currentPageModules);

        for (var i = 0; i < currentPageModules.length; i++) {
            if (!(await currentPageModules[i].module.$ModuleBase_validate())) {
                this.state.canProceed = false;
                return;
            }
        }

        this.state.canProceed = !(currentPageModules.find(m => !m.module.isValid));
        logger.logInfo(`validate, currentPageIsValid: ${this.state.canProceed}`);
    },

    getPayload() {
        if (!this.state.tokenDetailsForTransaction.CredentialHolder || this.state.tokenDetailsForTransaction.CredentialHolder.length == 0) {
            this.state.tokenDetailsForTransaction.CredentialHolder = `${this.state.customerDetails.FirstName} ${this.state.customerDetails.LastName}`;
            this.state.tokenDetailsForTransaction.CredentialHolder = this.state.tokenDetailsForTransaction.CredentialHolder.trim();
        }

        var payload = {
            DonationType: this.state.donationType,
            Message: this.state.message,
            RegularGivingSettings: {
                Enable: this.state.recurringDonation,
                Frequency: this.state.recurringDonationFrequency,
                NextPayment: this.state.nextPaymentDate,
                Amount: this.state.amount
            },
            DonationItems: [],

            TokenDetailsForTransaction: this.state.tokenDetailsForTransaction,

            CustomerDetails: this.state.customerDetails,

            TuringTest: this.state.captchaToken,
            TuringTestResult: {},

            WaitForCompletion: this.state.waitForCompletion,

            MetaData: this.state.metaData
        };

        if (this.state.feeModel == constants.FeeModel.donationTip)
            this.addDonationTipItem(payload);

        this.addAdditionalItemsToPayload(payload);
        logger.logInfo(`getPayload():`, payload);

        return payload;
    },

    addAdditionalItem(item) {
        logger.logInfo(`addAdditionalItem`, item);
        this.state.additionalItems.push(item);
    },

    addAdditionalItemsToPayload(payload) {
        this.state.additionalItems.forEach((val) => {
            payload.DonationItems.push({
                AcceptCost: this.state.coveringCost,
                IsAnonymous: val.isAnonymous,
                TaxTypeId: val.taxTypeId,
                ProductType: val.productType,
                Description: val.description,
                UnitPrice: val.unitPrice,
                Quantity: val.quantity,
                BeneficiaryId: val.beneficiary,
                EventCampaignId: parseInt(this.state.eventCampaignId) === NaN ? null : parseInt(this.state.eventCampaignId),
                FundraisingPageId: val.fundraisingPageId,
                MainLineItem: val.mainLineItem,
            });
        });
    },

    addDonationTipItem(payload) {
        payload.DonationItems.push({
            AcceptCost: this.state.coveringCost,
            IsAnonymous: true,
            TaxTypeId: this.state.donorTipBeneficiaryTaxTypeId,
            ProductType: 4,
            Description: 'Helping cover platform costs',
            UnitPrice: this.state.addOnamount,
            Quantity: 1,
            BeneficiaryId: this.state.donorTipBeneficiary,
            EventCampaignId: parseInt(this.state.eventCampaignId) === NaN ? null : parseInt(this.state.eventCampaignId),
            FundraisingPageId: null,
            MainLineItem: false
        });
    },

    setCdnBaseUrl(val) {
        this.state.cdnBaseUrl = val;
    },

    setRegion(val) {
        this.state.regionId = val;
    },

    setPaymentResponse(val) {
        logger.logInfo(`setPaymentResponse:`, val);
        this.state.paymentResponse = val;
    },

    overrideProceedToNext(val) {
        logger.logInfo(`overrideProceedToNext: ${val}`);
        this.state.canProceed = val;
    },

    setWaitForCompletion(val) {
        this.state.waitForCompletion = val;
    },

    setPollingPeriodInMs(val) {
        this.state.pollingPeriodInMs = val;
    },

    setPollingTimeoutInMs(val) {
        this.state.pollingTimeoutInMs = val;
    },

    fetchEntityConfig(type, subType) {
        logger.logInfo(`fetchEntityConfig(), type: ${type}, subType: ${subType}`);
        this.state.entityConfigsToLoad.push({ type: type, subType: subType });
    },

    setEntityConfigResults(results) {
        logger.logInfo(`setEntityConfigResults()`, results);
        this.state.entityConfigResults = results;
        this.state.entityConfigsToLoad = [];
    },

    setDonorTipBeneficiary(beneficiary, taxTypeId) {
        logger.logInfo(` setDonorTipBeneficiary: ${beneficiary} taxTypeId: ${taxTypeId} `);
        this.state.donorTipBeneficiary = beneficiary;
        if (taxTypeId && taxTypeId > 0)
            this.state.donorTipBeneficiaryTaxTypeId = taxTypeId;
    },

    setRecurringDonation(val, frequency, nextPaymentDate) {
        logger.logInfo(`Recurring Donation: ${val}, frequency: ${frequency}, nextPaymentDate: ${nextPaymentDate}`)
        this.state.recurringDonation = val;
        this.state.recurringDonationFrequency = frequency;
        this.state.nextPaymentDate = nextPaymentDate;
    },

    setProcessingPayment(val) {
        logger.logInfo(`setProcessingPayment: ${val}`)
        this.state.isProcessingPayment = val;
    },

    updateClientActionsResponse(val) {
        logger.logInfo(`updateClientActionsResponse: ${val}`)
        this.state.clientActionsResponse = val;
    },

    setPaymentPendingActions(val, data) {
        logger.logInfo(`setPaymentPendingActions: ${val}`)
        logger.logInfo(`setPaymentPendingActions data: ${data}`)
        if (!this.state.isProcessingPayment || !this.state.pendingPaymentActionsData) {
            this.state.isPendingPaymentActions = val;
            this.state.pendingPaymentActionsData = data;
        }
    },

    clearPaymentPendingActions() {
        logger.logInfo(`clearPaymentPendingActions`);
        this.state.isPendingPaymentActions = false;
        this.state.pendingPaymentActionsData = null;
    },

    initializeStore() {
        this.state = _.cloneDeep(storeDefaults);
    },

    updatePrefillData(val) {
        this.state.prefillData = val
    },

    refreshAlternateButton(hasPaymentModule) {
        var showButton = false
        if (hasPaymentModule) {
            var setAlternateDonateButton = _.get(this.state.currentGateway, 'additionalSettings.setAlternateDonateButton', false)
            if (setAlternateDonateButton == true) {
                showButton = true
            }
        }
        
        this.setDisplayAlternateButton(showButton)      
    },

    setCurrentGateway(val) {
        this.state.currentGateway = val
    },

    setDefaultPaymentGateway(val) {
        this.state.defaultPaymentGateway = val
    },

    setCurrentGatewayTrigger() {
        this.state.currentGatewayTrigger = this.state.currentGatewayTrigger + 1
    },

    setDonationType(val) {
        this.state.donationType = val
    },

    setForceContactDetailsAsAnonymous(val) {
        this.state.forceContactDetailsAsAnonymous = val
    },

    setPaymentClientReference(val) {
        this.state.paymentClientReference = val
    },

    setDisplayAlternateButton(val) {
        this.state.showAlternateButton = val
    },

    setBeneficiaryData(val) {
        this.state.beneficiaryData = val
    },

    triggerTipAmountCalculation() {
        this.state.calculateTipAmountTrigger += 1
    },

    triggerGenerateToken() {
        this.state.regenerateTokenTrigger += 1
    },
}
