export default {
    SuccessResponse: {
        Status: {
            Success: true,
            Message: ''
        },
        Page: {
            PageId: 885189,
            PageImagePath: 'https://cdn.au.awstest.gfrnetworks.com/Upload/176182/LOGO131317212622691371.png',
            PageTitle: 'Admin ACRF',
            PageCreatorName: 'Admin ACRF',
            PageUrl: 'https://inmemory.au.awstest.gfrnetworks.com/page/Admin-56469857',
            PageDonationUrl: 'https://inmemory.au.awstest.gfrnetworks.com/payments/donate/page/885189',
            PageRaiseTarget: 700.00,
            PageTotal: 100.00
        },
        Payment: {
            Referrer: 'http://makingadifference.au.awstest.gfrnetworks.com/payments/donate/beneficiary/2174',
            PaymentTitle: '3MBS (Music Broadcasting Society of Victoria Limited)',
            Reference: '2678914',
            Amount: 34.00,
            PaymentId: 2972195,
            PaymentUrl: 'http://www.au.awstest.gfrnetworks.com/payments/donate/beneficiary/2174'
        },
        PaymentItems: [{
            PaymentItemId: 3555891,
            Description: 'Tax Deductible Donation',
            ProductId: 1,
            Amount: 34.00,
            UnitPrice: 34.00,
            Quantity: 1,
            LastModified: '2021-01-08 17:33:24.8535247 +11:00'
        }],
        Beneficiary: {
            BeneficiaryAccountId: 2174,
            BeneficiaryImagePath: 'https://cdn.au.awstest.gfrnetworks.com/Upload/beneficiary/2174/logo.png?rev=2',
            BeneficiaryName: '3MBS (Music Broadcasting Society of Victoria Limited)',
            BeneficiaryUrl: 'https://www.au.awstest.gfrnetworks.com/beneficiary/3MBS'
        },
        Event: {
            EventCampaignId: 4,
            EventImagePath: 'https://cdn.au.awstest.gfrnetworks.com/Upload/4/EventBanner131532093178373873.jpg',
            EventName: 'In Memory',
            EventUrl: 'inmemory.au.awstest.gfrnetworks.com'
        },
        GateWaySettings: {
            gatewaySettings: {
                clientIpAddress: '124.83.107.195, 3.26.138.77',
                currency: {
                    symbol: '$',
                    name: 'United States dollar',
                    code: 'USD',
                    isoLanguageCode: 'en-US'
                },
                fees: [{
                    name: 'MX',
                    transactionFee: '0.2',
                    transactionRate: '0.0198'
                }, {
                    name: 'Visa',
                    transactionFee: '0.2',
                    transactionRate: '0.011'
                }, {
                    name: 'Master',
                    transactionFee: '0.2',
                    transactionRate: '0.011'
                }],
                gatewayTypes: [{
                    additionalSettings: {
                        displayNameSelector: true,
                        displayNameSelectorLabel: 'I am the Card Holder'
                    },
                    paymentMethod: 'CC',
                    publicToken: 'sandbox_p7f4kscm_h472r2723rfch9w7',
                    type: 'braintree-credit-card',
                    typeId: '70'
                }, {
                    additionalSettings: {
                        buttonPayPal: 'gfPresetPayPalBtn',
                        styleButtonPaypal: {
                            label: 'pay',
                            size: 'responsive',
                            shape: 'rect',
                            color: 'blue',
                            tagline: false
                        },
                        paymentTypeBraintreePaypal: 'sandbox',
                        displayNamePaypal: 'GfPayment'
                    },
                    paymentMethod: 'PP',
                    publicToken: 'sandbox_p7f4kscm_h472r2723rfch9w7',
                    type: 'braintree-paypal',
                    typeId: '71'
                }, {
                    additionalSettings: {},
                    paymentMethod: 'CC',
                    publicToken: 'pk_test_ErBdPs3AyuRpcoaaK6koJIXd',
                    type: 'stripe-credit-card',
                    typeId: '72'
                }]
            },
            status: {
                msg: '',
                success: true
            }
        }
    },
    SuccessNoWaitForCompletionResponse: {
        Status: {
            Success: true,
            Message: 'Your payment is being processed, please wait'
        },
        ReferenceId: '839fcd98-11f6-4511-8ee1-cb8aa82e1b6e'
    },
    SuccessLocationApiResponse: {
      Settings: [{
        Settings: {
          Id: 117,
          Type: "Credentials",
          SubType: "Location",
          ContextKey: "REG",
          ContextValue: 1,
          Settings: '[{"type":"Google","settings":{"key":"AIzaSyDvgf2lSsDE9ywXXA0HKkX-US9Xd25cu-w"}}]',
          LastModifiedBy: null,
          LastUpdated: null,
          LastModifiedByUser: null,
          IsDeleted: false
        },
        ConfigurationName: "Credentials",
        ConfigurationSubType: "Location"
      },
      {
        Settings: {
          Values: [{
            Value: "100",
            Text: "could help students like Brianna purchase textbooks and course materials",
            ImageUrl: null,
            IsSelected: false
          }, {
            Value: "125",
            Text: "could help students facing hardship buy essentials such as groceries and medicine",
            ImageUrl: null,
            IsSelected: false
          }, {
            Value: "150",
            Text: "could help ease living expenses so that disadvantaged students can focus on their studies",
            ImageUrl: null,
            IsSelected: false
          }, {
            Value: "225",
            Text: "will provide crucial support for students facing hardship",
            ImageUrl: null,
            IsSelected: false
          }]
        },
        ConfigurationName: "Donation",
        ConfigurationSubType: "DollarHandles"
      }, {
        Settings: null,
        ConfigurationName: "Payment",
        ConfigurationSubType: "Matching"
      }, {
        Settings: {
          Id: 0,
          Type: null,
          SubType: null,
          ContextKey: null,
          ContextValue: 0,
          Settings: null,
          LastModifiedBy: null,
          LastUpdated: null,
          IsDeleted: false
        },
        ConfigurationName: "Security",
        ConfigurationSubType: "Turing"
      }]
    },
    RetrievePaymentStatusReceivedResponse: {
        "title": "Success",
        "text": "Successful in getting payment status",
        "type": "success",
        "success": true,
        "data": {
            "Status": null,
            "PageModel": null,
            "Payment": null,
            "PaymentItems": null,
            "Beneficiary": null,
            "Event": null,
            "CsrfToken": null,
            "PaymentReferenceId": "62e6fc0c-c3d0-400a-becf-680ffc2810a0",
            "PaymentStatus": "Received"
        },
        "error": null
    },
    RetrievePaymentStatusSuccessResponse: {
        "title": "Success",
        "text": "Successful in getting payment status",
        "type": "success",
        "success": true,
        "data": {
            "Status": null,
            "PageModel": {
                "PageId": 0,
                "PageImagePath": null,
                "PageTitle": null,
                "PageCreatorName": null,
                "PageUrl": null,
                "PageDonationUrl": null,
                "PageRaiseTarget": 0,
                "PageTotal": 0
            },
            "Payment": {
                "Referrer": null,
                "PaymentTitle": "X Demo 01",
                "Reference": "361",
                "Amount": 54.5,
                "PaymentId": 40399,
                "PaymentUrl": "https://birthday.au.gfrnetworks.local/payments/donate/embedded"
            },
            "PaymentItems": [{
                "PaymentItemId": 40429,
                "Description": "Tax Deductible Donation",
                "ProductId": 1,
                "Amount": 50,
                "UnitPrice": 50,
                "Quantity": 1,
                "LastModified": "29/09/2021 3:48:59 AM",
                "DisplayName": "Eka GF",
                "Message": null
            }, {
                "PaymentItemId": 40430,
                "Description": "Helping cover platform costs",
                "ProductId": 4,
                "Amount": 4.5,
                "UnitPrice": 4.5,
                "Quantity": 1,
                "LastModified": "29/09/2021 3:48:59 AM",
                "DisplayName": "Eka GF",
                "Message": null
            }],
            "Beneficiary": {
                "BeneficiaryAccountId": 1,
                "BeneficiaryImagePath": "https://cdn.gfrnetworks.local/Upload/beneficiary/104/logo.png",
                "BeneficiaryName": "X Demo 01",
                "BeneficiaryUrl": "http://www.au.gfrnetworks.local/beneficiary/Demo"
            },
            "Event": {
                "EventCampaignId": 1,
                "EventImagePath": "https://cdn.gfrnetworks.local",
                "EventName": "Not Applicable - Direct Donation",
                "EventUrl": "www.au.gfrnetworks.local"
            },
            "CsrfToken": null,
            "PaymentReferenceId": "62e6fc0c-c3d0-400a-becf-680ffc2810a0",
            "PaymentStatus": "Success" // change this to "Failure" to simulate failed payment
        },
        "error": null
    },
    SuccessGivingDayResponse: {
        "Settings": [{
            "Settings": [{
                "RuleId": 1,
                "Status": "A",
                "ProcessorSettings": [{
                    "PluginName": "NewPayment",
                    "PluginType": "Trigger",
                    "Settings": null,
                    "ProcessorId": 1
                }, {
                    "PluginName": "MatchPayment",
                    "PluginType": "Validator",
                    "Settings": "{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"PaymentItems\",\"ValidationParameter\":{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"EventCampaignId\",\"Value\":8449,\"Operator\":\"Equal\"},{\"ConditionField\":\"DatePaid\",\"Value\":\"2022-01-11 00:00:00.0000000 +11:00\",\"Operator\":\"GreaterThanOrEqual\"},{\"ConditionField\":\"DatePaid\",\"Value\":\"2022-07-30 00:00:00.0000000 +11:00\",\"Operator\":\"LessThanOrEqual\"}]}}],\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":2,\"ExceedStateAction\":\"NoMatch\"}},\"PaymentCreateRequest\":{\"BillingDetails\":{\"FirstName\":\"Admin\",\"LastName\":\"UON\",\"Email\":\"uon.admin@uon.com\",\"Organisation\":\"UON\"},\"Payment\":{\"PaymentType\":\"CASH\",\"PaymentSubType\":\"OFFLINE\",\"PaymentStatus\":\"A\",\"PaymentNotes\":\"This is matched payment\",\"PaymentToken\":\"\",\"AdditionalData\":\"\",\"PaymentItems\":[{\"PaymentItemNotes\":\"test notes\"}]}},\"StateConditionsOperator\":\"All\",\"StateConditions\":[{\"ConditionField\":\"ProcessedAmount\",\"Value\":10000,\"Operator\":\"LessThanOrEqual\"}]}",
                    "ProcessorId": 2
                }, {
                    "PluginName": "OfflinePaymentCreator",
                    "PluginType": "Executor",
                    "Settings": "{\n    \"MatchingGift\": {\n        \"Type\": \"MatchedPayment\",\n        \"Settings\": {\n            \"AmountMultiplier\": 2,\n            \"ExceedStateAction\": \"NoMatch\"\n        }\n    },\n    \"PaymentCreateRequest\": {\n        \"BillingDetails\": {\n            \"FirstName\": \"Admin\",\n            \"LastName\": \"UON\",\n            \"Email\": \"uon.admin@uon.com\",\n            \"Organisation\": \"UON\"\n        },\n        \"Payment\": {\n            \"PaymentType\": \"CASH\",\n            \"PaymentSubType\": \"OFFLINE\",\n            \"PaymentStatus\": \"A\",\n            \"PaymentNotes\": \"This is matched payment\",\n            \"PaymentToken\": \"\",\n            \"AdditionalData\": \"\",\n            \"PaymentItems\": [{\n                \"PaymentItemNotes\": \"test notes\"\n            }]\n        }\n    }\n}",
                    "ProcessorId": 3
                }],
                "State": [{
                    "Name": "Match Payment State",
                    "Description": null,
                    "Status": "A",
                    "RuleStateSettings": "{\"ProcessedAmount\":5200}"
                }]
            }],
            "ConfigurationName": "Payment",
            "ConfigurationSubType": "Matching"
        }, {
            "Settings": [{
                "RuleId": 2,
                "Status": "I",
                "ProcessorSettings": [{
                    "PluginName": "NewPayment",
                    "PluginType": "Trigger",
                    "Settings": null,
                    "ProcessorId": 1
                }, {
                    "PluginName": "MatchPayment",
                    "PluginType": "Validator",
                    "Settings": "{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"PaymentItems\",\"ValidationParameter\":{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"EventCampaignId\",\"Value\":8449,\"Operator\":\"Equal\"},{\"ConditionField\":\"DatePaid\",\"Value\":\"2022-06-12 00:00:00.0000000 +11:00\",\"Operator\":\"GreaterThanOrEqual\"},{\"ConditionField\":\"DatePaid\",\"Value\":\"2022-08-30 00:00:00.0000000 +11:00\",\"Operator\":\"LessThanOrEqual\"}]}}],\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":3,\"ExceedStateAction\":\"NoMatch\"}},\"PaymentCreateRequest\":{\"BillingDetails\":{\"FirstName\":\"Admin\",\"LastName\":\"UON\",\"Email\":\"uon.admin@uon.com\",\"Organisation\":\"UON\"},\"Payment\":{\"PaymentType\":\"CASH\",\"PaymentSubType\":\"OFFLINE\",\"PaymentStatus\":\"A\",\"PaymentNotes\":\"This is matched payment\",\"PaymentToken\":\"\",\"AdditionalData\":\"\",\"PaymentItems\":[{\"PaymentItemNotes\":\"test notes\"}]}},\"StateConditionsOperator\":\"All\",\"StateConditions\":[{\"ConditionField\":\"ProcessedAmount\",\"Value\":20000,\"Operator\":\"LessThanOrEqual\"}]}",
                    "ProcessorId": 2
                }, {
                    "PluginName": "OfflinePaymentCreator",
                    "PluginType": "Executor",
                    "Settings": "{\n    \"MatchingGift\": {\n        \"Type\": \"MatchedPayment\",\n        \"Settings\": {\n            \"AmountMultiplier\": 2,\n            \"ExceedStateAction\": \"NoMatch\"\n        }\n    },\n    \"PaymentCreateRequest\": {\n        \"BillingDetails\": {\n            \"FirstName\": \"Dinh\",\n            \"LastName\": \"Dang\",\n            \"Email\": \"dang@uon.com\",\n            \"Organisation\": \"GoFundraise Ltd\"\n        },\n        \"Payment\": {\n            \"PaymentType\": \"CASH\",\n            \"PaymentSubType\": \"OFFLINE\",\n            \"PaymentStatus\": \"A\",\n            \"PaymentNotes\": \"This is matched payment\",\n            \"PaymentToken\": \"\",\n            \"AdditionalData\": \"\",\n            \"PaymentItems\": [{\n                \"PaymentItemNotes\": \"test notes\"\n            }]\n        }\n    }\n}",
                    "ProcessorId": 3
                }],
                "State": [{
                    "Name": "Match Payment State",
                    "Description": null,
                    "Status": "A",
                    "RuleStateSettings": "{\"ProcessedAmount\":1200}"
                }]
            }],
            "ConfigurationName": "Payment",
            "ConfigurationSubType": "Matching"
        }]
    },
    SuccessCaptchaApiResponse: {
        Settings: [{
            Settings: {
                Type: "InvisibleCaptcha",
                Settings: {
                    PublicKey: "6Ld0RGYUAAAAADG8ueuXCvNbEzKPsvORVF9L68Em",
                    VerificationUrl: "https://www.google.com/recaptcha/api/siteverify"
                }
            },
            ConfigurationName: "Security",
            ConfigurationSubType: "Turing"
        }]

    },
    SuccessIntermediaryResponse: {
        fields: [{
            name: 'contactDetails',
            settings: "{\"firstName\": \"Predefined First\", \"lastName\": \"PreDefined Last\", \"email\": \"email@gofundraise.com\"}"
        }, {
            name: 'address',
            settings: "{\"addressLine1\": \"streetname\", \"suburb\": \"PreDefined city\", \"state\": \"Predefined State\", \"country\": \"United States\", \"postcode\": \"123\"}"
        }],
        contexts: [{
            type: "DonationLineId",
            value: 123
        }, {
            type: "BeneficiaryId",
            value: 104
        }]
    }
}