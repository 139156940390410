import _ from "lodash";

export default {
    objectKeysToCamelCase(snake_case_object) {
        var self = this;
        var camelCaseObject = {};
        var camelCaseArray = [];
        _.forEach(snake_case_object, function(value, key) {
            if (_.isPlainObject(value) || _.isArray(value)) {
                value = self.objectKeysToCamelCase(value);
            }
            if (_.isArray(snake_case_object)) camelCaseArray.push(value);
            else camelCaseObject[_.camelCase(key)] = value;
        });
        if (_.isArray(snake_case_object)) return camelCaseArray;
        else return camelCaseObject;
    }
};
