import _ from 'lodash';
import logger from '../../../../common/logger';

export default {    
    beneficiaryData: null,

    apply: function(settings) {              
        if (settings && settings.beneficiaryParentIds.length > 0) {

          if (_.has(settings, 'matchType')) {
            switch (settings.matchType.toLowerCase()) {
              case 'in-list':
              case 'inlist':
              case 'contains':
                return settings.beneficiaryParentIds.some(id => {  
                  logger.logInfo(`$ModuleBase_Filter.settings -> inList -> parentId`, id)                
                      return this.beneficiaryData !== null 
                          && _.has(this.beneficiaryData, 'BeneficiaryParents') 
                          && this.beneficiaryData.BeneficiaryParents.length > 0
                          && this.beneficiaryData.BeneficiaryParents.some(parent => parent.Id == id)
                });
              case 'not-in-list':
              case 'notinlist':
              case 'doesnotcontain':
                let parentIds = (this.beneficiaryData !== null 
                  && _.has(this.beneficiaryData, 'BeneficiaryParents') 
                  && this.beneficiaryData.BeneficiaryParents.length > 0) 
                  ? this.beneficiaryData.BeneficiaryParents.map(i => i.Id)
                  : []

                logger.logInfo(`$ModuleBase_Filter.settings -> notInList -> store.parentIds`, parentIds)
                logger.logInfo(`$ModuleBase_Filter.settings -> notInList -> filter.settings.beneficiaryParentIds`, settings.beneficiaryParentIds)

                return _.intersection(parentIds, settings.beneficiaryParentIds).length == 0
            }
          } else {
            return settings.beneficiaryParentIds.some(id => {  
              logger.logInfo(`$ModuleBase_Filter.settings -> parentId`, id)                
                  return this.beneficiaryData !== null 
                      && _.has(this.beneficiaryData, 'BeneficiaryParents') 
                      && this.beneficiaryData.BeneficiaryParents.length > 0
                      && this.beneficiaryData.BeneficiaryParents.some(parent => parent.Id == id)
            });
          }
            
        }
    }
};