export default {
    TaxDeductibleDonation: 1,
    NonTaxDeductibleDonation: 2,
    DepositCollectedFunds: 3,
    DonationTip: 4,
    Registration: 5,
    Tickets: 6,
    Other: 7,
    Shipping: 8,
    Adjustment: 9,
    WorkPlaceGiving: 10,
    TransactionFee: 11
};
