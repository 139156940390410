<template>
  <div class="w-100 mb-3">
    <label>{{ label }} <span
      v-if="isRequired"
      class="text-danger"
    >*</span></label>
    <MazPhoneNumberInput
      :id="MazInputPhoneNumberId"
      v-model="phoneNumber"
      show-code-on-list
      :default-country-code="defaultCountryCode"
      :default-phone-number="defaultPhoneNumber"
      :preferred-countries="preferredCountries"
      :placeholder="placeholder"
      @update="onUpdate"
      @blur="onBlur"
      @focus="onFocus"
      @keyup="onUpdate"
    />
  </div>  
</template>

<script>
import Vue from 'vue'
import _ from 'lodash';
import ModuleBase from "../ModuleBase.vue";
import contactNumberType from '../../../../common/contactNumberTypes'
import { MazPhoneNumberInput } from 'maz-ui';

Vue.use(MazPhoneNumberInput)

export default {
  name: 'ContactNumbers',
	components: {
    MazPhoneNumberInput,
  },
	mixins: [ModuleBase],
  data() {
    return {
      phoneNumber: '',
      label: 'Contact Number',
      defaultPhoneNumber: '',
      defaultCountryCode: '',
      preferredCountries: ['AU', 'US', 'GB', 'NZ', 'CA'],
      isRequired: true,
      contactType: contactNumberType.ALL,
      placeholder: 'Contact Number',
      isPhoneNumberValid: false,
      onUpdateTrigger: 0,
      MazInputPhoneNumberId: `MazInputPhoneNumber-${Math.floor(Math.random() * 1000).toString()}`
    }
  },
  watch: {
    isRequired(val) {
      this.isPhoneNumberValid = val == false ? true : false
    },
    defaultPhoneNumber(val) {
      this.phoneNumber = val
    },
    placeholder(val) {
      if (val == '')
        this.placeholder = ' '
    },
    onUpdateTrigger() {
      this.$nextTick(() => {        
        let inputPhoneNumber = this.$el.querySelector(`#${this.MazInputPhoneNumberId} .input-phone-number`)
        inputPhoneNumber.classList.remove('is-invalid')
        this.logger.logInfo('onUpdateTrigger.this.validation.hasError(isPhoneNumberValid): ' + this.validation.hasError('isPhoneNumberValid'))
        this.logger.logInfo('onUpdateTrigger.this.validation.isTouched(isPhoneNumberValid): ' + this.validation.isTouched('isPhoneNumberValid'))

        if (this.validation.hasError('isPhoneNumberValid') && this.validation.isTouched('isPhoneNumberValid')) {
          inputPhoneNumber.classList.remove('is-valid')
          inputPhoneNumber.classList.add('is-invalid')
          inputPhoneNumber.classList.remove('is-focused')
        }
      })
    },
  },
  async mounted() {
    if (!_.isEmpty(this.settings)) {
      await new Promise(async resolve => {
        await this.setDefault('isRequired')
        await this.setDefault('defaultCountryCode')
        await this.setDefault('preferredCountries')
        await this.setDefault('defaultPhoneNumber')
        await this.setDefault('label')
        await this.setDefault('contactType')
        await this.setDefault('placeholder')
        resolve()
      })
    }
  },
  validators: {
    isPhoneNumberValid(value) {
      return this.validator.value(value).match(true)
    }
  },
  methods: {
    onFocus() {
      this.validation.setTouched('isPhoneNumberValid')
      this.onUpdateTrigger += 1
    },
    onBlur() {
      this.onUpdateTrigger += 1
    },
    onUpdate(val) {
      this.logger.logInfo('onUpdate', val)
      this.store.updateMobile('')
      this.store.updateContactNumber('')

      this.isPhoneNumberValid = false

      if (_.has(val, 'type') && _.has(val, 'isValid') && val.isValid == true) {
        this.isPhoneNumberValid = this.isValidType(val.type)
      }

      if (this.isRequired == false || (this.isRequired && val.isValid))
        this.isPhoneNumberValid = true

      if ( _.has(val, 'isValid') && val.isValid == true && _.has(val, 'formatInternational')) {
        switch (val.type) {
          case contactNumberType.MOBILE:
            this.store.updateMobile(val.formatInternational)
            break
          default:
            this.store.updateContactNumber(val.formatInternational)
            break
        }
      }

      this.$nextTick(async () => {
        await this.$ModuleBase_validate()
        this.onUpdateTrigger += 1
      })
    },
    async setDefault(keyName) {
      if (_.has(this.settings, keyName)) {
        this[keyName] = this.settings[keyName]
        await this.$nextTick()
      }
    },
    isValidType(type) {
      let isValid = false

      switch (this.contactType) {
        case contactNumberType.ALL:
            isValid = true
          break
        case contactNumberType.MOBILE:
            if (type == contactNumberType.MOBILE) {
              isValid = true
            }
          break
        case contactNumberType.OTHER:
            if (type != contactNumberType.MOBILE) {
              isValid = true
            }
          break
      }

      return isValid
    }
  }
}
</script>

<style>
.maz-base-component {
  --maz-border-width: 1px;
  --maz-border-color: rgba(34, 36, 38, 0.2);
  --maz-border-radius: 5px;
  --maz-primary: rgba(34, 36, 38, 0.4);
  --maz-placeholder-color: #777;
}

.maz-phone-number-input__country-flag {
  bottom: 1.35rem !important;
}

.maz-input.has-value:not(.has-no-label) .maz-input__input {
  padding-top: 0 !important;
}

.maz-input {
  padding: 13px;
  height: unset !important;
  line-height: 1.6rem;
}

.maz-input input {
  height: unset;
  padding: unset;
  border: unset;
}

.maz-input__label {
  display: none;
}

.maz-input.is-valid, .maz-input.is-focused, .maz-input.is-focused.is-valid, .maz-input.is-focused.is-valid:hover {
  border-color: var(--maz-border-color) !important;
}

.maz-phone-number-input__country-flag + .maz-input.has-value:not(.is-valid) {
  border-color: var(--maz-danger) !important;
}

.maz-input.is-focused.is-valid .maz-input__icon path.arrow, .maz-input.is-valid .maz-input__icon path.arrow {
  fill: var(--maz-light-darken) !important;
}

.maz-input__toggle-btn.--clear{
  display: none;
}
</style>