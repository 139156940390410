import GfPaymentServiceClient from "../GfPaymentServiceClient";

export default {
    async submit(payload, csrfToken) {
        var result = await GfPaymentServiceClient.postDonation(payload, csrfToken);
        return result.data;
    },

    async updatePaymentRequest(paymentReferenceId, requestType, additionalData) {
        return await GfPaymentServiceClient.updatePaymentRequest(
            paymentReferenceId, 
            requestType, 
            additionalData);
    },

    async retrievePaymentStatus(clientReferenceId, attempt, maxAttempt) {
        return await GfPaymentServiceClient.retrievePaymentStatus(
            clientReferenceId
        );
    }
};
