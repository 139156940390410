<script>
import GfCardTypes from './GfCardTypes'
import GfPaymentStatusManager from "../common/GfPaymentStatusManager.vue"
import EmbeddedPaymentStore from "../components/embedded-payment-loader/EmbeddedPaymentStore.js";
import logger from "../common/logger";
export default {
    name: 'GfPaymentInputHandler',
    mixins: [GfPaymentStatusManager],
    data: function () {
        return {
            cardType: '',
            lastDigits: '',
            store: EmbeddedPaymentStore
        }
    },
    computed: {
        detailsTokenized: function () {
            return this.cardType !== '' && this.lastDigits !== '' && this.nonce !== ''
        }
    },
    methods: {
        highlightEnteredCardType: function (cardType) {
            switch (cardType) {
                case GfCardTypes.types.AMEX:
                    this.cardTypeToHighlight = "amex";
                    break;
                case GfCardTypes.types.MASTERCARD:
                    this.cardTypeToHighlight = "master";
                    break;
                case GfCardTypes.types.VISA:
                    this.cardTypeToHighlight = "visa";
                    break;
                case GfCardTypes.OTHER:
                    this.cardTypeToHighlight = "none";
                    break;
                default:
                    this.cardTypeToHighlight = "all";
            }
        },

        _updatePaymentType: function (paymentType) {
            this.cardType = paymentType;
            this.processButton && this.processButton.setAttribute("data-payment-type", this.cardType);
        },
        onDetailsEntered: function (lastDigits) {
            this.lastDigits = lastDigits;
            this.processButton && this.processButton.setAttribute("data-last-digits", this.lastDigits);
        },
        updateNonce: function (nonce) {
            this.nonce = nonce;
            this.processButton && this.processButton.setAttribute("data-nonce", this.nonce);
            this.$emit('tokenUpdate', nonce);
        },
        updateExpiry: function (expiry) {
            this.expiry = expiry;
            this.processButton && this.processButton.setAttribute("data-expiry", this.expiry);
        },
        _updateAdditionalData: function (additionalData) {
            var vm = this;
            Object.keys(additionalData).forEach(key => {
                vm.processButton && vm.processButton.setAttribute("data-" + key, additionalData[key]);
            });
        },
        updateInputData(paymentType, lastDigits, nonce, expiry, additionalData) {
            this.onPaymentTypeChanged(paymentType);
            this.onDetailsEntered(lastDigits);
            this.updateNonce(nonce);

            if (expiry && expiry !== undefined)
                this.updateExpiry(expiry);

            if (additionalData && additionalData !== undefined) {
                additionalData['paymentType'] = this.paymentMethod;
                additionalData['clientIpAddress'] = this.clientIpAddress;
                additionalData['paymentSubType'] = paymentType;
                additionalData['referrerUrl'] = window.location.href;
                this._updateAdditionalData(additionalData);
            }
        },
        clearInputData: function () {
            this.lastGeneratedTokenDetails = {
                nonce: '',
                paymentType: '',
                lastDigits: '',
                expiry: '',
                additionalData: {}
            };
            this.updateInputData('', '', '');
            this.$emit('tokenReset', this.$options.name);
            if (this.onTokenReset !== '' && this.onTokenReset) {
                window[this.onTokenReset]();
            }
        }
    }
}
</script>
