export default {
    "Beneficiaries": [{
        "BeneficiaryAccountId": "1372480",
        "BeneficiaryName": "PayPal Giving Fund UK",
        "BeneficiaryImagePath": "https:\/\/cdn.uk.onprem2.gfrnetworks.com\/images\/200\/Upload\/beneficiary\/1372480\/logo.png",
        "BeneficiaryTotal": "",
        "BeneficiaryTarget": "",
        "BeneficiaryUrl": "https:\/\/www.uk.onprem2.gfrnetworks.com\/beneficiary\/paypalgivingfund",
        "BeneficiaryDonationUrl": "https://www.uk.onprem2.gfrnetworks.com/payments/donate/beneficiary/1372480",
        "BeneficiaryCreatePageUrl": "https://www.uk.onprem2.gfrnetworks.com/pages/create?cid=1372480",
        "BeneficiaryRegion": "UK",
        "BeneficiaryLocation": "",
        "BeneficiaryContactEmail": "",
        "BeneficiaryParents": [{
            "Id": 1234,
            "PercentageSplit": 1.0
        }],
        "BeneficiaryExternalIds": [],
        "BeneficiaryHierarchy": {
            "BeneficiaryTotal": ""
        },
        "BeneficiaryTags": [],
        "BeneficiaryTheme": {
            "DriverButtonBackgroundColour": "#000",
            "DriverButtonFontColour": "#fff",
            "SecondaryButtonColour": "#000",
            "SecondaryButtonFontColour": "#777",
            "BannerBackgroundColour": "",
            "BannerImage": "https:\/\/cdn.uk.onprem2.gfrnetworks.com\/images\/200\/Upload\/beneficiary\/1372480\/logo.png"
        }
    }],
    "ResultsReturned": 1,
    "PageNumber": 1,
    "TotalPages": 1
}