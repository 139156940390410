<template>
  <div
    id="gf-epl-additional-item"
    class="row m-0 p-0 w-100"
  >
    <component
      :is="settings.type"
      :settings="settings.itemSettings"
      :displayed-on-page-index="displayedOnPageIndex"
      @action="(eventData) => this.$emit('action', eventData)"
    />
  </div>
</template>

<script>
import ModuleBase from "../ModuleBase.vue";
import TransactionFee from './TransactionFee.vue'

export default {
  components: {TransactionFee},
  mixins: [ModuleBase],
  data() {
    return {
      isParentModule: true
    }
  },
};
</script>

<style></style>
