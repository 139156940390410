<script>
import { nextTick } from 'vue';
import { customQuestionComponents, operators, resolveFieldAlias, resolveModuleAlias, unionOperators } from './ConditionalLogicConfig';
import conditionalRenderStore from './ConditionalLogicStateStore';

export default {
    name: 'ConditionalRenderMixin',
    props: {
        conditions: {
            type: Array,
            default: () => []
        },
    },
    data(){
        return {
            componentKey: this.$options.name,
            watchables: [],
            conditionalRenderStore,
            actionDictionary: {},
        }
    },
    watch: {
        'conditionalRenderStore.state': {
            async handler(){
                this.setupWatchableFields();
                await nextTick();
                if(!this.conditions || !this.conditions.length) return;
                this.conditions.forEach(condition => {
                        this.evaluateCondition(condition)
                })
            },
            immediate: true,
            deep: true,
        }
    },
    destroyed(){
        this.cleanupWatchers();
    },
    methods: {
        setupWatchableFields(){
            let uniqueKey = this.componentKey;
            const isComponentCustomQuestion = customQuestionComponents.includes(this.componentKey);
            if(isComponentCustomQuestion && this.$props.hasOwnProperty('settings')){
                if (this.$props.settings.hasOwnProperty('uniqueIdentifier'))
                uniqueKey = this.$props.settings.uniqueIdentifier;
                else if (this.$data.settings.hasOwnProperty('uniqueIdentifier'))
                uniqueKey = this.$data.settings.uniqueIdentifier;
            }
            const isWatchable = Object.keys(this.conditionalRenderStore.state).includes(uniqueKey);
            if(!isWatchable) return;
            const watchableFields = Object.keys(this.conditionalRenderStore.state[uniqueKey]);
            watchableFields.forEach((wfield) => {
                const isWatched = this.watchables.find(watchedField =>
                watchedField.componentKey === uniqueKey &&
                watchedField.property === wfield);
                if(isWatched) return;
                if(this.$props.hasOwnProperty(wfield) || this.$data.hasOwnProperty(wfield)) {
                    this.setupDynamicWatcher(wfield, uniqueKey);
                }
            })
        },
        setupDynamicWatcher(property, uniqueKey) {
            const dynamicWatcher = this.$watch(property, (newVal) => {
                this.conditionalRenderStore.setState(uniqueKey, property, newVal);
            }, { immediate: true });
            this.watchables.push({ componentKey: uniqueKey, property, unwatch: dynamicWatcher });
        },
        cleanupWatchers() {
            this.watchables.forEach(watchable => watchable.unwatch());
            this.watchables = [];
        },
        registerAction(action, cb){
            this.actionDictionary[action] = cb;
        },
        evaluateCondition(condition){
            let result = null;
            const storeState = this.conditionalRenderStore.state;
            if(!condition.conditionals) return;
            condition.conditionals.forEach(conditional => {
                const module = resolveModuleAlias(conditional.dependsOnModule);
                const property = resolveFieldAlias(conditional.property);
                const stateValue = storeState[module] && storeState[module][property];
                if(stateValue === null || stateValue === undefined) return;
                let isValid = false;
                switch (conditional.operator) {
                    case operators.greaterThan:
                        isValid = (stateValue > conditional.operand)
                        break;
                    case operators.lessThan:
                        isValid = (stateValue < conditional.operand)
                        break;
                    case operators.greaterThanOrEqualTo:
                        isValid = (stateValue >= conditional.operand)
                        break;
                    case operators.lessThanOrEqualTo:
                        isValid = (stateValue <= conditional.operand)
                        break;
                    case operators.equalTo:
                        isValid = (stateValue == conditional.operand)
                        break;
                    case operators.notEqualTo:
                        isValid = (stateValue != conditional.operand)
                        break;
                }
                if(result === null) result = isValid;
                else if (condition.unionOperator === unionOperators.and) result = result && isValid;
                else if (condition.unionOperator === unionOperators.or)  result = result || isValid;
            })
            if(result === null) return;
            if(result)
            this.actionDictionary[condition.action] && this.actionDictionary[condition.action]();
            else
            condition.fallbackAction && this.actionDictionary[condition.fallbackAction] && this.actionDictionary[condition.fallbackAction]();
        },
    },

}
</script>
