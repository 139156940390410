<template>
  <div
    id="gf-epl-dollar-handles"
    class="row m-0 p-0 w-100"
  >
    <template v-if="handleItems == null">
      <div class="col-md-12">
        <div class="row">
          <div
            v-for="i in 4"
            :key="i"
            class="col-6 col-md-3 mb-3"
          >
            <SkeletonBox
              width="100%"
              height="50px"
            />
          </div>
        </div>
      </div>
    </template>
    <div
      v-for="(item, index) in handleItems"
      v-else
      :key="index"
      :class="`${settings.class} ${handlesTypeClass}`"
    >
      <div @click="setPredefined(item.value)">
        <input
          :id="`donation-amount-${index}`"
          v-model="predefinedValue"
          name="DonationAmount"
          type="radio"
          :value="item.value"
          tabindex="0"
        >
        <label
          :for="`donation-amount-${index}`"
          :data-amountdesc="item.text"
          :title="item.text"
          class="donation-line"
          :class="{
            'gf-secondary-border':
              predefinedValue === item.value && usingPredefined
          }"
        >
          <div
            v-if="item.imageUrl"
            class="donation-image m-auto"
            :style="`background-image: url('${item.imageUrl}')`"
          />
          <span
            :class="{
              'gf-secondary-bg selected gf-secondary-text':
                predefinedValue === item.value &&
                usingPredefined
            }"
          >{{ currencySymbol }}{{ item.value }}</span></label>
      </div>
    </div>
    <div
      hide=""
      class="col-md-12 text-center m-auto"
    >
      <p>{{ getItemTitle }}</p>
    </div>

    <div
      v-if="settings.displayOther"
      class="donation-item hide-radio other-amount col-12 field"
    >
      <div class="currency-symbol">
        {{ currencySymbol }}
      </div>
      <input
        id="donation-other"
        name="DonationOther"
        type="radio"
        value=""
      >
      <div class="ui input">
        <input
          v-model="customAmount"
          name="OtherAmount"
          type="text"
          value=""
          :placeholder="`${settings.otherAmountPlaceHolderText}`"
        >
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonBox from '../../../common/SkeletonBox.vue';
import AdditionalItemBase from '../AdditionalItem/AdditionalItemBase.vue';
import ModuleBase from "../ModuleBase.vue";
import constants from '../../../embedded-payment-loader/EmbeddedPaymentConstants'

export default {
    name: "DollarHandles",
    components: { SkeletonBox },
    mixins: [
      ModuleBase,
      AdditionalItemBase,
    ],
    data() {
        return {
            selectedValue: 0,
            predefinedValue: null,
            customAmount: "",
            usingPredefined: false,
            handleItems: null,
            entityConfigSettings: [
              {
                type: "Donation",
                subType: "DollarHandles"
              }
            ],
        };
    },

    computed: {
        handlesTypeClass() {
            return this.settings.type == "tiled"
                ? this.getColumnNumberByQuantityItem()
                : "col-12";
        },
        getItemTitle() {
            if (this.handleItems == null || this.handleItems.length == 0)
                return ""
            var item = this.handleItems.find(
                x => x.value == this.predefinedValue
            );
            return item ? item.text : "";
        },
        currencySymbol(){
			return this.sharedState.currencyInfo.symbol || "$";
		}
    },

    watch: {
        customAmount(val) {
            this.usingPredefined = val === this.predefinedValue;
            this.selectedValue = val;
        },

        selectedValue(val) {
            this.store.updateAmount(val);
            this.$nextTick(async () => {
                await this.$ModuleBase_validate();
            });
            this.updateAdditionalItemUnitPrice(val)
        },
        'store.state.nameDisplayFormat': {
          deep: true,
          handler: function (val) {
            this.isAnonymous = val == constants.DisplayNameFormat.anonymous
          }
        }
    },

    validators: {
        selectedValue(value) {
            return this.validator
                .value(value)
                .required()
                .float()
                .greaterThan(0);
        }
    },

    beforeMount() {
      // Update the donation line item
      this.productType = 1
      this.mainLineItem = true
      this.description = 'Tax Deductible Donation'
      this.isAnonymous = false
      this.unitPrice = this.store.state.amount
      this.beneficiary = parseInt(this.store.state.beneficiaryId)
      this.fundraisingPageId = this.store.state.fundraisingPageId
    },

    mounted() {
        this.logger.logInfo("Dollar handles Settings", this.settings)
        if (this.selectedValue > 0) this.isValid = true;
        else this.isValid = false;

        if (this.settings.items) {
            if (this.handleItems == null) {
                this.handleItems = []
            }

            this.handleItems.push(...this.settings.items)
        }
    },

    methods: {
        $ModuleBase_handleEntityConfigResults(results) {
            this.logger.logInfo("Dollar handles config results", results)

            if (this.settings.useDefaults && this.settings.useDefaults === true && results.length > 0 && results[0].Values.length > 0) {
                if (this.handleItems == null) {
                    this.handleItems = []
                }
                this.handleItems.splice(0);
                var cdnBaseUrl = this.store.state.cdnBaseUrl;
                this.logger.logInfo(`DollarHandles, cdnBaseUrl: ${cdnBaseUrl}`);
                this.handleItems.push(...results[0].Values.map(x => {
                    return {
                        value: x.Value,
                        text: x.Text,
                        imageUrl: (x.ImageUrl === null || x.ImageUrl === '' || x.ImageUrl.indexOf('http://') === 0 || x.ImageUrl.indexOf('https://') === 0 || x.ImageUrl.indexOf('//') === 0) ? x.ImageUrl : cdnBaseUrl + x.ImageUrl
                    }
                }))
            }
        },


        setPredefined(val) {
            this.usingPredefined = true;
            this.customAmount = val;
            this.selectedValue = val;
        },

        getColumnNumberByQuantityItem() {
            var result = "";
            if (!this.handleItems || !this.settings.itemsPerRow)
                return result

            this.settings.itemsPerRow.forEach(element => {
                switch (element.type) {
                    case "phone":
                        result +=
                            element.quantity <= this.handleItems.length
                                ? "col-" + 12 / element.quantity + " "
                                : "col ";
                        break;
                    case "tablet":
                        result +=
                            element.quantity <= this.handleItems.length
                                ? "col-sm-" + 12 / element.quantity + " "
                                : "col-sm ";
                        break;
                    case "desktop":
                        result +=
                            element.quantity <= this.handleItems.length
                                ? "col-md-" + 12 / element.quantity + " "
                                : "col-md ";

                        break;
                    case "largerDesktop":
                        result +=
                            element.quantity <= this.handleItems.length
                                ? "col-lg-" + 12 / element.quantity + " "
                                : "col-lg ";

                        break;
                    case "extraLargerDesktop":
                        result +=
                            element.quantity <= this.handleItems.length
                                ? "col-xl-" + 12 / element.quantity + " "
                                : "col-xl ";
                        break;
                }
            });

            return result;
        },
    }
};
</script>
<style scoped>
.ui-tile-item input[type="radio"],
.hide-radio input[type="radio"] {
    visibility: hidden;
    position: absolute;
}

.ui-tile-item label > span {
    background: #f7f7f7;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
    display: block;
    padding: 12px;
    cursor: pointer;
}

.ui-tile-item input + label,
.ui-tile-item input + label > span {
    width: 100%;
}

.ui-tile-item input:checked + label > span.selected {
    background: #1388cd;
    color: #fff;
}

#gf-epl-dollar-handles .other-amount {
    position: relative;
    margin-bottom: 20px;
    margin-top: 5px;
}

.currency-symbol {
    position: absolute;
    z-index: 1;
    top: 19px;
    left: 30px;
    font-weight: 700;
    color: #a2a2a2;
}

.donation-item.ui-tile-item label > span {
    margin-bottom: 0px;
}

.donation-item {
    padding: 4px !important;
    align-self: center;
}

#gf-epl-dollar-handles input[type="text"],
#gf-epl-dollar-handles input[type="email"],
#gf-epl-dollar-handles input[type="password"],
#gf-epl-dollar-handles input[type="textarea"],
#gf-epl-dollar-handles select {
    margin: 0;
    outline: 0;
    tap-highlight-color: hsla(0, 0%, 100%, 0);
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.2);
    color: #000;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    box-shadow: inset 0 0 0 0 transparent;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    line-height: 1.6em;
    padding: 13px;
    width: 99.8%;
}

#gf-epl-dollar-handles .other-amount > .ui.input > input {
    padding-left: 40px;
}
.donation-line {
    border: 2px solid transparent;
    display: block;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
}
.donation-image {
    padding-top: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
