import intermediaryPrefillDataLoader from "./intermediaryPrefillDataLoader"
import casingUtilities from "../casingUtilities"

export default {
  async get(settings) {
    if (typeof settings.type == 'undefined' || ! settings)
      return await new Promise(resolve => resolve({}))

    switch (settings.type.toLowerCase()) {
      case 'intermediary':
        return await new Promise(async resolve => {
            let data = casingUtilities.objectKeysToCamelCase(await intermediaryPrefillDataLoader.get(settings.settings))
            resolve(data)
        })
      default:
        return await new Promise(resolve => resolve({}))
    }

  }
}
