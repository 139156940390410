<script>
import GfCardTypes from "../../../common/GfCardTypes"
import VS2 from '@gf/vue-script2';

export default {
  name: 'GfStripeInitializer',
  data() {
    return {
      instanceOptions: {},
      elementsOptions: {},
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      options: {
        style: {
          base: {
            fontSize: '18px',
            fontFamily: 'Arial'
          }
        }
      },
      loadedStripe: false,
      lastToken: {
        token:'',
        type:'',
        last4: '',
        expiry: ''
      }
    }
  },
  watch: {
    number() {
      this.update()
    },
    expiry() {
      this.update()
    },
    cvc() {
      this.update()
    }
  },
  mounted() {
    var vm = this;
    VS2.load('https://js.stripe.com/v3/').then(function () {
      vm.loadedStripe = true;
    });
  },
  methods: {
    update() {
      this.complete = this.number && this.expiry && this.cvc
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.stripeElement.focus()
        } else if (!this.cvc) {
          this.$refs.cardCvc.stripeElement.focus()
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.stripeElement.focus()
        } else if (!this.number) {
          this.$refs.cardNumber.stripeElement.focus()
        }
      }
      if (this.complete) {
        this.createStripeToken();
      } else {
        if (this.cardType == 'unknown') {
          this.clearInputData();
        } else {
          this.updateInputData(this.cardType, '', '');
        }
      }
    },
    createStripeToken() {
      var self = this;
      const groupComponent = self.$refs.elms
      const cardNumberComponent = self.$refs.cardNumber
      if (!groupComponent || !groupComponent.instance)
        return;

      groupComponent.instance.createToken(cardNumberComponent.stripeElement).then(function(data) {
        self.lastToken.token = data.token.id;
        self.lastToken.type = self.normaliseCard(data.token.card.brand);
        self.lastToken.last4 = data.token.card.last4;
        self.lastToken.expiry = `${data.token.card.exp_month}/${data.token.card.exp_year}`;
        self.onPaymentMethodReceived(data.token.id, self.normaliseCard(data.token.card.brand), data.token.card.last4, self.lastToken.expiry, self.additionalData);
      });
    },

    normaliseCard(cardType) {
      switch (cardType.toLowerCase()) {
          case "amex":
          case "american express":
              return GfCardTypes.types.AMEX;
          case "mastercard":
              return GfCardTypes.types.MASTERCARD;
          case "visa":
              return GfCardTypes.types.VISA;
          default:
              return GfCardTypes.OTHER;
      }
    }
  }
}
</script>
