import EmbeddedPaymentStore from '../../components/embedded-payment-loader/EmbeddedPaymentStore'
import DemoResponse from '../../components/embedded-payment-loader/DemoResponse';
import GfApiIntermediaryService from '@gf/gf-api-services/services/intermediary/IntermediaryService'

export default {
  async get(settings) {

    if (typeof settings.id == 'undefined' || typeof settings.owner == 'undefined') {
      return await new Promise((resolve, reject) => {
        reject('ID and Owner is required to fetch the intermediary data')        
      })
    }

    if (EmbeddedPaymentStore.state.isDemo) {
      return await new Promise(resolve => {
        resolve(DemoResponse.SuccessIntermediaryResponse)
      })
    }

    const apiService = new GfApiIntermediaryService()
    const response = await apiService.get('EmbeddedPayment', settings.id, settings.owner)
    return response
  }
}
