import { render, staticRenderFns } from "./Standard.vue?vue&type=template&id=151e0d04&scoped=true&"
import script from "./Standard.vue?vue&type=script&lang=js&"
export * from "./Standard.vue?vue&type=script&lang=js&"
import style0 from "./Standard.vue?vue&type=style&index=0&id=151e0d04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151e0d04",
  null
  
)

export default component.exports