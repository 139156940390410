<template lang="html">
  <div>
    <div
      id="pay-cardimages"
      class="ccIcon cctype icon selected float-right"
    >
      <label
        id="pm-visa"
        class="radio visa"
        for="visa"
        data-type="Visa"
        :class="{hilite: highlightVisa}"
      >
        <input
          id="visa"
          type="radio"
          name="CardType"
          tabindex="-1"
          value="Visa"
          style="display:none"
          disabled="disabled"
        >
        <span class="cclogo visa">&nbsp;</span>
      </label>
      <label
        id="pm-mastercard"
        class="radio mc"
        for="mastercard"
        data-type="MasterCard"
        :class="{hilite: highlightMaster}"
      >
        <input
          id="mastercard"
          type="radio"
          class="mc_subtype"
          tabindex="-1"
          name="CardType"
          style="display:none"
          value="MasterCard"
          disabled="disabled"
        >
        <span class="cclogo mc">&nbsp;</span>
      </label>
      <label
        id="pm-amex"
        class="radio amex"
        for="amex"
        data-type="AmEx"
        :class="{hilite: highlightAmex}"
      >
        <input
          id="amex"
          type="radio"
          name="CardType"
          value="AmericanExpress"
          style="display:none"
          disabled="disabled"
        >
        <span class="cclogo amex">&nbsp;</span>
      </label>
    </div>
  </div>
</template>

<script>
import logger from "../../common/logger";

export default {

  name: 'GfSupportedPaymentTypes',
  props: {
    /**
      * Used to configure the item to highlight. Supported values are:
      * all, none, visa, master, amex
      */
    highlightItem: {
      type: String,
      default: 'all'
    } 
  },
  data: function() {
      return {
          highlightMaster: false,
          highlightVisa: false,
          highlightAmex: false
      }
  },

  watch: {
    highlightItem: function(val) {
      logger.logInfo(`highlightItem: ${val}`);
      this.onHighlightPropUpdate(val);
    }
  },

  mounted: function() {
    this.onHighlightPropUpdate(this.highlightItem);
  },

  methods: {
    onHighlightPropUpdate: function(val) {
      switch (val.toLowerCase()) {
        case "all":
          this.setHighlightingOfAllTo(true);
          break;
        case "none":
          this.setHighlightingOfAllTo(false);
          break
        default:
          this.highlightOnly(val);
      }
    },

    setHighlightingOfAllTo: function (val) {
      this.highlightMaster = val;
      this.highlightVisa = val;
      this.highlightAmex = val;
    },

    highlightOnly: function (val) {
      logger.logInfo(`highlightOnly: ${val}`);
      switch (val.toLowerCase()) {
        case "amex":
          this.highlightAmex = true;
          this.highlightVisa = false;
          this.highlightMaster = false;
          break;
        case "master":
          this.highlightAmex = false;
          this.highlightVisa = false;
          this.highlightMaster = true;
          break;
        case "visa":
          this.highlightAmex = false;
          this.highlightVisa = true;
          this.highlightMaster = false;
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style lang="css" scoped>
#pay-cardimages label {
    float: left;
    height: 23px;
    margin-right: 1em;
    width: 40px !important;
    padding: 0px !important;
}

div.cctype .visa
{
    background: url(https://cdn.gofundraise.com.au/content/images/payment_icons_sprite.2D.png) no-repeat left -30px;
}
div.cctype .mc
{
    background: url(https://cdn.gofundraise.com.au/content/images/payment_icons_sprite.2D.png) no-repeat left -60px;
}
div.cctype .amex
{
    background: url(https://cdn.gofundraise.com.au/content/images/payment_icons_sprite.2D.png) no-repeat left -120px;
}
div.cctype.selected label
{
    opacity: 0.13;
    filter: alpha(opacity=13);
}
div.cctype.selected label.hilite
{
    opacity: 1;
    filter: alpha(opacity=100);
}
</style>
