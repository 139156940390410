<template lang="html">
  <div id="multiGatewayLoader">
    <div v-if="supportedGateways.length > 0">
      <vue-tabs
        content-class="mt-3"
        @tab-change="handleTabChange"
      >
        <v-tab
          v-for="(item, key) in supportedGateways"
          :key="key"
          :title="getPaymentMethodFriendlyName(item.paymentMethod)"
        >
          <div class="panel panel-default px-3 py-3">
            <component
              :is="getComponentName(item.type)"
              :additional-properties="item.additionalSettings"
              :tokenization-key="item.publicToken"
              :process-button-id="processButtonId"
              :pending-actions-data="pendingActionsData"
              :global-event-bus="globalEventBus"
              :on-tokenize-success="onTokenizeSuccess"
              :on-token-reset="onTokenReset"
              :on-fees-received="onFeesReceived"
              :on-ready="onReady"
              :on-load-error="onLoadError"
              :client-ip-address="clientIpAddress"
              :tear-down-component="tearDownComponent"
              :embedded-payment-mode="embeddedPaymentMode"
              @tokenReset="onTokenResetEvent"
              @tokeniseSuccess="onTokenSuccess"
              @teardownSuccess="$emit('teardownSuccess')"
            />

            <div
              v-if="item.paymentMethod === 'PP'"
              id="gfPresetPayPalBtn"
              class="col-12 col-md-8 m-auto pb-3"
            />
          </div>
        </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import logger from "../../common/logger";
import Vue from "vue";
import SupportedGateways from "../SupportedGateways.vue";
import {VueTabs, VTab} from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: "GfMultiGatewayLoader",
  components: {
    VueTabs,
    VTab
  },

  mixins: [SupportedGateways],
  props: {
    apiDomain: {
      type: String
    },
    supportedGateways: {
      type: Array,
      default: () => []
    },
    processButtonId: {
      type: String,
      default: "",
      required: true
    },
    pendingActionsData: {
        type: Object,
        default: () => null
    },
    globalEventBus: {
      type: Object,
      default: () => {}
    },
    onTokenizeSuccess: {
      type: String,
      default: ""
    },
    onFeesReceived: {
      type: String,
      default: ""
    },
    onTokenReset: {
      type: String,
      default: ""
    },
    onReady: {
      type: String,
      default: ""
    },
    onLoadError: {
      type: String,
      default: ""
    },
    additionalProperties: {
      type: Object
    },
    clientIpAddress: {
      type: String,
      default: ""
    },
    embeddedPaymentMode: {
        type: Boolean,
        default: false
    }
  },

  data: function() {
    return {
      selectedGateway: 0,
      gatewayTokenDetails: {},
      tearDownComponent: false
    };
  },
  watch: {
    supportedGateways(val) {
          logger.logInfo("supported Gateways changed", this.supportedGateways);
    },

    selectedGateway(val) {
      var selectedGatewayType = this.supportedGateways[val].Type;
      logger.logInfo(`Selected gateway changed: ${selectedGatewayType}`)
      logger.logInfo("token details", this.gatewayTokenDetails)

      if (this.gatewayTokenDetails[selectedGatewayType] && this.gatewayTokenDetails[selectedGatewayType].paymentToken && this.gatewayTokenDetails[selectedGatewayType].paymentToken.length > 0) {
        window[this.onTokenizeSuccess](this.gatewayTokenDetails[selectedGatewayType]);
      }
      else {
        if (this.onTokenReset !== '' && this.onTokenReset) {
          window[this.onTokenReset]();
        }
      }
    }
  },

  methods: {
    getPaymentMethodFriendlyName(paymentMethod) {
      if (!paymentMethod)
        return "Credit Card";

      switch (paymentMethod.toLowerCase()) {
        case "pp":
          return "PayPal"
        default:
          return "Credit Card"
      }
    },

    onTokenResetEvent(source) {
      logger.logInfo(`onTokenResetEvent from ${source}`);
      this.gatewayTokenDetails[this.getFriendlyName(source)] = {};
    },

    onTokenSuccess(val) {
      logger.logInfo("GfMultiGatewayLoader, onTokenSuccess", val);
      if (val.source && val.source.length > 0)
        this.gatewayTokenDetails[this.getFriendlyName(val.source)] = val
    },

    handleTabChange(tabIndex, newTab, oldTab){
        this.selectedGateway = tabIndex;
    },

    tearDown() {
      this.tearDownComponent = true;
    }
  }
};
</script>

<style lang="css" scoped>
#gfPresetPayPalBtn{
  border:none;
  padding: 0;
}
</style>
