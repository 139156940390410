import EmbeddedPaymentConstants from "../../EmbeddedPaymentConstants";
import moment from 'moment'

export default {
    calculate(period) {
        var result = null;
        switch (period) {
            case EmbeddedPaymentConstants.RegularGivingFrequency.Daily:
                result = moment().add(1, 'days')
                break
            case EmbeddedPaymentConstants.RegularGivingFrequency.Weekly:
                result = moment().add(1, 'weeks')
                break
            case EmbeddedPaymentConstants.RegularGivingFrequency.Monthly:
                result = moment().add(1, 'months')
                break
            case EmbeddedPaymentConstants.RegularGivingFrequency.Quarterly:
                result = moment().add(1, 'quarters')
                break
            case EmbeddedPaymentConstants.RegularGivingFrequency.Yearly:
                result = moment().add(1, 'years')
                break
            default:
                return result;
        }
        return this.formattedDate(result)
    },

    formattedDate(dateTime) {
        return dateTime.utc().format('YYYY-MM-DD HH:mm:ss +00:00')
    }
}
