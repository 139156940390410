import DemoResponse from "../../components/embedded-payment-loader/DemoResponse";

export default {
    async submit(payload) {
        if (payload.WaitForCompletion) {
            return await (new Promise(resolve => resolve(DemoResponse.SuccessResponse)));
        } else {
            return await (new Promise(resolve => resolve(DemoResponse.SuccessNoWaitForCompletionResponse)));
        }
    },
    
    async updatePaymentRequest(paymentReferenceId, requestType, additionalData) {
        // TODO: return some values for demo
    },

    async retrievePaymentStatus(clientReferenceId, attempt, maxAttempt) {
        return await (new Promise(resolve => resolve(attempt < maxAttempt
                    ? DemoResponse.RetrievePaymentStatusReceivedResponse
                    : DemoResponse.RetrievePaymentStatusSuccessResponse)));
    }
};
