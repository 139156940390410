export default [{
    number: 1,
    pageType: "SLIDE",
    class: "",
    backgroundColour: null,
    contents: [{
        module: "DollarHandles",
        settings: {
            items: [{
                value: 50,
                text: "",
                imageUrl: "",
            }, {
                value: 100,
                text: "",
                imageUrl: "",
            }, {
                value: 250,
                text: "",
                imageUrl: "",
            }, {
                value: 500,
                text: "",
                imageUrl: "",
            }, {
                value: 1000,
                text: "",
                imageUrl: "",
            }, {
                value: 5000,
                text: "",
                imageUrl: "",
            }, ],
            type: "tiled",
            itemsPerRow: [{
                type: "phone",
                quantity: 2,
            }, {
                type: "tablet",
                quantity: 3,
            }, {
                type: "desktop",
                quantity: 4,
            }, {
                type: "largerDesktop",
                quantity: 6,
            }, {
                type: "extraLargerDesktop",
                quantity: 6,
            }, ],
            displayOther: true,
            otherAmountPlaceHolderText: "Other",
            class: "donation-item ui-tile-item",
        },
    }, 
    {
        "module": "DollarMatching",
        "settings": { "Title": "Your Impact" }
    },
    {
        module: "FeeModule",
        settings: {
            type: "DonationTip",
            feeSettings: {
                "DefaultTipValue": 9,
                "SuggestedTipValues": [
                    9
                ],
                "DisplayCurrencyName": true,
                "IsSuggestedValuesPercentages": true,
                "tipThankYouMessage": "Thank you for your generous tip of $Tip.Amount$",
                "TipDescription": "Help cover platform fees ",
                "TipDescriptionToolTip": "By adding this amount you will help GoFundraise, a for-purpose online fundraising platform, cover our costs and continue to offer this service."
            }
        },
    }, {
        module: "ActionButton",
        settings: {
            text: "Next",
            action: "NEXTPAGE",
            class: "btn btn-primary btn-next",
        },
    }, ],
}, {
    number: 2,
    pageType: "SLIDE",
    class: "",
    backgroundColour: null,
    contents: [{
        module: "ContactDetails",
        settings: {
            enableCompanySelector: true,
            nameDisplayFormatSelector: true,
            displayMobileNumber: true,
        },
    }, {
        module: "Address",
        settings: {
            isRequired: true,
            class: '',
            placeHolderText: 'Please put your address here'
        }
    }, {
        module: "FundraisingMessage",
        settings: {
            text: "Message for Fundraising page (optional)",
            isRequired: false,
        },
    }, {
        module: "ActionButton",
        settings: {
            text: "Next",
            action: "NEXTPAGE",
            class: "btn btn-primary btn-next",
        },
    }, ],
}, {
    number: 3,
    pageType: "SLIDE",
    class: "",
    backgroundColour: null,
    contents: [{
        module: "Payment",
        settings: {
            displayCardholderNameSelector: true,
        },
    }, {
        module: "TermsConditions",
        settings: {
            termsConditionsLink: "",
            privacyPolicyLink: "",
            newsletterSignUp: "I would like to sign in to Gofundraise newsletter and be updated.",
            contactOptIn: "I am happy to be contacted regarding this cause.",
        },
    }, {
        module: "ActionButton",
        settings: {
            text: "Donate",
            action: "DONATE",
            actionSettings: {
                "waitForCompletion": false,
                "pollingPeriodInMs": 1500,
                "pollingTimeoutInMs": 18000
            },
            class: "btn btn-primary btn-donate",
        },
    }, ],
}, {
    number: 4,
    pageType: "COMPLETION",
    class: "",
    backgroundColour: null,
    contents: [{
        module: "GfText",
        settings: {
            content: "Thank you for your donation to $Beneficiary.BeneficiaryName$",
            class: "text-center"
        },
    }, {
        module: "DownloadReceipt",
        settings: {
            content: '<span>We have sent your receipt to your email, but you can <a href="javascript:void(0)" id="receiptDownload">click here</a> if you want to see it now.<span>',
            downloadLinkSelector: "#receiptDownload",
            class: "text-center"
        },
    }, {
        module: "ActionButton",
        settings: {
            text: "Close",
            action: "RELOAD",
            class: "btn btn-primary btn-close",
        },
    }, ],
}, {
    "number": 5,
    "pageType": "COMPLETION_ERROR",
    "class": "",
    "backgroundColour": null,
    "contents": [{
        "module": "GfText",
        "settings": {
            "content": "Unexpected error has occurred with your payment. Please contact support on 1300 889 272 (or +61 2 8188 8920) and mention the payment reference id: $PaymentReferenceId$",
        }
    }, {
        "module": "ActionButton",
        "settings": {
            "text": "Close",
            "action": "RELOAD",
            "class": "btn btn-primary btn-close",
        }
    }]
}
];