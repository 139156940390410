<template>
  <div v-if="!loadFailure">
    <div
      id="gfPresetApplePayButton"
      class="payment-button-wrapper"
      :data-type="paymentTypeName"
    >
      <!-- <button>APPLE PAY BUTTON</button> -->
      <apple-pay-button
        buttonstyle="black"
        type="pay"
        locale="en-AU"
        @click="onApplePayButtonClicked"
      />
    </div>
  </div>
</template>
<script>
import GfPaymentComponent from '../../../common/GfPaymentComponent.vue';
import GfBraintreeApplePayInitializer from './GfBraintreeApplePayInitializer.vue'
import logger from '../../../common/logger'

export default {
  name: 'GfBrainTreeApplePay',
  mixins: [GfPaymentComponent, GfBraintreeApplePayInitializer],
  data() {
    return {
      paymentMethodButtonSelector: '#gfPresetApplePayButton',
      paymentTypeName: 'braintree-applepay',
      paymentMethod: 'AP',
      isInjectedToDom: false,
      readyToInject: false,
    }
  },

  methods: {
    injectToDOM() {
      this.injectPaymentGatewayButton(
        this.paymentMethodButtonSelector,
        this.paymentTypeName,
        {
          callback: () => {
            this.isInjectedToDom = true
            this.onPaymentMethodReceived(Date.now().toString(), "", "", "", { forceSubmit: false })
          }
        }
      )
    },
    $_GfPaymentComponent_onPendingClientActions(data) {
      logger.logInfo(`GfBraintreeApplePay, onPendingClientActions:`, data);
      if(data.AdditionalData.PaymentAmount) {
        this.paymentAmount = data.AdditionalData.PaymentAmount
        this.store.updateAmount(data.AdditionalData.PaymentAmount)
      }
    }
  }
}
</script>
<style lang="css">
apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 38px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 5px 0px;
}
@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.braintreePPFlexBox{
  display: flex;
  flex-flow: row;
}
.braintreePPFlexBox p{
  white-space: pre;
  margin-bottom: 0px;
}
.animated-placeholder,.animated-placeholder-user {
  display: none;
  margin: 0 auto;
 background-color: #eee;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eee 10%, #ddd 18%, #eee 10%);
  background-size: 800px 104px;
  height: 16px;
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  width: 100%;
}

input[disabled], div[disabled], p[disabled] {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}

.payment-button-wrapper {
  max-width: 800px;
  width: 100%;
}

</style>
