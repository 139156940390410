export default {
  totalPriceStatus: {
    estimated: 'ESTIMATED',
    final: 'FINAL',
    notCurrentlyKnown: 'NOT_CURRENTLY_KNOWN',
  },
  environment: {
    test: 'TEST',
    production: 'PRODUCTION'
  },
  apiVersion: 2,
  apiVersionMinor: 0,
  googlePayVersion: 2
}