<script>
import axios from 'axios';
import DemoResponse from '../components/embedded-payment-loader/DemoResponse';
import EmbeddedPaymentStore from '../components/embedded-payment-loader/EmbeddedPaymentStore';

export default {
  data() {
    return {
      store: EmbeddedPaymentStore,
      endpoint: '/configuration/get-settings',
      ajaxOptions: {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }
    };
  },

  methods: {
    getSettingsRequest(eventId, region, configurationName, configurationSubType) {
      return {
        "SettingsContext": {
          "EventId": eventId,
          "Region": region
        },
        "Types": [{
          "ConfigurationName": configurationName,
          "ConfigurationSubType": configurationSubType
        }]
       };
    },

    getMultipleSettingsRequest(eventId, beneficiaryId, fundraisingPageId, regionId, types, intermediarySettings) {
      let request = {
        "SettingsContext": {
          "EventId": eventId,
          "Region": regionId,
          "BeneficiaryId": beneficiaryId,
          "FundraisingPageId": fundraisingPageId,
        },
        "Types": types.map(x => {
          return {
            "ConfigurationName": x.type,
            "ConfigurationSubType": x.subType
          }
        })
       };

       if (intermediarySettings) {
        request.SettingsContext = Object.assign(request.SettingsContext, ...intermediarySettings.map(s => {
          let obj = {}
          obj[s.type] = s.value
          return obj
        }))
       }

       return request
    },

    async getClientSettings(request) {
      let isDemo = this.store.state.isDemo;

      if (isDemo) {
        if(request.Types.length > 0 && request.Types[0].ConfigurationName === "GivingDay")
          return new Promise(resolve => {
            resolve(DemoResponse.SuccessGivingDayResponse);
          });
        else
          return new Promise(resolve => {
            resolve(DemoResponse.SuccessLocationApiResponse);
          });
      }

      let response = await axios.post(this.endpoint, request, this.ajaxOptions);
      return response.data;
    },

    async getCaptchaSettings(request) {

      let isDemo = this.store.state.isDemo;
      if (isDemo) {
        return new Promise(resolve => {
          resolve(DemoResponse.SuccessCaptchaApiResponse);
        });
      }

      let response = await axios.post(this.endpoint, request, this.ajaxOptions);
      return response.data;
    }
  }
}

</script>
