<template>
  <div
    v-if="settings.feeModelConfiguration.donorPay"
    id="gf-epl-fm-std"
    class="row m-0 p-0 w-100"
  >
    <div class="platform-fee col-12">
      <div class="field rounded-checkbox">
        <label
          for="IsAccepted"
          class="transition"
        >
          <input
            id="IsAccepted"
            v-model="coveringCost"
            autocomplete="off"
            data-val="true"
            name="IsAccepted"
            type="checkbox"
          >
          <div
            class="box"
            :class="{'gf-secondary-button': coveringCost}"
          /></label>
        <label
          for="coveringCost"
          class="checkbox-detail"
        >
          {{ settings.feeModelConfiguration.donorPayDescription }}
        </label>
      </div>
      <hr>
      <div
        v-show="coveringCost && donorPayAmount > 0"
        class="row"
      >
        <div class="col-auto">
          {{ currency(store.state.totalAmount , { symbol : currencySymbol }).format() }}
        </div>
        <div
          class="col text-right"
        >
          {{ settings.feeModelConfiguration.donorPayThankYouMessage }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import Currency from "currency.js";
export default {
  mixins: [ModuleBase],

  data() {
    return {
      coveringCost: true,
      donorPayAmount: 0,
      currency: Currency,
    }
  },

  computed: {
    currencySymbol(){			
        return this.sharedState.currencyInfo.symbol || "$";
      }
  },
  watch: {
    coveringCost(val) {
      this.store.setCoveringCost(val);
      this.calculateDonorPay();
    },
    "sharedState.amount": function () {
			this.calculateDonorPay();
		},
  },

  mounted() {
    if (!this.settings.feeModelConfiguration.donorPay)
      this.coveringCost = false;
    this.store.setCoveringCost(this.coveringCost);
  },

  methods: {
    calculateDonorPay() {
        this.donorPayAmount = this.sharedState.amount * this.getDonorUpliftAmount()
        
        if (this.coveringCost) {
            this.store.addToAmount(this.donorPayAmount)
        } else {
            this.store.addToAmount(0)
        }

        if (this.donorPayAmount > 0) this.$ModuleBase_resized()
        this.logger.logInfo(`donor pay amount`, this.donorPayAmount)
        
    },

    getDonorUpliftAmount() {
        if (this.settings.feeSummary && this.coveringCost) {
            var dulFee = this.settings.feeSummary.find(x=>x.type == "DUL")
            if (dulFee) return dulFee.rate;
        }

        if (this.settings.feeModelConfiguration.donorUpliftRate)
          return this.settings.feeModelConfiguration.donorUpliftRate
        return 0;
    }
  }
};
</script>
<style scoped>
.platform-fee *{
	color: #666666;
	font-size: 12px;
}
</style>
