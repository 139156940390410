import Vue from 'vue';
import {resolveFieldAlias, resolveModuleAlias, unionOperators} from './ConditionalLogicConfig';
import { operators } from './ConditionalLogicConfig';

export default {
    state: Vue.observable({}),
    initializeStateViaPages(pages){
        pages.length && pages.forEach((page => {
            page.contents && page.contents.forEach((content) => {
                content.conditions && content.conditions.forEach(condition => {
                    if (!condition.conditionals ||
                        !condition.conditionals.length ||
                        !condition.action ||
                        !Object.values(unionOperators).some((unionOperator) => unionOperator === condition.unionOperator)
                    ) return;

                    condition.conditionals.forEach(conditional => {
                        if (!conditional.dependsOnModule ||
                            !conditional.dependsOnModule.length ||
                            !conditional.property ||
                            !conditional.property.length ||
                            !Object.values(operators).some((operator) => operator === conditional.operator)
                        ) return;
                        if (!this.state[resolveModuleAlias(conditional.dependsOnModule)]) {
                            Vue.set(this.state, resolveModuleAlias(conditional.dependsOnModule), {});
                          }
                          Vue.set(this.state[resolveModuleAlias(conditional.dependsOnModule)], resolveFieldAlias(conditional.property), null);
                    })
                })
            })
        }))
    },
    setState(module, property, value) {
        if (!this.state[module]) {
          Vue.set(this.state, module, {});
        }
        Vue.set(this.state[module], property, value);
    },
}
