<script>
import GfCardTypes from "../../../common/GfCardTypes"

export default {
    name: 'GfClickToPayInitializer',
    data: function() {
        return {
            hostedFieldInstance: '',
            numberValid: -1,
            cvvValid: -1,
            expirationDateValid:  -1,
            loadFailure: false,
            paymentTypeName: 'clicktopay-credit-card'
        };
    },
    methods: {
        initialize: function(tokenizationKey, preFillData) {
            
        },

        getOptions: function(paymentMethodReceivedCallback, paymentErrorCallback) {
            return {
                onPaymentMethodReceived: paymentMethodReceivedCallback,
                onError: paymentErrorCallback
            };
        },

        getNormalisedCardType: function(data) {
            var cardType = GfCardTypes.OTHER;
            if (data.cards && data.cards.length === 1)
                cardType = this.normaliseCard(data.cards[0].niceType)

            return cardType;
        },

        normaliseCard(cardType) {
            switch (cardType.toLowerCase()) {
                case "american express":
                    return GfCardTypes.types.AMEX;
                case "mastercard":
                    return GfCardTypes.types.MASTERCARD;
                case "visa":
                    return GfCardTypes.types.VISA;
                default:
                    return GfCardTypes.OTHER;
            }
        },

        initStyles: function() {
            return {
                    'input': {
                      'color': '#ccc',
                      'font-size': '18px',
                    },
                    'input.invalid': {
                        'border-color': 'red'
                    },
                    ':focus': {
                        'color': 'black'
                    },
                    'input.valid': {
                        'color': 'green'
                    }
                };
        },

        tearDown: function() {
            var vm = this;
            if (vm.$data.hostedFieldInstance === undefined) {
                return;
            }
            if (vm.$data.hostedFieldInstance !== '') {
                vm.$data.hostedFieldInstance.teardown((err) => {
                    if (err) {
                        console.log("teardown err: " + err);
                        return;
                    }
                    vm.$data.hostedFieldInstance = '';
                });
            }
            vm.$emit('teardownSuccess');
        }
    }
}
</script>
