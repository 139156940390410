<template>
  <div class="col-md-12 p-0">
    <div class="deposit-funds hide-radio other-amount col-12 field">
      <div class="currency-symbol">
        {{ currencySymbol }}
      </div>
      <div class="ui input">
        <input
          id="DepositAmount"
          v-model="amount"
          type="text"
          value=""
          :placeholder="`${amountPlaceHolderText}`"
        >
      </div>
    </div>
    <div class="col-12">
      <div
        class="field rounded-checkbox"
      >
        <label
          for="IsDepositAccepted"
          class="transition"
        >
          <input
            id="IsDepositAccepted"
            v-model="depositReceiptAccepted"
            name="IsDepositAccepted"
            autocomplete="off"
            data-val="true"
            type="checkbox"
          >
          <div
            class="box"
            :class="{ 'gf-secondary-bg gf-secondary-text': depositReceiptAccepted }"
          />
        </label>
        <span
          id="IsDepositedAcceptedLabel"
          class="checkbox-detail col accepted-deposit-fund-label"
          for="IsDepositAccepted"
          data-toggle="popover"
          data-trigger="hover"
          data-placement="bottom"
        >{{ acceptanceDescription }}<a
          href="#"
          onclick="return false;"
          :title="acceptanceDescriptionToolTip"
          class="tooltip checkbox-tip"
        >
          ?
        </a></span>
      </div>
    </div>
  </div>
</template>

<script>
import EmbeddedPaymentConstants from '../../EmbeddedPaymentConstants'
import AdditionalItemBase from '../AdditionalItem/AdditionalItemBase.vue'
import ModuleBase from "../ModuleBase.vue"
import * as GfLocalisation from '@gf/gf-localisation'

export default {
  name: 'DepositFunds',
  mixins: [
    ModuleBase,
    AdditionalItemBase,
  ],
  data() {
    return {
      amount: null,
      depositReceiptAccepted: false,
      GfLocalisation,
    }
  },
  computed: {
    amountPlaceHolderText() {
      return this.settings && typeof this.settings.amountPlaceHolderText != 'undefined' 
        ? this.settings.amountPlaceHolderText 
        : this.GfLocalisation.getLocale('depositFunds.includeAmount')
    },
    acceptanceDescription() {
      return this.settings && typeof this.settings.acceptanceDescription != 'undefined' 
        ? this.settings.acceptanceDescription 
        : this.GfLocalisation.getLocale('depositFunds.acceptanceDescription')
    },
    acceptanceDescriptionToolTip() {
      return this.settings && typeof this.settings.acceptanceDescriptionToolTip != 'undefined' 
        ? this.settings.acceptanceDescriptionToolTip 
        : this.acceptanceDescription || ''
    },
    currencySymbol(){
			return this.sharedState.currencyInfo.symbol || "$";
		}
  },
  watch: {
    amount(val) {
      this.store.updateAmount(val)
      this.$nextTick(async () => {
        await this.$ModuleBase_validate()
      })
      this.updateAdditionalItemUnitPrice(val)
    },
    depositReceiptAccepted(val) {
      this.$nextTick(async () => {
        await this.$ModuleBase_validate()
      })
    }
  },
  beforeMount() {
    this.store.setForceContactDetailsAsAnonymous(true)
    this.store.setDonationType(EmbeddedPaymentConstants.DonationType.depositCollectedFunds)
    this.description = 'Depositing Collected Funds'
    this.isAnonymous = true
    this.mainLineItem = true
    this.productType = 3
    this.unitPrice = this.amount
    this.beneficiary = parseInt(this.store.state.beneficiaryId)
    this.fundraisingPageId = this.store.state.fundraisingPageId
  },
  mounted() {
    this.initializePopper()
  },
  validators: {
    amount(value) {
      return this.validator
        .value(value)
        .required()
        .float()
        .greaterThan(0)
    },
    depositReceiptAccepted(value) {
      return this.validator
        .value(value)
        .match(true)
    }
  },
  methods: {
    initializePopper() {
      if (typeof $ != 'undefined') {
        $(function () {
          $('[data-toggle="popover"]').popover()
        })
      }
    },
  }
}
</script>
<style>
.accepted-deposit-fund-label{
  color: #666;
  font-size: 12px;
}

.rounded-checkbox .checkbox-detail{
  margin: 0;
  width: auto;
  vertical-align: top;
  padding: 0;
}

.deposit-funds {
    padding: 4px!important;
    -ms-flex-item-align: center;
    align-self: center;
}

.other-amount {
    position: relative;
    margin-bottom: 20px;
    margin-top: 5px;
}

.currency-symbol {
    position: absolute;
    z-index: 1;
    top: 19px;
    left: 30px;
    font-weight: 700;
    color: #a2a2a2;
}

.other-amount>.ui.input input {
    padding-left: 40px;
}

input[type="text"]{
    margin: 0;
    outline: 0;
    tap-highlight-color: hsla(0, 0%, 100%, 0);
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.2);
    color: #000;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    box-shadow: inset 0 0 0 0 transparent;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    line-height: 1.6em;
    padding: 13px;
    width: 99.8%;
}

a.tooltip {
    opacity: 1;
    z-index: 1;
    width: 16px;
    height: 16px;
    display: inline-block;
    text-indent: initial;
    text-decoration: none;
    color: transparent;
    font-size: 15px;
}

a.tooltip.checkbox-tip {
    background: #416170;
    border-radius: 50px;
    color: #fff;
    text-indent: 0;
    font-family: 'bariol_boldbold';
    font-size: 12px;
    text-align: center;
    padding: 0;
    line-height: 16px;
    margin-left: 5px;
}

</style>
