<script>
export default {
    name: 'GfAnalyticsMixin',
    methods: {
        track(data) {
           window.dataLayer.push(JSON.parse(data));
        }
    }
}
</script>
