<script>
import ModuleBase from "../ModuleBase.vue";
import currency from 'currency.js';

export default {
    mixins: [ModuleBase],
    data() {
        return {
            amount: null,
            beneficiary: null,
            description: null,
            isAnonymous: false,
            itemIdentifier: null,
            productType: null,
            quantity: 1,
            taxTypeId: 1,
            unitPrice: null,
            mainLineItem: false,
            fundraisingPageId: null,
        };
    },

    watch: {
        "this.settings": {
            deep: true,
            handler(val) {
                this.$_AdditionalItemBase_updateProperties(val);
            }
        },
        "isAnonymous":{
            handler(val) {
                this.setPropertyValueOfAllItems('isAnonymous', val);
            }
        }
    },

    methods: {
        setPropertyValueOfAllItems(propName, propValue){
            this.store.state.additionalItems.forEach((x) => {
                x[propName] = propValue;
            });
        },

        $ModuleBase_afterBaseMounted() {
            this.logger.logInfo('AdditionalItemBase.$ModuleBase_afterBaseMounted')
            this.updateProperties();
        },

        updateProperties() {
            if (!this.settings) return;

            this.itemIdentifier = this.settings.itemIdentifier;

            this.$AdditionalItemBase_updateProperties(
                this.settings
            );

            this.$AdditionalItemBase_updateItemInStore();
        },

        $AdditionalItemBase_updateProperties(settings) {
            // override
        },

        $AdditionalItemBase_updateItemInStore() {
            if (this.store.state.additionalItems.length > 0) {
                var matchingItemInStore = this.store.state.additionalItems.find(
                    x => x.itemIdentifier == this.itemIdentifier
                );
                if (matchingItemInStore) {
                    return;
                }
            }
            this.store.addAdditionalItem({
                amount: this.amount,
                beneficiary: this.beneficiary,
                description: this.description,
                isAnonymous: this.isAnonymous,
                itemIdentifier: this.itemIdentifier,
                productType: this.productType,
                quantity: this.quantity,
                taxTypeId: this.taxTypeId,
                unitPrice: this.unitPrice,
                mainLineItem: this.mainLineItem,
                fundraisingPageId: this.fundraisingPageId
            });
        },

        updateAdditionalItemUnitPrice(unitPrice) {
          const item = this.store.state.additionalItems.find(i => i.mainLineItem == true)
          if (item)
            item.unitPrice = currency(unitPrice).value
        }
    }
};
</script>

<style></style>
