<template>
  <div />
</template>

<script>
import AdditionalItemBase from "./AdditionalItemBase.vue";
import GfProductTypes from "../../../../common/GfProductTypes";

export default {
  mixins: [AdditionalItemBase],
  data() {
    return {
      isAnonymous: true,
      productType: GfProductTypes.TransactionFee,
    };
  },

  methods: {
    $AdditionalItemBase_updateProperties(settings) {
      if (settings) {
        if (settings.amount !== null) {
          this.unitPrice = settings.amount;
          this.amount = settings.amount;
        }

        if (settings.beneficiary !== null)
          this.beneficiary = settings.beneficiary;

        if (settings.description !== null)
          this.description = settings.description;
      }
    },
  },
};
</script>

<style>
</style>
