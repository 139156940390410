export default [
  {
    content: "<i class='far fa-usd-circle gf-secondary-colour-fg'></i>",
    subText: "",
  },
  {
    content: "<i class='far fa-comment-alt-lines gf-secondary-colour-fg'></i>",
    subText: "",
  },
  {
    content: "<i class='far fa-credit-card-blank gf-secondary-colour-fg'></i>",
    subText: "",
  },
];
