<template lang="html">
  <div
    id="embeddedPaymentLoader"
    :style="`background-color:${pageBackgroundColour}`"
    class="gf-body-text-face"
  >
    <Loader v-if="processing" />
    <SectionSteps
      v-if="stepsData.length > 0"
      :steps="stepsData"
      slider-class="slider-nav"
      slider-content-ref=".slider-for"
      :disable-all-steps="isOnCompletionPage"
      :active-index="store.state.currentPageIndex"
      @navigateToSlide="navigateToSlide"
    />

    <div
      v-if="pages.length > 0"
      ref="slick"
      class="slider-for"
    >
      <div
        v-for="(page, index) in pages"
        v-show="index==store.state.currentPageIndex"
        :id="`step-${page.number}`"
        :key="index"
        class="col-12"
        :class="page.class"
      >
        <resize-observer
          v-if="index == store.state.currentPageIndex"
          @notify="handleResize"
        />
        <div
          v-for="(content, contentIndex) in page.contents"
          :key="contentIndex"
          class="row"
        >
          <component
            :is="content.module"
            v-if="content.showComponent"
            :settings="content.settings"
            :conditions="content.conditions"
            :displayed-on-page-index="index"
            :disabled="store.state.isProcessingPayment"
            :additional-properties="additionalProperties"
            :embedded-payment-mode="embeddedPaymentMode"
            @action="(eventData) => onAction(eventData, contentIndex, index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logger from "../../common/logger";
import SectionSteps from './SectionSteps.vue';
import DollarHandles from "./modules/DollarHandles/DollarHandles.vue";
import ActionButton from "./modules/ActionButton/ActionButton.vue";
import AdditionalItem from "./modules/AdditionalItem/AdditionalItem.vue"
import CoverCost from "./modules/CoverCost/CoverCost.vue";
import ContactDetails from "./modules/ContactDetails/ContactDetails.vue";
import Constants from "./EmbeddedPaymentConstants";
import FeeModule from "./modules/FeeModule/FeeModule.vue";
import FundraisingMessage from "./modules/FundraisingMessage/FundraisingMessage.vue";
import Payment from "./modules/Payment/Payment.vue";
import RegularGiving from "./modules/RegularGiving/RegularGiving.vue";
import TermsConditions from "./modules/TermsConditions/TermsConditions.vue";
import GfText from "./modules/GfText/GfText.vue";
import DownloadReceipt from "./modules/DownloadReceipt/DownloadReceipt.vue";
import Address from './modules/Address/Address.vue';
import EmbeddedPaymentStore from './EmbeddedPaymentStore';
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import PaymentView from './templates/default/view';
import Loader from './modules/Common/Loader.vue';
import PaymentSubmissionMixin from './mixins/PaymentSubmissionMixin.vue';
import GfAnalytics from './modules/GfAnalytics/GfAnalytics.vue';
import Captcha from './modules/Captcha/Captcha.vue';
import DollarMatching from  './modules/DollarMatching/DollarMatching.vue'
import CustomQuestion from './modules/CustomQuestion/CustomQuestion.vue';
import DepositFunds from './modules/DepositFunds/DepositFunds.vue'
import ContactNumber from './modules/ContactNumber/ContactNumber.vue';
import GfConfigurationClient from '../../common/GfConfigurationClient.vue';
import prefillDataLoader from '../../common/prefillData/prefillDataLoader'
import casingUtilities from '../../common/casingUtilities'
import _ from 'lodash';
import FilterParser from './modules/FilterHandlers/FilterParser'
import beneficiaryDemoData from "../../data/beneficiaryData";
import apiService from '@gf/gf-api-services';

export default {
  name: "GfEmbeddedPaymentLoader",
  components: {
    ActionButton,
    AdditionalItem,
    Address,
    ContactDetails,
    CoverCost,
    CustomQuestion,
    DollarHandles,
    DollarMatching,
    DownloadReceipt,
    FeeModule,
    FundraisingMessage,
    GfAnalytics,
    GfText,
    Loader,
    Payment,
    RegularGiving,
    ResizeObserver,
    SectionSteps,
    TermsConditions,
    Captcha,
    DepositFunds,
    ContactNumber,
  },

  mixins: [ GfConfigurationClient, PaymentSubmissionMixin ],
  props: {
    apiDomain: {
      type: String,
      default:""
    },
    beneficiaryId: {
      type: String,
      default: ""
    },
    eventId: {
      type: String,
      default: ""
    },
    pageId: {
      type: String,
      default: ""
    },
    regionId: {
      type: String,
      default: ""
    },
    gatewaySettings: {
      type: Object,
      default: () => {return {status: {success: true, msg: ''}, gatewaySettings: []}}
    },
    currencyInfo: {
      type: Object,
      default: () => {return {code: "", symbol:""}}
    },
    globalEventBus: {
      type: Object,
      default: () => {}
    },
    onFeesReceived: {
      type: String,
      default: ""
    },
    onTokenReset: {
      type: String,
      default: ""
    },
    onReady: {
      type: String,
      default: ""
    },
    onLoadError: {
      type: String,
      default: ""
    },
    onPaymentResponse: {
      type: String,
      default: ""
    },
    additionalProperties: {
      type: Object,
      default: () => {},
    },
    clientIpAddress: {
      type: String,
      default: ""
    },
    demo: {
      type: Boolean,
      default: false
    },
    captchaPublicKey:{
      type: String,
      default: ""
    },
    prefillSettings: {
      type: Object,
      default: () => null
    },
    embeddedPaymentMode: {
        type: Boolean,
        default: true
    }
  },
  data: function() {
    return {
      stepsData: [],
      pages: [],
      view: {},
      store: EmbeddedPaymentStore,
      resize: false,
      pollingId: '',
      processing: false,
      templateView:PaymentView,
      requestToSubmitPayment: false,
      logger,
      prefillDataLoader,
    };
  },

  computed: {
    pageBackgroundColour() {
      if (this.pages.length > 0 && this.pages[this.store.state.currentPageIndex].backgroundColour)
        return this.pages[this.store.state.currentPageIndex].backgroundColour;
      return 'inherit';
    },

    isOnCompletionPage() {
      if (this.pages.length > 0) {
        return this.pages[this.store.state.currentPageIndex].pageType == Constants.PageType.completion;
      }
      return false;
    },

    completionPage() {
      return this.pages.find(p => p.pageType == Constants.PageType.completion);
    },

    completionErrorPage() {
      return this.pages.find(p => p.pageType == Constants.PageType.completionError);
    },
  },

  watch: {
    globalEventBus(val) {
      logger.logInfo("globalEventBus updated", val);
      this.store.setGlobalEventBus(val);
    },
    gatewaySettings(val) {
      logger.logInfo("supported gateways updated", val);
      this.store.setSupportedGateways(val);
    },
    currencyInfo(val) {
      this.$nextTick(() => {
        logger.logInfo("currencyInfo updated", val);
        this.store.setCurrencyInfo(val);
      });
    },
    clientIpAddress(val) {
      this.store.setClientIpAddress(val);
    },

    beneficiaryId(val) {
      this.store.setContext(val, null, null);
    },

    eventId(val) {
      this.store.setContext(null, val, null);
    },

    pageId(val) {
      this.store.setContext(null, null, val);
    },

    regionId(val) {
      this.store.setRegion(val);
    },

    'store.state.readyForSubmission': {
      async handler(val) {
          if (val === true)
            await this.$_PaymentSubmissionMixin_SubmitPayment();
        }
    },

    'store.state.clientActionsResponse': {
        handler(val) {
          if (val)
            this.$_PaymentSubmissionMixin_OnClientActionsResponse(val);
        }
    },

    'store.state.entityConfigsToLoad':{
      async handler(val) {
        if(val.length > 0)
          await this.loadEntityConfigurations();
       }
    },
  },
  async mounted() {

    if (this.additionalProperties && this.additionalProperties.csrfToken)
      this.store.setCsrfToken(this.additionalProperties.csrfToken);

    try {
        this.store.setGlobalEventBus(this.globalEventBus === undefined ? new Vue() : this.globalEventBus);
    }
    catch (error) {
        this.store.setGlobalEventBus(this.globalEventBus);
    }

    if (this.demo === true)
      this.store.setDemo(true)

    this.logger.logInfo('this.additionalProperties.view', this.additionalProperties.view);

    if (this.additionalProperties && this.additionalProperties.view) {
      this.view = casingUtilities.objectKeysToCamelCase(this.additionalProperties.view);
    }

    this.logger.logInfo('GfEmbeddedPaymentLoader.mounted.view', this.view);

    if (this.view) {
      if(!this.view.pages.some(x=> x.contents.some(y => y.module === 'Captcha'))){
        var contents = this.view.pages.find(x=> x.contents.some(y => y.module === 'ActionButton' && y.settings.action === 'DONATE')).contents;
        contents.push(
        {
          "module": "Captcha",
          "settings": {
          }
        });
      }

      this.stepsData = this.view.steps;
      if (this.demo === false){
          await this.storeBeneficiaryData();
      }
      else {
        this.store.setBeneficiaryData(beneficiaryDemoData.Beneficiaries[0])
      }
      this.filterModules();
      this.pages = this.view.pages;

      if (this.view.customCss && this.view.customCss.length > 0) {
        var style = document.createElement('style');
        style.type = "text/css";
        style.appendChild(document.createTextNode(this.view.customCss));
        document.head.appendChild(style);
      }
    }

    this.triggerDefaultGateway();

    this.store.setRegion(this.regionId);
    this.store.setContext(this.beneficiaryId, this.eventId, this.pageId);

  },

  methods: {
    async onAction(eventData) {
      if (eventData.action == "NEXTPAGE") {
        this.navigateToNextSlide();
        this.triggerDefaultGateway();
        this.store.refreshAlternateButton(this.pageHasPaymentsModule());
      }

      if (eventData.action == "DONATE") {
        await this.store.executeBeforePayment();
      }

      if (eventData.action == "RELOAD"){
        await this.reloadPage();
      }
    },

    pageHasPaymentsModule() {
      return this.pages[this.store.state.currentPageIndex].contents.some(i => i.module.toLowerCase() === 'payment')
    },

    triggerDefaultGateway() {
      if (this.pageHasPaymentsModule()) {
        logger.logInfo('pageHasPaymentsModule')
        this.store.setCurrentGatewayTrigger()
      }
    },

    onResize() {
      logger.logInfo(`onResize(), slide Height: ${this.slideHeight}`);
    },

    handleResize ({ width, height }) {
      logger.logInfo(`resized ${width}, ${height}`);
    },

    reloadPage() {
      var message = {
        "Application":"GoFundraise",
        "Type": "Payment",
        "Action": "DonationPageReload",
        "Data": null
      };
      window.parent.postMessage(JSON.stringify(message), '*');
      window.self.location.reload();
    },

    navigateToCompletion() {
      logger.logInfo(`Navigating to completion page`);
      this.store.moveToPage(this.completionPage.number - 1);
    },

    navigateToCompletionError() {
      logger.logInfo(`Navigating to completion error page`);
      this.store.moveToPage(this.completionErrorPage.number - 1);
    },

    navigateToNextSlide() {
        this.store.moveToNextPage();
    },

    navigateToSlide(slideNumber) {
      logger.logInfo(`Navigating to slide: ${slideNumber}`);
      this.store.moveToPage(slideNumber);
      this.store.refreshAlternateButton(this.pageHasPaymentsModule());
    },

    async loadEntityConfigurations() {
        this.store.setEntityConfigResults(await this.getClientSettingsResponse());
    },

    async getClientSettingsResponse(){
      var contextSettings = null
      if (this.prefillSettings) {
        try {
          contextSettings = await this.prefillDataLoader.get(this.prefillSettings)

          if (contextSettings && typeof contextSettings.fields != 'undefined')
            this.store.updatePrefillData(contextSettings.fields)
        } catch (e) {
          logger.logInfo('Error Loading intermediarySettings', e)
        }
      }

      var intermediarySettings = contextSettings && typeof contextSettings.contexts != 'undefined'
        ? contextSettings.contexts
        : null
      var request = this.getMultipleSettingsRequest(this.eventId, this.beneficiaryId, this.pageId, this.regionId, this.store.state.entityConfigsToLoad, intermediarySettings);
      logger.logInfo('GfEmbeddedPaymentLoader > getClientSettingsResponse > contextSettings', contextSettings)
      logger.logInfo("loadEntityConfigurations() > getClientSettingsResponse(), request: ", request);
      var clientSettings = await this.getClientSettings(request);
      logger.logInfo('getClientResponse > clientSettings', clientSettings);
      return clientSettings;
    },

    async storeBeneficiaryData() {
      if (this.beneficiaryId) {
        let params = {
          beneficiaryid: this.beneficiaryId
        }
        let beneficiaryService = new apiService.BeneficiariesService(this.apiDomain)
        let beneficiaryData = await beneficiaryService.byParams(params)

        if (beneficiaryData.Beneficiaries.length > 0) {
          this.store.setBeneficiaryData(beneficiaryData.Beneficiaries[0])
        }
      }
    },

    filterModules(){
      var self = this;
      for(let i in this.view.pages) {
        var page = this.view.pages[i];
        for(let j in page.contents){
            var content = page.contents[j];
            content.showComponent = true;
            var hasFilters =_.has(content.settings, 'filters') && content.settings.filters.length > 0;

            if (hasFilters) {
              var result = FilterParser.parse(content.settings.filters, self.store);
              if(!result) {
                  content.showComponent = false;
              }
              self.logger.logInfo('filterModules() showComponent',content)
            }
        }
      }
    }
  }
};
</script>

<style lang="css">
#gfPresetPayPalBtn {
  border: none;
  padding: 0;
}

.field {
  padding-bottom: 25px;
}

.field label {
  margin-bottom: 5px;
}

.is-invalid {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + .75rem) !important;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E) !important;
    background-repeat: no-repeat !important;
    background-position: center right calc(.375em + .1875rem) !important;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.rounded-checkbox label .box {
    content: '';
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #ccc;
    vertical-align: middle;
    background: #fff;
	margin-right: 10px;
}

.rounded-checkbox > *{
	cursor: pointer;
}

.rounded-checkbox .checkbox-detail {
    width: auto;
    vertical-align: top;
}

.rounded-checkbox input {
    position: absolute;
    left: -9999px;
    display:none;
}

.rounded-checkbox .other-textbox {
  left: 35px;
  display: inline-block;
}

.rounded-checkbox input:checked + .box {
    border-radius: 5px;
    border-color: #1388cd;
	  color: #fff;
	  background: #1388cd;
}

.rounded-checkbox input:checked + .box:after {
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    font-size: 9px;
    text-align: center;
    display: block;
    line-height: 20px;
    font-weight: 900;
	padding: 1px;
}
.checkbox-tip {
	margin-left: 5px;
}
.form-control.hosted-field {
  height: 48px !important;
}

.required.field > label:after, .required.field > span:after {
	display: inline-block;
	margin: 0 0.2em 0 0;
	content: "*";
	color: #ec0000;
    margin-left: 0.2em;
}

.ui-tab {
    background: #f7f7f7;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
    display: block;
    padding: 12px;
    cursor: pointer;
}

</style>
