<template>
  <div class="col-12 m-0 p-0">
    <div class="row col-12">
      <div
        v-for="item in availableSchedules"
        :key="item.value"
        class="col ui-tab text-center gf-secondary-font"
        :class="{
          'gf-secondary-bg gf-secondary-text gf-secondary-font':
            item.value === selectedFrequency,
        }"
        @click="setFrequency(item.value)"
      >
        {{ item.displayText }}
      </div>
    </div>
  </div>
</template>

<script>
import * as GfLocalisation from "@gf/gf-localisation";
import constants from "../../EmbeddedPaymentConstants";
import logger from "../../../../common/logger";

export default {
  props: {
    defaultFrequency: {
      type: String,
      default: constants.RegularGivingFrequency.Monthly,
    },
  },
  data() {
    return {
      availableSchedules: [],
      selectedFrequency: -1
    };
  },

  watch: {
      defaultFrequency(val) {
          this.selectedFrequency = val;
      },
      selectedFrequency(val) {
          logger.logInfo(`frequency changed: ${val}`);
          this.$emit('frequencyChanged', val)
      }
  },

  mounted() {
    this.selectedFrequency = this.defaultFrequency;
    this.setDefaultFrequencies();
  },

  methods: {
    setDefaultFrequencies() {
      const defaultSchedules = [
        {
          displayText: GfLocalisation.getLocale("regularGiving.scheduleWeekly"),
          value: constants.RegularGivingFrequency.Weekly,
        },
        {
          displayText: GfLocalisation.getLocale(
            "regularGiving.scheduleMonthly"
          ),
          value: constants.RegularGivingFrequency.Monthly,
        },
        {
          displayText: GfLocalisation.getLocale("regularGiving.scheduleQuarterly"),
          value: constants.RegularGivingFrequency.Quarterly,
        },
        {
          displayText: GfLocalisation.getLocale("regularGiving.scheduleYearly"),
          value: constants.RegularGivingFrequency.Yearly,
        },
      ];
      this.availableSchedules.push(...defaultSchedules);
    },

    setFrequency(val) {
      this.selectedFrequency = val;
    },
  },
};
</script>

<style>
</style>
