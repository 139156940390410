<template lang="html">
  <div>
    <div id="braintreeWidget" />

    <div
      v-if="!loadFailure"
      class="panel panel-default py-3"
    >
      <div class="row">
        <div
          v-if="displayNameSelector && !alwaysUseCardholderName"
          id="card-holder-name-selector"
          class="col-12 mb-3"
        >
          <label
            for="is-cardholder-name-selector"
            class="switch"
          >
            <input
              id="is-cardholder-name-selector"
              v-model="ignoreCardholderName"
              type="checkbox"
              name="is-cardholder-name-selector"
              :disabled="isDisabled"
            >
            <span class="slider round" />
          </label>
          <span>{{ displayNameSelectorLabel }}</span>
        </div>
      </div>
      <div class="row">
        <div
          v-if="ignoreCardholderName === false"
          class="form-group col-12"
        >
          <input
            id="cardholder-name"
            v-model="cardholderName"
            class="hosted-field form-control cardholder-name"
            placeholder="Cardholder's name"
            :class="{ invalid: cardholderNameInvalid }"
            :disabled="isDisabled"
          >
        </div>
      </div>
      <form
        id="cardForm"
        action="/"
        method="post"
        class="panel-body braintree-fields px-0"
      >
        <div class="row">
          <div class="form-group col-12">
            <div
              id="card-number"
              class="hosted-field form-control"
              :class="{ invalid: numberValid == 0}"
              :disabled="isDisabled"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6">
            <label
              class="control-label"
              for="card-expiry"
            >Expiry</label>
            <div
              id="card-expiry"
              class="form-control hosted-field"
              :class="{ invalid: expirationDateValid == 0}"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group col-6">
            <label
              class="control-label"
              for="card-security"
            >Security Number</label>
            <div
              id="card-security"
              class="form-control hosted-field"
              :class="{ invalid: cvvValid == 0}"
              :disabled="isDisabled"
            />
          </div>
        </div>
      </form>
      <div
        v-if="!isSupportedPaymentTypesHidden"
        id="card-name-selector-wrap"
        class="col-12 pl-0 pr-0"
      >
        <ul class="pl-0 pr-0 row">
          <li class="col-12">
            <GfSupportedPaymentTypes :highlight-item="cardTypeToHighlight" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import GfBrainTreeInitializer from './GfBrainTreeInitializer.vue';
import GfPaymentComponent from '../../../common/GfPaymentComponent.vue';
import BrainTreePaymentRequestor from './BrainTreePaymentRequestor';
import GfSupportedPaymentTypes from '../../common/GfSupportedPaymentTypes.vue';
import logger from '../../../common/logger'

export default {
  name: 'GfBrainTreeCreditCard',
  components: { GfSupportedPaymentTypes },
  mixins: [GfBrainTreeInitializer, GfPaymentComponent],
  props: {
    preFillData: {
      type: Object,
      default: () => {}
    },
    isSupportedPaymentTypesHidden: {
        type: Boolean,
        default: false,
    }
  },
  data: function() {
    return {
      brainTreePaymentRequestor: new BrainTreePaymentRequestor(),
      hostedFieldInstance: '',
      displayNameSelector: true,
      displayNameSelectorLabel: '',
      additionalData: { cardholderName: '' },
      ignoreCardholderName: true,
      alwaysUseCardholderName: false,
      cardholderName: '',
      lastUpdatedNonce: '',
      lastUpdatedCardType: '',
      lastUpdatedDigits: '',
      lastUpdatedExpiry: '',
      cardTypeToHighlight: 'all',
      cardholderNameInvalid: true,
      paymentTypeName: 'braintree-credit-card',
    }
  },

  computed: {
    isDisabled() {
      return this.disabled;
    },
  },
  watch: {
    cardholderName: function (val) {
      if (!this.ignoreCardholderName) {
        this.additionalData.cardholderName = this.cardholderName;
        this.notifyPaymentDetailsUpdatedIfValid();
      }
    },
    ignoreCardholderName: function (val) {
      this.additionalData.cardholderName = '';
      if (!val) {
        this.additionalData.cardholderName = this.cardholderName;
      }

      this.notifyPaymentDetailsUpdatedIfValid();
    },
  },

  created: function() {
    if (typeof(this.additionalProperties) === 'undefined')
      return;

    if (typeof(this.additionalProperties.displayNameSelectorLabel) !== 'undefined')
      this.displayNameSelectorLabel = this.additionalProperties.displayNameSelectorLabel;

    if (typeof(this.additionalProperties.alwaysUseCardholderName) !== 'undefined')
      this.alwaysUseCardholderName = this.additionalProperties.alwaysUseCardholderName;

    this.displayNameSelector = (this.additionalProperties.displayNameSelector !== null || this.additionalProperties.displayNameSelector !== undefined) && this.additionalProperties.displayNameSelector;
  },

  mounted: function() {
    this.initialize(this.tokenizationKey, this.preFillData, this.onPaymentMethodReceived, this.onPaymentError);
    this.debouncedTokenRequest = _.debounce(this._generateToken, 500);
    if (this.alwaysUseCardholderName) {
      this.ignoreCardholderName = false;
      this.displayNameSelector = false;
    }
  },
  methods: {
    generateToken: function() {
      this.debouncedTokenRequest();
    },

    _generateToken: function() {
      this.brainTreePaymentRequestor.tokenizeRequest(this.hostedFieldInstance, this.onPaymentSuccessfulRequest, this.onPaymentError);
    },

    onPaymentSuccessfulRequest(payload) {
      this.lastUpdatedNonce = payload.nonce;
      this.lastUpdatedCardType = this.normaliseCard(payload.details.cardType);
      this.lastUpdatedDigits = payload.details.lastFour;
      this.lastUpdatedExpiry = `${payload.details.expirationMonth}/${payload.details.expirationYear}`;
      this.notifyPaymentDetailsUpdatedIfValid();
    },

    notifyPaymentDetailsUpdatedIfValid: function() {
      if (this.displayNameSelector && !this.ignoreCardholderName && !this.cardholderName) {
        this.cardholderNameInvalid = true;
        this.clearInputData();
        return;
      }
      this.cardholderNameInvalid = false;
      this.onPaymentMethodReceived(this.lastUpdatedNonce, this.lastUpdatedCardType, this.lastUpdatedDigits, this.lastUpdatedExpiry, this.additionalData);
    },
  }
}
</script>

<style lang="css" scoped>
input.invalid {
  border-color: red;
}

.hosted-field {
  border-radius: 5px;
  height: 34px;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  display: inline-block;
  box-shadow: none;
  font-weight: 400;
  font-size: 13.33px;
  font-family: 'Arial';
  line-height: 20px;
  transition: all 300ms ease-in-out;
}

.invalid {
  border-color: red;
  background-color: #fee;
}

.braintree-hosted-fields-valid {
  border-color: #128d01;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 0px;
}

.switch input {
  display:none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: #fafafa;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#card-name-selector-wrap li {
  list-style-type: none;
}

input[disabled], div[disabled] {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}

</style>
