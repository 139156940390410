<template>
  <div
    id="gf-epl-action-button"
    class="col-12 m-0 p-0"
  >
    <div
      v-show="sharedState.showAlternateButton"
      class="alternate-button-container"
    >
      <div
        v-show="!sharedState.canProceed"
        class="alternate-button-disabled-overlay"
      />
    </div>
    <div
      v-show="!sharedState.showAlternateButton"
      class="btn-wrap col px-0"
    >
      <button
        type="button"
        class="btn btn-primary border-0 btn-next gf-driver-button d-flex align-items-center justify-content-center"
        :class="{'not-allowed': !sharedState.canProceed}"
        :disabled="!sharedState.canProceed"
        rel=""
        tabindex="0"
        data-id="1"
        @click="btnClicked"
      >
        <div>
          <span>{{ settings.text }}</span>
        </div>
        <div v-if="settings.isAmountShown">
          <span>{{ sharedState.currencyInfo.symbol }}</span>
          <span>{{ sharedState.totalAmount }}</span>
        </div>
        <div class="ml-2">
          <i
            v-if="settings.action === 'NEXTPAGE'"
            class="fas fa-arrow-right"
          />
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
export default {
  mixins: [ModuleBase],

  mounted() {
    if (this.settings.action === "DONATE" && this.settings.hasOwnProperty('actionSettings')) {
      this.store.setWaitForCompletion(this.settings.actionSettings.waitForCompletion);
      this.store.setPollingPeriodInMs(this.settings.actionSettings.pollingPeriodInMs);
      this.store.setPollingTimeoutInMs(this.settings.actionSettings.pollingTimeoutInMs);
    }
  },

  methods: {
    async btnClicked() {
      if (this.settings.action === "NEXTPAGE")
        this.$ModuleBase_notifyParent('NEXTPAGE');

      if (this.settings.action === "DONATE")
        this.$ModuleBase_notifyParent('DONATE');

      if (this.settings.action === "RELOAD")
        this.$ModuleBase_notifyParent('RELOAD');
    }
  }
};
</script>
<style scoped>
.btn {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  gap: 8px;
}

.btn-wrap {
    margin-top: 20px;
}

.btn-icon{
	font-size: 13px;
	-webkit-transition: 0.1s all linear;
    transition: 0.1s all linear;
}

.btn-icon.icon-right{
	margin-left: 20px;
    position: absolute;
    margin-top: 5px;
}

.btn-next:hover .btn-icon{
	margin-left: 30px;
}

.not-allowed {
  cursor: not-allowed;
}

.alternate-button-container {
  position: relative;
}

.alternate-button-disabled-overlay {
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  position: absolute;
  top: 0;
  background: white;
  z-index: 100;
  opacity: .3;
}
</style>
