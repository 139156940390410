export default [
    {
        "RuleId": 117,
        "Status": "I",
        "ProcessorSettings": [
            {
                "PluginName": "NewPayment",
                "PluginType": "Trigger",
                "Settings": "",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchPayment",
                "PluginType": "Validator",
                "Settings": "{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"PaymentItems\",\"ValidationParameter\":{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"DatePaid\",\"Value\":\"2023-03-06 13:43:37 +11:00\",\"Operator\":\"GreaterThanOrEqual\"},{\"ConditionField\":\"EventCampaignId\",\"Value\":13242,\"Operator\":\"Equal\"}]}}],\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"StateConditionsOperator\":\"All\",\"StateConditions\":[{\"ConditionField\":\"ProcessedAmount\",\"Value\":\"2000\",\"Operator\":\"LessThanOrEqual\"}]}",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchedPaymentCreator",
                "PluginType": "Executor",
                "Settings": "{\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"PaymentCreateRequest\":{\"BillingDetails\":{\"FirstName\":\"GoFundraise\",\"LastName\":\"Admin\",\"Email\":\"noreply@gofundraise.com\",\"Organisation\":\"Sponsor 1.44PM\"},\"Payment\":{\"PaymentType\":\"CASH\",\"PaymentSubType\":\"OFFLINE\",\"PaymentStatus\":\"I\",\"PaymentNotes\":\"This is matched payment\",\"PaymentToken\":\"\",\"AdditionalData\":\"\",\"PaymentItems\":[{\"PaymentItemNotes\":\"matching rule notes\"}]}}}",
                "ProcessorId": 0
            }
        ],
        "State": [
            {
                "Name": "Match Payment State",
                "Description": "State configuration for Event: 13242",
                "Status": "A",
                "RuleStateSettings": "{\"ProcessedAmount\":0.0}"
            }
        ],
        "AdditionalSettings": {
            "SelectedTimeZone": null
        }
    },
    {
        "RuleId": 133,
        "Status": "I",
        "ProcessorSettings": [
            {
                "PluginName": "NewPayment",
                "PluginType": "Trigger",
                "Settings": "",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchPayment",
                "PluginType": "Validator",
                "Settings": "{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"PaymentItems\",\"ValidationParameter\":{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"DatePaid\",\"Value\":\"2023-04-21 11:12:00 +10:00\",\"Operator\":\"LessThan\"},{\"ConditionField\":\"DatePaid\",\"Value\":\"2023-04-17 11:12:15 +10:00\",\"Operator\":\"GreaterThanOrEqual\"},{\"ConditionField\":\"EventCampaignId\",\"Value\":13251,\"Operator\":\"Equal\"}]}}],\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"StateConditionsOperator\":\"All\",\"StateConditions\":[{\"ConditionField\":\"ProcessedAmount\",\"Value\":\"1000\",\"Operator\":\"LessThanOrEqual\"}]}",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchedPaymentCreator",
                "PluginType": "Executor",
                "Settings": "{\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"PaymentCreateRequest\":{\"BillingDetails\":{\"FirstName\":\"GoFundraise\",\"LastName\":\"Admin\",\"Email\":\"noreply@gofundraise.com\",\"Organisation\":\"Sponsor 17.04\"},\"Payment\":{\"PaymentType\":\"CASH\",\"PaymentSubType\":\"OFFLINE\",\"PaymentStatus\":\"I\",\"PaymentNotes\":\"This is matched payment\",\"PaymentToken\":\"\",\"AdditionalData\":\"\",\"PaymentItems\":[{\"PaymentItemNotes\":\"matching rule notes\"}]}}}",
                "ProcessorId": 0
            }
        ],
        "State": [
            {
                "Name": "Match Payment State",
                "Description": "State configuration for Event: 13242",
                "Status": "A",
                "RuleStateSettings": "{\"ProcessedAmount\":88.0}"
            }
        ],
        "AdditionalSettings": {
            "SelectedTimeZone": null
        }
    },
    {
        "RuleId": 134,
        "Status": "I",
        "ProcessorSettings": [
            {
                "PluginName": "NewPayment",
                "PluginType": "Trigger",
                "Settings": "",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchPayment",
                "PluginType": "Validator",
                "Settings": "{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"PaymentItems\",\"ValidationParameter\":{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"DatePaid\",\"Value\":\"2023-04-17 12:19:48 +10:00\",\"Operator\":\"GreaterThanOrEqual\"},{\"ConditionField\":\"EventCampaignId\",\"Value\":13251,\"Operator\":\"Equal\"}]}}],\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"StateConditionsOperator\":\"All\",\"StateConditions\":[{\"ConditionField\":\"ProcessedAmount\",\"Value\":\"5000\",\"Operator\":\"LessThanOrEqual\"}]}",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchedPaymentCreator",
                "PluginType": "Executor",
                "Settings": "{\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"PaymentCreateRequest\":{\"BillingDetails\":{\"FirstName\":\"GoFundraise\",\"LastName\":\"Admin\",\"Email\":\"noreply@gofundraise.com\",\"Organisation\":\"Sponsor 17APR 12.20pm\"},\"Payment\":{\"PaymentType\":\"CASH\",\"PaymentSubType\":\"OFFLINE\",\"PaymentStatus\":\"I\",\"PaymentNotes\":\"This is matched payment\",\"PaymentToken\":\"\",\"AdditionalData\":\"\",\"PaymentItems\":[{\"PaymentItemNotes\":\"matching rule notes\"}]}}}",
                "ProcessorId": 0
            }
        ],
        "State": [
            {
                "Name": "Match Payment State",
                "Description": "State configuration for Event: 13251",
                "Status": "A",
                "RuleStateSettings": "{\"ProcessedAmount\":2.0}"
            }
        ],
        "AdditionalSettings": {
            "SelectedTimeZone": null
        }
    },
    {
        "RuleId": 135,
        "Status": "A",
        "ProcessorSettings": [
            {
                "PluginName": "NewPayment",
                "PluginType": "Trigger",
                "Settings": "",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchPayment",
                "PluginType": "Validator",
                "Settings": "{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"PaymentItems\",\"ValidationParameter\":{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"DatePaid\",\"Value\":\"2023-04-22 09:36:00 +10:00\",\"Operator\":\"LessThan\"},{\"ConditionField\":\"DatePaid\",\"Value\":\"2023-04-20 09:36:00 +10:00\",\"Operator\":\"GreaterThanOrEqual\"},{\"ConditionField\":\"EventCampaignId\",\"Value\":13251,\"Operator\":\"Equal\"}]}}],\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"StateConditionsOperator\":\"All\",\"StateConditions\":[{\"ConditionField\":\"ProcessedAmount\",\"Value\":\"1000\",\"Operator\":\"LessThanOrEqual\"}]}",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchedPaymentCreator",
                "PluginType": "Executor",
                "Settings": "{\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"PaymentCreateRequest\":{\"BillingDetails\":{\"FirstName\":\"GoFundraise\",\"LastName\":\"Admin\",\"Email\":\"noreply@gofundraise.com\",\"Organisation\":\"Sponsor Name\"},\"Payment\":{\"PaymentType\":\"CASH\",\"PaymentSubType\":\"OFFLINE\",\"PaymentStatus\":\"A\",\"PaymentNotes\":\"This is matched payment\",\"PaymentToken\":\"\",\"AdditionalData\":\"\",\"PaymentItems\":[{\"PaymentItemNotes\":\"matching rule notes\"}]}}}",
                "ProcessorId": 0
            }
        ],
        "State": [
            {
                "Name": "Match Payment State",
                "Description": "State configuration for Event: 13251",
                "Status": "A",
                "RuleStateSettings": "{\"ProcessedAmount\":50.0}"
            }
        ],
        "AdditionalSettings": {
            "SelectedTimeZone": "Australia/Sydney"
        }
    },
    {
        "RuleId": 140,
        "Status": "A",
        "ProcessorSettings": [
            {
                "PluginName": "NewPayment",
                "PluginType": "Trigger",
                "Settings": "",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchPayment",
                "PluginType": "Validator",
                "Settings": "{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"PaymentItems\",\"ValidationParameter\":{\"ConditionsOperator\":\"All\",\"Conditions\":[{\"ConditionField\":\"DatePaid\",\"Value\":\"2023-05-18 11:49:18 +10:00\",\"Operator\":\"GreaterThanOrEqual\"},{\"ConditionField\":\"EventCampaignId\",\"Value\":13260,\"Operator\":\"Equal\"}]}}],\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"StateConditionsOperator\":\"All\",\"StateConditions\":[{\"ConditionField\":\"ProcessedAmount\",\"Value\":\"1000\",\"Operator\":\"LessThanOrEqual\"}]}",
                "ProcessorId": 0
            },
            {
                "PluginName": "MatchedPaymentCreator",
                "PluginType": "Executor",
                "Settings": "{\"MatchingGift\":{\"Type\":\"MatchedPayment\",\"Settings\":{\"AmountMultiplier\":1,\"ExceedStateAction\":\"NoMatch\"}},\"PaymentCreateRequest\":{\"BillingDetails\":{\"FirstName\":\"GoFundraise\",\"LastName\":\"Admin\",\"Email\":\"noreply@gofundraise.com\",\"Organisation\":\"Google\"},\"Payment\":{\"PaymentType\":\"CASH\",\"PaymentSubType\":\"OFFLINE\",\"PaymentStatus\":\"A\",\"PaymentNotes\":\"This is matched payment\",\"PaymentToken\":\"\",\"AdditionalData\":\"\",\"PaymentItems\":[{\"PaymentItemNotes\":\"matching rule notes\"}]}}}",
                "ProcessorId": 0
            }
        ],
        "State": [
            {
                "Name": "Match Payment State",
                "Description": "State configuration for Event: 13260",
                "Status": "A",
                "RuleStateSettings": "{\"ProcessedAmount\":0.0}"
            }
        ],
        "AdditionalSettings": {
            "SelectedTimeZone": "Australia/Sydney"
        }
    }
]